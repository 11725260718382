import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'

import RootRouter from 'router';
import Portfolio from 'view/portfolio';
import LittleMate from 'view/portfolio/littlemate';
import Saferoom from 'view/portfolio/saferoom';
import Myweather from 'view/portfolio/myweather';
import Split from 'view/portfolio/split';
import Cablecar from 'view/portfolio/cablecar';
import Uhyu from 'view/portfolio/uhyu';
import Ranchan from 'view/portfolio/ranchan';
import Metaverse from 'view/portfolio/metaverse';
import Diet from 'view/portfolio/diet';
import Hana from 'view/portfolio/hana';
import Etl from 'view/portfolio/etl';
import { Heuba } from 'view/portfolio/heuba';
import Welfare from 'view/portfolio/welfare';
import Home from 'view';
import Contact from 'view/Contact';
import NotFound from 'view/NotFound';
import 'css/index.css';

const routes = [
  { path: '/', name: 'Home', element: <Home />, },
  { path: '/contact', name: 'Contact', element: <Contact /> },
  {path:"/portfolio", element:<Portfolio />},
  {path:"/portfolio/littlemate", element:<LittleMate/>},
  {path:"/portfolio/saferoom", element:<Saferoom />},
  {path:"/portfolio/myweather", element:<Myweather />},
  {path:"/portfolio/split" ,element:<Split />},
  {path:"/portfolio/cablecar", element:<Cablecar />},
  {path:"/portfolio/uhyu", element:<Uhyu />},
  {path:"/portfolio/ranchan", element:<Ranchan />},
  {path:"/portfolio/diet" ,element:<Diet />},
  {path:"/portfolio/hana", element:<Hana />},
  {path:"/portfolio/etl", element:<Etl />},
  {path:"/portfolio/heuba", element:<Heuba />},
  {path:"/portfolio/welfare", element:<Welfare />},
  {path:"/portfolio/metaverse", element:<Metaverse />},
  {path:"/contact", element:<Contact />},
  {path:"/*", element:<NotFound />}
];
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootRouter />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
  },
])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <RouterProvider router={router} />
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
