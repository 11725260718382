import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Circle from 'component/Circle';
import { DURATION, Mobile, Pc } from 'value';
import MobilePage from './mobilePage';
import PcPage from './pcPage';

export function Heuba() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])
    // console.log(`url(${img_url}/bg.png')`);
    return (
        <div className='overflow-x-hidden'>
            <section className={`top-bg h-screen bg-center`} style={{ backgroundImage: `url("${img_url}/back.png")` }} data-aos="fade-up"  >
                <div className='font-B text-[4rem] md:text-[3rem] flex flex-col w-screen px-32  md:text-center sm:justify-center sm:items-center sm:text-center'>
                    <div className='text-heuba tracking-[3rem] md:tracking-[1rem]'>HEUBA</div>
                    <div className='tracking-[3rem] justify-end  text-right text-white md:tracking-[1rem]'>DESIGN</div>
                </div>
            </section>
            <div className='max-container'>

            <section className='flex flex-col justify-center items-center relative py-32'>
                <img src={`${img_url}/mockup.png`} />
            <div className={`w-[60rem] h-[20rem] bg-${cur} absolute top-[8rem] -z-10`} />
                    <div className='flex items-center justify-center md:flex-col sm:mt-[5rem]'>
                        <div className='mr-[5rem] w-[20rem] text-[1.5rem] md:mb-[4rem] md:mr-0'>
                            <div className='text-[2rem] font-B mb-4'>CLIENT</div>
                            <div className='border-b-2 mb-4' />
                            <div>Hueba Design</div>
                        </div>
                        <div className='mr-[5rem] w-[20rem] text-[1.5rem] md:mb-[4rem] md:mr-0'>
                            <div className='text-[2rem] font-B mb-4'>SERVICE</div>
                            <div className='border-b-2 mb-4' />
                            <div>Dynamic Webste</div>
                        </div>
                        <div className='mr-[5rem] w-[20rem] text-[1.5rem] md:mb-[4rem] md:mr-0'>
                            <div className='text-[2rem] font-B mb-4'>PROGRAM</div>
                            <div className='border-b-2 mb-4' />
                            <div>Photoshop, Illustrator</div>
                        </div>
                    </div>
            </section>
            <section className='bg-[#F1F1F1] flex flex-col items-center justify-center py-20' data-aos="fade-up"  >
                <img src={`${img_url}/main1.png`} className='mt-10 w-[65rem]' />
                <img src={`${img_url}/main2.png`} className='mt-10 w-[65rem]' />
                <img src={`${img_url}/main3.png`} className='mt-10 w-[65rem]' />
                <img src={`${img_url}/main4.png`} className='mt-10 w-[65rem]' />
            </section>
            <section className='layout py-[20rem] flex flex-col' data-aos="fade-up"  >
                <div className='font-B text-[2.6rem] mb-[8rem]'>ICON</div>
                <div className='flex items-center justify-center'>
                <img src={`${img_url}/icon.svg`} className='w-[65rem]'/>
                </div>
            </section>
            <Mobile>
                <MobilePage/>
            </Mobile>
            <Pc>
                <PcPage/>
            </Pc>
            </div>
            <section className={`mt-[15rem] flex flex-col bg-heuba items-center justify-end h-[30rem]`}>
            </section>
        </div>
    )

}


