
export default function HashTag(props) {
    const { direction ,active} = props;
    
    return (
        <div className={direction == "right" ? 'flex flex-col items-end' : 'flex flex-col'}>
            <div className='flex'>
                <div className={`hash-tag !border-diet ${active==1 ? `bg-diet text-white hover:text-diet hover:bg-white` :`text-diet hover:text-white hover:bg-diet`}  mr-2`}>정기 결제</div>
                <div className={`hash-tag !border-diet ${active==2 ? `bg-diet text-white hover:text-diet hover:bg-white` :`text-diet hover:text-white hover:bg-diet`}  mr-2`}>카카오/구글 로그인</div>
                <div className={`hash-tag !border-diet ${active==3 ? `bg-diet text-white hover:text-diet hover:bg-white` :`text-diet hover:text-white hover:bg-diet`}  mr-2`}>광고 배너</div>
                </div>
                <div className='flex mt-2'>
                <div className={`hash-tag !border-diet ${active==4 ? `bg-diet text-white hover:text-diet hover:bg-white` :`text-diet hover:text-white hover:bg-diet`}  mr-2`}>PUSH 알림</div>
                <div className={`hash-tag !border-diet ${active==5 ? `bg-diet text-white hover:text-diet hover:bg-white` :`text-diet hover:text-white hover:bg-diet`}  mr-2`}>관리자 페이지</div>
            </div>
        </div>
    )

}