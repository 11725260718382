import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Circle from 'component/Circle';
import { DURATION } from 'value';

export default function Etl() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {

        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <div className=' overflow-x-hidden'>
            <section className={`top-bg h-screen bg-center md:text-center `} style={{ backgroundImage: `url("${img_url}/bg.png")` }}>
                <div className='font-B text-[4rem] md:text-[3rem] text-white'><span className={`text-${cur}  font-EB`}>E</span>xtract, <span className={`text-etl  font-EB`}>T</span>ransform, <span className={`text-${cur}  font-EB`}>L</span>oad System</div>
            </section>

            <div className='max-container'>
                <section className="flex layout mt-28" data-aos="fade-up"  >
                    <div className="flex-1">
                        <div>
                            <div className='font-B text-[2.4rem]'><span className={`text-${cur} font-EB`}>E</span>xtract, <span className={`text-${cur}  font-EB`}>T</span>ransform, <span className={`text-${cur}  font-EB`}>L</span>oad 시스템 개발</div>
                            <div className='text-[1.5rem]'>센서 데이터 수집, 변환, 적재 시스템 개발</div>
                        </div>
                        <div className='mt-10 text-[1.4rem]'>
                            <div>프로젝트 기간 : 8개월</div>
                            <div>지원가능 OS : Window</div>
                            <div>개발언어 : C#, Python</div>
                            <div>제공언어 : 한국어</div>
                        </div>

                    </div>
                    <div className='relative back-mockup' >
                        <div className='w-[50rem] h-[50rem] rounded-full bg-[#0574FE20] absolute top-56 right-0 -z-20' />
                        <img src={`${img_url}/mockup.png`} className='z-[2] w-[50rem]' />
                    </div>

                </section>
                <section className='pb-20 relative top-[-10rem] z-0' data-aos="fade-up"  >
                    <div className='layout'>

                        <div className='subtitle'>Persona</div>
                        <div className='flex mb-10'>

                            <div className='bg-[#F8F8F8] p-[3rem] text-left rounded-lg flex flex-col'>
                                <div>
                                    <img className='w-24' src={`${img_url}/client.png`} />
                                    <div className='font-SB text-[#6C6C6C] text-[1.4rem] mt-3'>CLIENT</div>
                                    <div className='font-B text-[1.6rem]'>COMPANY</div>
                                </div>
                                <div className='mt-10 text-[1.4rem]'>
                                    <span className={`text-${cur} font-EB`}>WANT</span>
                                    <br />
                                    실시간 대용량 데이터를 수집, 변환, 적재 할 수 있는<br />
                                    프로그램을 만들고 싶습니다.<br />
                                </div>

                                <div className='mt-10 text-[1.4rem]'>
                                    <span className={`text-${cur} font-EB`}>GOAL</span><br />
                                    <div className='flex items-center mb-1'>
                                        <Circle number={1} color={cur} />
                                        설비에서 나오는 실시간 데이터 수집
                                    </div>
                                    <div className='flex items-center mb-1'>
                                        <Circle number={2} color={cur} />
                                        실시간 데이터 변환
                                    </div>
                                    <div className='flex items-center'>
                                        <Circle number={3} color={cur} />
                                        실시간 데이터 적재
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='subtitle'>
                            Service
                        </div>
                        <div className='text-[1.4rem]'>초당 <span className='text-etl font-B'>2만개</span> 이상의 데이터를 수집할 수 있고<br />
                            또한 KAFKA도 함께 연동하였습니다.
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={`${img_url}/main1.png`} className='w-[60rem] mt-[5rem]' />
                            <img src={`${img_url}/main2.png`} className='w-[60rem] mt-[5rem]' />
                            <img src={`${img_url}/arrow.svg`} className='w-[10rem] h-[10rem] mt-[5rem]' />
                            <img src={`${img_url}/main3.png`} className='w-[60rem] mt-[5rem]' />

                        </div>

                    </div>
                </section>

            </div>
            <section className={`flex flex-col bg-etl items-center justify-end h-[30rem]`}>
            </section>
        </div>
    )

}


