import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Service from './Service';
import Circle from 'component/Circle';
import MdMockup from 'component/MdMockup';
import Business from 'component/Business';
import { DURATION, Mobile,Pc } from 'value';
import HashTag from './HashTag';

export default function Diet() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {

        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
        
    }, [location])
    
    return (
        <div className=' overflow-x-hidden'>
            <section className={`top-bg bg-diet h-screen`}>
                <img src={`${img_url}/app.svg`} className='w-[10rem] mb-4' />
                <div className='text-white text-center text-[1.3rem]'>사용자간 다이어트 대회 프로그램을 진행하는 어플리케이션</div>
            </section>
            <div className='max-container'>
            <section className="flex layout" data-aos="fade-up"  >
                <div className="flex-1">
                    <div>
                        <img src={`${img_url}/logo_2.svg`} className='w-[6rem] mb-4 mt-36' />
                        <div className='text-[1.4rem]'> 다이어트 대회 프로그램을 진행하는 어플리케이션</div>
                    </div>
                    <div className='mt-10 text-[1.4rem]'>
                        <div>프로젝트 기간 : 6개월</div>
                        <div>지원가능 OS : Android / IOS</div>
                        <div>제공언어 : 한국어</div>
                    </div>

                </div>
                <div className='relative back-mockup'>
                    <div className={`w-[50rem] h-[50rem] rounded-full bg-diet_30 absolute top-56 -z-20`} />
                    <img src={`${img_url}/mockup.svg`} className='z-[2] w-[60rem] h-[60rem]' />
                </div>

            </section>

            <section className='pb-20 relative top-[-10rem] z-0' data-aos="fade-up"  >
                <div className='layout'>

                    <div className='subtitle'>Persona</div>
                    <div className='flex ml-16 mb-10 md:ml-0 md:flex-col md:items-center'>
                        <div>
                            <img className='w-[8rem]' src={`${img_url}/client.png`} />
                            <div className='font-SB text-[1.3rem] mt-3'>클라이언트</div>
                        </div>
                        <div className='bg-white py-6 px-10 text-center rounded-lg ml-16 flex text-[1.3rem] md:ml-0 md:text-[1rem]'>
                            <img src={`${img_url}/mark1.png`} className='mt-[-3rem] w-6 mr-2 h-fit' />
                            다이어트를 할때, 다른 사람들도 함께<br />
                            참여할 수 있는 어플리케이션을<br />
                            만들고 싶습니다.

                            <img src={`${img_url}/mark2.png`} className='mt-[-2rem] w-6  ml-2 h-fit' />
                        </div>

                    </div>
                </div>
                <Service cur={cur} img_url={img_url} />
            </section>

            <section data-aos="fade-up"  >
                <MdMockup
                    cur={cur}
                    img_url={img_url}
                    first={'사용자간의'}
                    second={'다이어트 대회 프로그램'}
                />

            </section>
            <section className='layout relative pt-[10rem]' data-aos="fade-up"  >
                <div className={`bg-diet_30 rounded-full w-[68rem] h-[68rem] absolute -left-[10rem] -z-10`} />
                <Pc>
                <div className='relative'>
                    <img src={`${img_url}/works1.png`} className='w-[22rem]' />
                    <div className='absolute -top-[4rem] left-[30rem] flex'>
                        <img src={`${img_url}/works1-2.png`} className='w-[18rem]' />
                        <div className='relative  -left-[0.7rem]'>
                            <div className='ml-24 dt-sub text-subGray'>원하는 날짜, 시간에 따라서<br />체성분과 무게를 기록 할 수 있어요.</div>
                            <img src={`${img_url}/arrow.svg`} className='w-[10rem]' />
                        </div>
                    </div>
                    <div className='absolute top-[20.5rem] left-[49rem] flex'>
                        <img src={`${img_url}/works1-3.png`} className='w-[18rem]' />
                        <div className='relative  -left-[0.6rem]'>
                            <div className='ml-8 dt-sub text-subGray'>원하는 날짜, 시간에 따라서<br />미션 및 원하는 문구를 기록 할 수 있어요.</div>
                            <img src={`${img_url}/arrow.svg`} className='w-[10rem]' />
                        </div>
                    </div>
                    <div className='absolute top-[40.5rem] left-[30rem] flex'>
                        <img src={`${img_url}/works1-4.png`} className='w-[18rem]' />
                        <div className='relative top-[10rem] -left-[0.7rem]'>
                            <img src={`${img_url}/arrow.svg`} className='w-[10rem] transform -scale-y-100' />
                            <div className='ml-24 dt-sub text-subGray'>원하는 날짜, 시간에 따라서<br />하루에 먹은 음식을 기록 할 수 있어요.</div>
                        </div>
                    </div>
                </div>
                </Pc>
                <Mobile>
                <div className='flex flex-col'>
                    <div>
                    <img src={`${img_url}/works1.png`} className='w-[22rem]' />

                    <div className='flex flex-col mt-[5rem]'>
                        <img src={`${img_url}/works1-2.png`} className='w-[18rem]' />
                        <div className='mt-[2rem]'>
                            <div className='dt-sub text-subGray'>원하는 날짜, 시간에 따라서<br />체성분과 무게를 기록 할 수 있어요.</div>
                        </div>
                    </div>
                    </div>
                    
                    <div className='flex flex-col items-end'>
                        <img src={`${img_url}/works1-3.png`} className='w-[18rem]' />
                        <div className='mt-[2rem]'>
                            <div className='text-right dt-sub text-subGray'>원하는 날짜, 시간에 따라서<br />미션 및 원하는 문구를 기록 할 수 있어요.</div>
                        </div>
                    </div>
                    <div>
                    <div className='flex flex-col mt-[5rem]'>
                        <img src={`${img_url}/works1-4.png`} className='w-[18rem]' />
                        <div className='mt-[2rem]'>
                            <div className='dt-sub text-subGray'>원하는 날짜, 시간에 따라서<br />하루에 먹은 음식을 기록 할 수 있어요.</div>
                        </div>
                    </div>

                    </div>
                </div>
                </Mobile>
            </section>
            <Pc>

            <section className="mt-[40rem] relative" data-aos="fade-up"  >
                <div className='absolute -top-[12.3rem] right-[20rem]'>
                    <div className='dt-sub text-subGray relative right-[20rem] text-right'>5단계의 관리 권한을 부여 할 수 있으며,<br />
                        각 관리자의 역할이 나누어져 있어요.</div>
                    <img src={`${img_url}/arrow.svg`} className='w-[10rem] traonsform -rotate-90 arrow' />
                </div>

                <div className='bg-[#e9e9e9] flex p-[4rem] md:mx-[2rem] mx-56 md:flex-col items-end justify-evenly md:justify-center md:items-center rounded-3xl'>
                    <div className='text-center text-[1.3rem] md:text-[1.4rem] md:mb-[5rem]'>
                        <img src={`${img_url}/auth.png`} className='w-[7rem] mb-10' />
                        <div>유저</div>
                    </div>
                    <div className='text-center text-[1.3rem] md:text-[1.4rem] md:mb-[5rem]'>
                        <img src={`${img_url}/auth.png`} className='w-[8rem] mb-10' />
                        <div>승인관리자</div>
                    </div>
                    <div className='text-center text-[1.3rem] md:text-[1.4rem] md:mb-[5rem]'>
                        <img src={`${img_url}/auth.png`} className='w-[9rem] mb-10' />
                        <div>보조관리자</div>
                    </div>
                    <div className='text-center text-[1.3rem] md:text-[1.4rem] md:mb-[5rem]'>
                        <img src={`${img_url}/auth.png`} className='w-[10rem] mb-10' />
                        <div>관리자</div>
                    </div>
                    <div className='text-center text-[1.3rem] md:text-[1.4rem] md:mb-[5rem]'>
                        <img src={`${img_url}/auth.png`} className='w-[11rem] mb-10 ' />
                        <div>슈퍼관리자</div>
                    </div>
                </div>
            </section>
            </Pc>
            <section className='layout py-28' data-aos="fade-up"  >
                <div className='subtitle'>BUSINESS MODEL</div>
                <Pc>
                <Business img_url={img_url} cur={cur}
                    txt1={'수수료'}
                    txt2={'플랫폼 제공'}
                    txt3={'상금'}
                    txt4={'대회 참여'}
                />
                </Pc>
                <Mobile>
                    <div className='flex items-center justify-center'>
                <img src={`${img_url}/mobile_bus.png`} className='w-[18rem]' />

                    </div>
                </Mobile>
            </section>


            <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
            <div className='md:flex md:items-center md:flex-col md:justify-center'>
                    <div className='font-SB service-sb-txt mb-5'>구독자가 원하는 기간대로,<br /><span className={`text-${cur} font-EB`}>정기결제 또는 일시적 결제.</span></div>
                    <Pc><HashTag cur={cur} active={1} /></Pc>
                </div>
                <div className='md:flex md:items-center md:justify-center'>
                <img src={`${img_url}/main1.png`} className=' app-mockup ' />

                </div>
            </section>
            <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
            <div className='md:flex md:items-center md:flex-col md:justify-center'>
                <img src={`${img_url}/main2.png`} className='app-mockup'/>
                </div>

                <div className='md:flex md:items-center md:flex-col md:justify-center'>
                    <div className='font-SB service-sb-txt text-right'>소셜로그인으로 간편하게</div>
                    <div className={`font-EB service-eb-txt text-${cur} mb-5 text-right`}>카카오/구글 로그인 제공.</div>
                    <Pc><HashTag cur={cur} direction="right" active={2} /></Pc>
                </div>
            </section>


            <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
            <div className='md:flex md:items-center md:flex-col md:justify-center'>
                    <div className='font-SB service-sb-txt '>하단 배너를 통해,</div>
                    <div className={`font-EB service-eb-txt text-${cur} mb-5 `}>광고수익 발생.</div>
                    <Pc><HashTag cur={cur}  active={3} /></Pc>
                </div>
                <div className='md:flex md:items-center md:justify-center'>
                <img src={`${img_url}/main3.png`} className=' app-mockup' />

                </div>

            </section>

            <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
            <div className='md:flex md:items-center md:justify-center'>

                <img src={`${img_url}/main4.png`} className='app-mockup'/>
                </div>
                <div className='md:flex md:items-center md:flex-col md:justify-center'>
                    <div className='font-SB service-sb-txt text-right'>서버와 통신이 끊어져도</div>
                    <div className={`font-EB service-eb-txt text-${cur} mb-5 text-right`}>지정한 시간에 알림 전송.</div>
                    <Pc><HashTag cur={cur} direction="right" active={4} /></Pc>
                </div>
            </section>
            <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
            <div className='md:flex md:items-center md:flex-col md:justify-center'>
                    <div className='font-SB service-sb-txt'>어플리케이션 내 내장된,</div>
                    <div className={`font-EB service-eb-txt text-${cur} mb-5 `}>관리자 기능으로 편리하게.</div>
                    <Pc><HashTag cur={cur}  active={5} /></Pc>
                </div>
                <div className='md:flex md:items-center md:justify-center'>
                <img src={`${img_url}/main5.png`} className='app-mockup' />

                </div>

            </section>

        </div>
            <section className={`flex flex-col bg-${cur} items-center justify-end h-[80rem]`} data-aos="fade-up"  >

                <div className='flex flex-col items-center justify-center mb-10'>

                    <img src={`${img_url}/btm_main.svg`} className='w-[35rem] mb-16 mt-10 md:w-[25rem]' />
                    <img src={`${img_url}/app.svg`} className='w-[7rem] mb-4' />
                </div>

            </section>
        </div>
    )

}
