import React, { Component } from 'react';

const Item = ({ text }) => {
  return <p>
    {text.split("\n").map((txt) => (
      <>
        {txt}
        <br />
      </>
    ))}
  </p>;
};


export default function Service(props) {
  const { path, first, second, third, fourth, result } = props;
  return (
    <div className='flex md:flex-col md:items-center justify-center'>
      <div className={`circle !border-${path}`}><Item text={first} /></div>
      <div className={`circle  !border-${path}`}><Item text={second} /></div>
      <div className={`circle  !border-${path}`}><Item text={third} /></div>
      <div className={`circle  !border-${path}`}><Item text={fourth} /></div>
      <div className='justify-center flex items-center'> 
      <img src={`${process.env.PUBLIC_URL}/asset/img/${path}/service_arr.svg`} className='h-[10rem] w-[10rem] md:rotate-90' />
      </div>

      <div className={`bg-${path} result-circle flex-col `}>
        <img src={`${process.env.PUBLIC_URL}/asset/img/${path}/logo_w.svg`} className='w-[11rem]' />
        <span className='mt-5 text-[1.2rem]'>{result}</span>
      </div>
    </div>
  )
}