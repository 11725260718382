import React, { Component ,useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import Service from 'component/Service';
import Keyword from 'component/Keyword';
import HashTag from './HashTag';
import {DURATION,Pc,Mobile} from 'value';


export default function Littlemate() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url  = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {
        
        const paths = location.pathname.split('/');
        setCur(paths[paths.length-1])
    }, [location])

        return (
            
            <div className=' overflow-x-hidden'>
               
                <section className="top-bg bg-littlemate h-screen" >
                    <img src={`${img_url}/app.svg`} className='w-[8rem] mb-4 ' />
                    <img src={`${img_url}/logo_w.svg`} className='w-[23rem] mb-5 sm:w-[12rem]' />
                    <div className='text-white text-[1.5rem] md:text-[1rem]  text-center'>반려동물 구독 서비스 및 비대면 상담 서비스 어플리케이션</div>
                </section>
                <div className='max-container'>
                <section className="flex layout" >
                    <div className="flex-1">
                        <div>
                            <img src={`${img_url}/logo.svg`} className='w-[23rem] mb-4 mt-36' />
                            <div className='text-[1.4rem] md:text-[1rem]'>반려동물 구독 서비스 및 비대면 상담 서비스 어플리케이션</div>
                        </div>
                        <div className='mt-10 text-[1.5rem]'>
                            <div>프로젝트 기간 : 6개월</div>
                            <div>지원가능 OS : Android / IOS</div>
                            <div>제공언어 : 한국어</div>
                        </div>

                    </div>
                    <div className='relative back-mockup' data-aos="fade-left" >
                        <img src={`${img_url}/mockup.png`} className='main-mockup'/>
                    </div>
                </section>
                <section className='bg-[#F3F9FF] pb-20 relative top-[-15rem] -z-10' >
                    <div className='layout'>
                        <div className='subtitle'>Persona</div>
                        <div className='flex ml-16 mb-10 md:flex-col md:items-center md:ml-0'>
                            <div>
                                <img className='w-24' src={`${process.env.PUBLIC_URL}/asset/img/littlemate/client.png`} />
                                <div className='font-SB text-[0.8rem] mt-3'>클라이언트</div>
                            </div>
                            <div 
                            className='bg-white p-6 text-center  rounded-lg ml-16 flex text-[1.4rem] md:mt-[2rem] md:ml-0 md:text-[1rem]'>
                                <img src={`${img_url}/mark1.png`} className='mt-[-2rem] w-10 h-fit' />
                                노령견 비율이 증가함에 따라,<br />
                                병원비로 경제적 부담이 증가해요.<br />
                                노령견 반려동물 프리미엄 케어 상담서비스<br />
                                어플리케이션을 만들고 싶습니다.
                                <img src={`${img_url}/mark2.png`} className='mt-[-2rem] w-10 h-fit' />
                            </div>

                        </div>
                        <div className='subtitle'>
                            Service
                        </div>
                        <div className='ml-16 md:ml-0'>
                            <Service 
                            first='수익모델'
                            second='구독 서비스'
                            third={`프리미엄\n케어 서비스`}
                            fourth='데이터, 제품 판매'
                            path={cur}/>
                        </div>
                    </div>
                </section>
                <section className='pb-[20rem]' data-aos="fade-up" >
                    <div className='mt-10 relative'>
                        <img src={`${img_url}/logo3.png`} className='w-[8rem] layout ' />
                        <div className='font-EB mt-2 layout text-[1.6rem]'>반려동물 케어,<br />
                            <span className='font-EB text-littlemate'>비대면 상담 서비스</span>
                        </div>
                        <div className='flex justify-center mt-[10rem]'>
                        <img className='w-[80rem] -mt-10' src={`${img_url}/md_mockup.png`}/>
                        {/* <img className='w-[18rem] -mt-10' src={`${img_url}/mockup1.png`}/>
                        <img className='w-[17rem] ' src={`${img_url}/mockup2.png`}/>
                        <img className='w-[17rem] -mt-10' src={`${img_url}/mockup3.png`}/>
                        <img className='w-[17rem] ' src={`${img_url}/mockup4.png`}/> */}
                        </div>
                        <div className='bg-[#F3F9FF] h-[20rem] -bottom-24 w-screen absolute -z-10'/>
                    </div>
                    <div className='flex'>
                    </div>
                </section>
                <section className='layout'>
                    <div className='subtitle'>BRANDING</div>
                  <Keyword />
                </section>
                <section className='layout py-28' data-aos="fade-up">
                    <div className='subtitle'>BUSINESS MODEL</div>
                    <div className='flex items-center justify-center'>
                        <Pc>
                        <div className='flex relative'>
                        <div className={`circle !border-${cur}`}>
                            <img src={`${img_url}/dog.png`} className='w-16 h-16' />
                        </div>
                        <div className='text-[#FDB325] absolute right-[1.5rem] top-[4rem] text-[0.6rem]'>월 구독료</div>
                            <img src={`${img_url}/yellow_arr.svg`} className='w-32' />

                        </div>
                        

                        <div className='flex relative ml-[-5rem]'>
                        <img src={`${img_url}/blue_arr.svg`} className='w-32 mt-10' />
                        <div className='text-[#009AF7] text-right absolute -left-10 bottom-10 text-[0.6rem]'>수의사 매칭<br/>노령견 맞춤형 제품 제공</div>
                            <div className={`center-circle !border-${cur}`}>
                                <img src={`${img_url}/logo_2.png`} className='w-24' />
                            </div>
                            
                            <img src={`${img_url}/yellow_arr.svg`} className='w-32 mt-10 transform -scale-y-100' />
                            <div className='text-[#FDB325] absolute right-[1.5rem] top-[9rem] text-[0.6rem]'>서비스 수수료</div>
                        </div>

                        <div className='flex relative ml-[-4rem]'>
                        <div className='text-[#009AF7] text-right absolute left-5 top-[4.7rem] text-[0.6rem]'>원격 상담</div>
                        <img src={`${img_url}/blue_arr.svg`} className='w-32 transform -scale-y-100 ml-[-0.5rem]' />    
                        <div className={`circle z-[2] !border-${cur}`}>
                            <img src={`${img_url}/vet.png`} className='w-16 h-16' />
                        </div>

                        </div>

                        </Pc>
                        <Mobile>
                    <img src={`${img_url}/mobile_bus.png`} className="w-[15rem] mt-[10rem]"/>
                    </Mobile>
                    </div>
                </section>
                <section className='flex layout py-16 md:py-0' >
                    <div className='flex-1'>
                        <div className='subtitle'>ICON</div>
                        <div className='flex items-center justify-between md:pr-0 pr-60'>
                        <div className='flex flex-col items-center justify-between'>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon1.svg`} className='w-16 h-16' />
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon2.svg`} className='w-12 h-12 mt-8' />
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon3.svg`} className='w-12 h-12 mt-8' />
                        </div>
                        <div className='flex flex-col items-center justify-between'>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon4.svg`} className='w-12 h-12' />
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon5.svg`} className='w-12 h-12 mt-8' />
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon6.svg`} className='w-12 h-12 mt-8' />
                            
                        </div>
                        <div className='flex flex-col items-center justify-between'>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon7.svg`} className='w-12 h-12' />
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon11.svg`} className='w-12 h-12 mt-8' />
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon9.svg`} className='w-12 h-12 mt-8' />
                            
                        </div>
                        <div className='flex flex-col items-center justify-between'>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon10.svg`} className='w-12 h-12' />
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon8.svg`} className='w-12 h-12 mt-8' />
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/icon12.svg`} className='w-12 h-12 mt-8' />
                        </div>

                        </div>
                    </div>
                    <div className='flex-1 md:mt-[4rem]'>
                        <div className='subtitle'>ILLUSTRATION</div>
                        <div className='flex flex-col items-center md:my-[5rem]'>
                            <div className='flex justify-between sm:flex-col' >
                                <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/pic1.png`} className='w-52 mr-20 md:mr-5' />
                                <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/pic2.png`} className='w-52 sm:my-[2rem]' />
                            </div>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/pic3.png`} className='w-52' />
                        </div>
                    </div>
                </section>
                <section>
                        <section className=' h-screen flex layout items-center justify-around'  data-aos="fade-up" >
                            <div className='md:flex md:items-center md:flex-col md:justify-center'>
                                <div className='font-SB service-sb-txt'>구독자가 원하는 기간대로,</div>
                                <div className='font-EB service-eb-txt text-littlemate mb-5'>정기결제 및 마일리지 결제.</div>
                               <Pc><HashTag active={1}/></Pc>
                            </div>
                            <div className='md:flex md:items-center md:justify-center'>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/main1.png`} className=' app-mockup'/>
                            </div>
                        </section>
                        <section className=' h-screen flex layout items-center justify-around'  data-aos="fade-up" >
                            <div className='md:flex md:items-center md:justify-center'>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/main2.png`} className='app-mockup' />
                            </div>

                            <div className='md:flex md:items-center md:flex-col md:justify-center  '>
                                <div className='font-SB service-sb-txt text-right'>자체개발 WebRTC로,</div>
                                <div className='font-EB service-eb-txt text-littlemate mb-5 text-right'>비대면 상담 제공.</div>
                                <Pc><HashTag direction="right" active={2}/></Pc>
                            </div>
                        </section>
                        <section className=' h-screen flex layout items-center justify-around'  data-aos="fade-up" >

                        <div className='md:flex md:items-center md:flex-col md:justify-center  '>
                                <div className='font-SB service-sb-txt'>클릭 한번으로,</div>
                                <div className='font-EB service-eb-txt text-littlemate mb-5'>간편한 SNS 로그인 제공.</div>
                                <Pc><HashTag active={3}/></Pc>
                            </div>
                            <div className='md:flex md:items-center md:justify-center'>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/main3.png`} className='app-mockup'/>

                            </div>
                        </section>
                        <section className=' h-screen flex layout items-center justify-around'  data-aos="fade-up" >
                        <div className='md:flex md:items-center md:justify-center'>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/main5.png`} className='w-[50rem] md:scale-150'/>
</div>
                            <div className='md:flex md:items-center md:flex-col md:justify-center md:mt-[5rem] '>
                                <div className='font-SB service-sb-txt text-right'>회원 관리 및 재고 관리용,</div>
                                <div className='font-EB service-eb-txt text-littlemate mb-5 text-right'>관리자페이지로 손쉽게.</div>
                                <Pc><HashTag direction="right" active={4}/></Pc>
                            </div>
                        </section>

                        <section className=' h-screen flex layout items-center justify-around'  data-aos="fade-up" >
                        <div className='md:flex md:items-center md:justify-center md:mb-[3rem]'>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/main4.png`} className='app-mockup'/>
</div>
                            <div className='md:flex md:items-center md:flex-col md:justify-center  '>
                                <div className='font-SB service-sb-txt text-right'>결제일 전과 상담이 시작하기 전에,</div>
                                <div className='font-EB service-eb-txt text-littlemate mb-5 text-right'>PUSH알람 제공.</div>
                                <Pc><HashTag active={5} direction="right"/></Pc>
                            </div>
                        </section>
                </section>
                </div>
                <section className='flex flex-col bg-littlemate items-center justify-between h-[80rem]' >
                    <div className='flex justify-between mt-[1rem]'>
                        <div className='mt-[-5rem]'>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm1.png`} className='w-[14rem]' />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm2.png`} className='w-[14rem]' />
                        </div>
                        <div>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm4.png`} className='w-[14rem]' />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm3.png`} className='w-[14rem]' />
                        </div>
                        <div className='mt-[-5rem]'>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm5.png`} className='w-[14rem]' />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm6.png`} className='w-[14rem]' />
                        </div>
                        <div>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm7.png`} className='w-[14rem]' />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm8.png`} className='w-[14rem]' />
                        </div>
                        <div className='mt-[-5rem]'>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm9.png`} className='w-[14rem]' />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm10.png`} className='w-[14rem]' />
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center mb-10'>
                        
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/btm_main.png`} className='w-60 mb-4' />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/app.svg`} className='w-20 mb-4' />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/littlemate/logo_w.svg`} className='w-32' />
                    </div>

                </section>
                
            </div>
        )
 
}

