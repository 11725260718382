import React, { Component } from 'react';
import { Pc, Mobile } from 'value';

export default function Icon(props) {
    const { img_url } = props;
    return (
        <>
            <Pc>
                <PcIcon img_url={img_url} />
            </Pc>
            <Mobile>
                <MobileIcon img_url={img_url} />
            </Mobile>
        </>
    )
}

function PcIcon(props) {
    const { img_url } = props;
    return (
        <div className='flex flex-col items-start justify-center'>
            <div className='flex items-center justify-center'>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon1.svg`} className='w-16 h-16' />
                    <div className='mt-6'>에어컨</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon2.svg`} className='w-16 h-16' />
                    <div className='mt-6'>냉장고</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon3.svg`} className='w-16 h-16' />
                    <div className='mt-6'>세탁기</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon4.svg`} className='w-16 h-16' />
                    <div className='mt-6'>책상</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon5.svg`} className='w-16 h-16' />
                    <div className='mt-6'>가스레인지</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon6.svg`} className='w-16 h-16' />
                    <div className='mt-6'>인덕션</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon7.svg`} className='w-16 h-16' />
                    <div className='mt-6'>건조대</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon8.svg`} className='w-16 h-16' />
                    <div className='mt-6'>침대</div>
                </div>


            </div>

            <div className='flex items-center justify-center mt-10'>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon9.svg`} className='w-16 h-16' />
                    <div className='mt-3'>거울</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon10.svg`} className='w-16 h-16' />
                    <div className='mt-3'>전자레인지</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon11.svg`} className='w-16 h-16' />
                    <div className='mt-3'>TV</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon12.svg`} className='w-16 h-16' />
                    <div className='mt-3'>화장대</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon13.svg`} className='w-16 h-16' />
                    <div className='mt-3'>옷장</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon14.svg`} className='w-16 h-16' />
                    <div className='mt-3'>신발장</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon15.svg`} className='w-16 h-16' />
                    <div className='mt-3'>인터넷</div>
                </div>

                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon16.svg`} className='w-16 h-16' />
                    <div className='mt-6'>CCTV</div>
                </div>
            </div>

            <div className='flex items-center justify-center mt-10'>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon17.svg`} className='w-16 h-16' />
                    <div className='mt-6'>비디오폰</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon18.svg`} className='w-16 h-16' />
                    <div className='mt-6'>공동형관</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon19.svg`} className='w-16 h-16' />
                    <div className='mt-6'>경비원</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon20.svg`} className='w-16 h-16' />
                    <div className='mt-6'>카드키</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-[5rem]'>
                    <img src={`${img_url}/icon21.svg`} className='w-16 h-16' />
                    <div className='mt-6'>화재경보기</div>
                </div>
            </div>
        </div>

    )

}


function MobileIcon(props) {
    const { img_url } = props;
    return (
        <div className='flex flex-col w-screen mx-[2rem]'>
            <div className='flex items-center justify-between mt-[3rem]'>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon1.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>에어컨</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon2.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>냉장고</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] text-center'>
                    <img src={`${img_url}/icon3.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>세탁기</div>
                </div>
            </div>

            <div className='flex items-center justify-between mt-[3rem]'>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon4.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>책상</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon5.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>가스레인지</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] text-center'>
                    <img src={`${img_url}/icon6.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>인덕션</div>
                </div>

            </div>
            <div className='flex items-center  justify-between mt-[3rem]'>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon7.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>건조대</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon8.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>침대</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] text-center'>
                    <img src={`${img_url}/icon9.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-3'>거울</div>
                </div>
            </div>

            <div className='flex items-center justify-between mt-[3rem]'>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon10.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-3'>전자레인지</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon11.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-3'>TV</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] text-center'>
                    <img src={`${img_url}/icon12.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-3'>화장대</div>
                </div>


            </div>
            <div className='flex items-center justify-between mt-[3rem]'>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon13.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-3'>옷장</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon14.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-3'>신발장</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] text-center'>
                    <img src={`${img_url}/icon15.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-3'>인터넷</div>
                </div>
            </div>

            <div className='flex items-center justify-between mt-[3rem]'>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon16.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>CCTV</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon17.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>비디오폰</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] text-center'>
                    <img src={`${img_url}/icon18.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>공동형관</div>
                </div>
                
            </div>
            <div className='flex items-center justify-between mt-[3rem]'>
            <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon19.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>경비원</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] mr-14text-center'>
                    <img src={`${img_url}/icon20.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>카드키</div>
                </div>
                <div className='flex flex-col justify-center items-center text-[1.5rem] text-center'>
                    <img src={`${img_url}/icon21.svg`} className='w-16 h-16' />
                    <div className='text-[1rem] mt-6'>화재경보기</div>
                </div>
            </div>
        </div>

    )

}