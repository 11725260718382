import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Circle from 'component/Circle';
import { DURATION, Pc } from 'value';

export default function Welfare() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;

    useEffect(() => {
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])

    }, [location])

    return (
        <div className=' overflow-x-hidden'>
            <section className={`top-bg h-screen`} style={{ backgroundImage: `url("${img_url}/bg.png")` }}>
                <div className='font-B text-6xl tracking-widest text-center sm:text-[2rem]'>WELFARE FOUNDATION<br />APPLICATION</div>
            </section>
            <div className='max-container'>
            <section className="mt-28 flex layout" data-aos="fade-up"  >
                <div className="flex-1">
                    <div>
                        <img src={`${img_url}/app.svg`} className='mb-4 w-[8rem]' />
                        <div className='text-[1.3rem] font-B'>복지재단 지면 후원신청서의 디지털 전환</div>
                    </div>
                    <div className='mt-10 text-[1.4rem]'> 
                        <div>프로젝트 기간 : 2개월</div>
                        <div>지원가능 OS : Android</div>
                        <div>개발언어 : Node js, React Native</div>
                        <div>제공언어 : 한국어</div>
                    </div>
                </div>
                <Pc>
                <div className='relative back-mockup'>
                    <div className='w-[50rem] h-[50rem] rounded-full bg-[#1FCA5B30] absolute top-56 -z-20' />
                    <img src={`${img_url}/mockup.png`} className='z-[2] w-[33rem] ml-[10rem]' />
                </div>

                </Pc>

            </section>
            <section className='pb-20 md:mt-[15rem] relative top-[-10rem] z-0' data-aos="fade-up"  >
                <div className='layout'>

                    <div className='subtitle'>Persona</div>
                    <div className='flex mb-10'>

                        <div className='bg-[#ececec] p-[3rem] text-left rounded-[1.5rem] flex flex-col'>
                            <div>
                                <img className='w-24' src={`${img_url}/client.png`} />
                                <div className='font-SB text-[#6C6C6C] text-[1.4rem] mt-3'>CLIENT</div>
                                <div className='font-B text-[1.6rem]'>COMPANY</div>
                            </div>
                            <div className='mt-10 text-[1.4rem]'>
                                <span className={`text-${cur} font-EB`}>WANT</span>
                                <br />
                                기존의 지면 후원신청서에서<br />
                                전자 후원신청서로 변환하고 싶습니다.<br />
                            </div>

                            <div className='mt-10 text-[1.4rem]'>
                                <span className={`text-welfare font-EB`}>GOAL</span><br />
                                <div className='flex items-center mb-1'>
                                    <Circle number={1} color={cur} />
                                    설비데이터 실시간 수집
                                </div>
                                <div className='flex items-center mb-1'>
                                    <Circle number={2} color={cur} />
                                    실시간 데이터 변환
                                </div>
                                <div className='flex items-center'>
                                    <Circle number={3} color={cur} />
                                    실시간 데이터 적재
                                </div>
                            </div>
                        </div>
                    </div>
                    <section>
                        <div className='subtitle'>
                            Service
                        </div>

                        <div className='flex justify-center items-center  py-14'>
                            <div className='flex md:flex-col md:items-center '>
                                <div className={`circle !border-welfare`}>전자후원 신청</div>
                                <div className={`circle !border-welfare`}>전자서명 및<br />전자계약서 발급</div>
                                <div className={`circle !border-welfare z-10`}>xlsx,pdf등<br />문서 변환</div>
                                <img src={`${img_url}/service_arr.svg`} className='w-[8rem] md:rotate-90 md:mt-[3.2rem] md:z-0' />
                                <div className={`bg-welfare result-circle flex-col md:mt-[3.8rem]`}>
                                    <div className=' font-SB text-[1.8rem]'>복지재단</div>
                                    <div className='flex flex-col text-center justify-center items-center'>
                                        <div className='flex text-[1rem]'><div>지면</div>&nbsp;신청서의</div>
                                        <div className='flex text-[1rem]'> <div>디지털</div>&nbsp;전환</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                </div>
            </section>
            <section className='justify-content flex flex-col items-center'>
                <div className='relative flex items-center  md:flex-col'>
                    <img src={`${img_url}/main1.png`} className='w-[34rem]' />
                    <div className='flex items-center relative -left-[3rem]'>
                        <img src={`${img_url}/arrow.svg`} className='w-[10rem] mr-5 arrow' />
                        <div>
                            <div className='dt-title'>
                                <span className={`font-EB text-${cur}`}>WEB</span>으로 편하게 관리할 수 있어요.
                            </div>
                            <div className='dt-sub text-subGray'>
                                관리자 마다 아이디를 부여받아서<br />
                                사용 할 수 있어요.
                            </div>
                        </div>
                    </div>
                <div className='w-[50rem] h-[50rem] rounded-full bg-[#1FCA5B30] absolute top-56 -z-20' />
                </div>
                <div className='relative flex items-center mt-[15rem] md:flex-col'>

                    <div className='flex items-center relative left-[2rem]'>
                        <div>
                            <div className='dt-title'>
                                <span className={`font-EB text-${cur}`}>유저관리</span>화면
                            </div>
                            <div className='dt-sub text-subGray'>
                                유저의 아이디, 부서, 이름 등을<br />
                                한번에 확인 하고 추가 및 삭제를 할 수 있어요.
                            </div>
                        </div>
                        <img src={`${img_url}/arrow.svg`} className='w-[10rem] -scale-x-100 arrow' />
                    </div>
                    <img src={`${img_url}/main2.png`} className='w-[34rem]' />
                </div>
                <div className='relative flex items-center mt-[15rem]  md:flex-col'>
                    <img src={`${img_url}/main3.png`} className='w-[30rem]' />
                    <div className='flex items-center relative -left-[3rem]'>
                        <img src={`${img_url}/arrow.svg`} className='w-[10rem] mr-5 arrow' />
                        <div>
                            <div className='dt-title'>
                                <span className={`font-EB text-${cur}`}>후원 신청서</span>관리
                            </div>
                            <div className='dt-sub text-subGray'>저장된 후원 신청서를 확인하고<br />
                                pdf파일로 다운로드 할 수 있어요.</div>
                        </div>
                    </div>
                </div>
                <div className='relative flex items-center my-[15rem]  md:flex-col'>
                    <div className='flex items-center relative left-[2rem]'>
                        <div>
                            <div className='dt-title'>
                                <span className={`font-EB text-${cur}`}>관리자 관리</span>화면
                            </div>
                            <div className='dt-sub text-subGray'>
                                다른 관리자를 한눈에 확인 할 수 있고,<br />
                                삭제 및 추가도 가능해요.
                            </div>
                        </div>
                        <img src={`${img_url}/arrow.svg`} className='arrow w-[10rem] transform -scale-x-100 ' />
                    </div>
                    <img src={`${img_url}/main4.png`} className='w-[34rem]' />
                    <div className='w-[50rem] h-[50rem] rounded-full bg-[#1FCA5B30] absolute right-56 -z-20' />
                </div>
            </section>

            </div>
            <section className={`flex flex-col bg-${cur} items-center justify-end h-[30rem]`}>
            </section>
        </div>
    )

}


