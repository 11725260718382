import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Service from 'component/Service';
import Model from './model';
import {DURATION,Pc} from 'value';
import HashTag from './HashTag';
export default function Ranchan() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {

        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <div className=' overflow-x-hidden'>
            <section className={`top-bg bg-ranchan h-screen `} >
                <img src={`${img_url}/logo_w.svg`} className='w-48 mb-2' />
                <div className='text-white text-center text-[1.4rem] mt-5'>전통시장 반찬가게 실시간 중개 주문 플랫폼</div>
            </section>
            <div className='max-container '>
            <section className="flex layout" >
                <div className="flex-1">
                    <div>
                        <img src={`${img_url}/logo.svg`} className='w-36 mb-4 mt-36' />
                        <div className='text-[1.5em]'>전통시장 반찬가게 실시간 중개 주문 플랫폼</div>
                    </div>
                    <div className='mt-10 text-[1.4rem]'>
                        <div>프로젝트 기간 : 6개월</div>
                        <div>지원가능 OS : Android / IOS</div>
                        <div>제공언어 : 한국어</div>
                    </div>

                </div>
                <div className='relative back-mockup z-0' data-aos="fade-left"  >
                    <img src={`${img_url}/mockup.svg`} />
                    <div className='absolute -z-10 bg-[#EB540010] w-[40rem] h-[40rem] rounded-full -left-[10rem] top-[15rem]'></div>
                </div>

            </section>
            <section className='pb-20 relative top-[-10rem] z-0' >
                <div className='layout'>

                    <div className='subtitle'>Persona</div>
                    <div className='flex ml-16 mb-10 md:flex-col md:items-center md:ml-0'>
                        <div>
                        <img className='w-[8rem]' src={`${img_url}/client.png`} />
                        <div className='font-SB text-[1.2rem] text-center mt-3'>클라이언트</div>
                        </div>
                        <div className='bg-white py-6 px-10 md:px-0 text-center rounded-lg ml-16 flex text-[1.4rem] md:ml-0 md:text-[1rem] md:mt-[2rem]'>
                            <img src={`${img_url}/mark1.png`} className='mt-[-3rem] w-6 mr-2 h-fit' />
                            온라인 플랫폼을 통해 반찬을 고객님들께 배달하고,<br />
                            다른 시장상인들도 이용할 수 있는 플랫폼을<br />
                            만들고 싶습니다.
                            <img src={`${img_url}/mark2.png`} className='mt-[-2rem] w-6  ml-2 h-fit' />
                        </div>

                    </div>
                    <div className='subtitle'>
                        Service
                    </div>
                    <div className='ml-16 mt-[10rem]'>

                    <div className='flex md:flex-col md:items-center'>
            <div className={`circle !border-ranchan`}>수익 모델</div>
            <div className={`circle  !border-ranchan`}>가맹점 모집</div>
            <div className={`circle  !border-ranchan`}>반찬 판매</div>
            <div className={`circle  !border-ranchan`}>수수료</div>
            <img src={`${img_url}/service_arr.svg`} className='h-[10rem] w-[10rem] md:rotate-90' />

            <div className={`bg-ranchan result-circle flex-col `}>
            <img src={`${img_url}/logo_w.svg`} className='w-[7rem]' />
                <span className='mt-2 text-[1.1rem]'>반찬가게 배달서비스</span>
                </div>
        </div>

                       
                    </div>
                </div>
            </section>

            <section data-aos="fade-up"  >
            
                <Model img_url={img_url} cur={cur}/>
            </section>


            <section className='mb-32' data-aos="fade-up"  >
                <div className='layout mt-10 flex flex-col items-end justify-end '>
                    <div className='flex items-end justify-end'>
                    <img src={`${img_url}/logo_2.svg`} className='w-32' />
                    </div>
                    <div className='mt-2 text-right text-[1.5rem]'><span className={`font-EB text-${cur}`}>온라인 반찬 배달</span><br />
                        플랫폼
                    </div>
                </div>
                <div className='flex relative justify-center'>
                    <img src={`${img_url}/mockup1.png`} className='w-[100rem]' />
                    <div className='bg-[#EB540020] w-[50rem] h-[50rem] rounded-full absolute left-[10rem] -top-[5rem] -z-[1]'></div>

                </div>
            </section>


            <section data-aos="fade-up"  >
                <section className=' h-screen flex layout items-center justify-around'>
                <div className='md:flex md:items-center md:justify-center md:mb-[3rem]'>
                    <img src={`${img_url}/main1.png`} className='mr-[6rem] md:mr-0 app-mockup' />
                    </div>
                    <div className='md:flex md:items-center md:flex-col md:justify-center md:mt-[5rem] '>
                        <div className='font-SB service-sb-txt text-right'>지도 연동으로</div>
                        <div className='font-SB service-sb-txt mb-5 text-right'><span className={`text-${cur} font-EB`}>가까운 지점을</span> 확인할 수 있어요.</div>
                        <Pc><HashTag cur={cur} direction="right" active={1}/></Pc>
                    </div>
                </section>
  
                <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >

                <div className='md:flex md:items-center md:flex-col md:justify-center md:mt-[5rem] '>
                        <div className='font-SB service-sb-txt'>앱에서 바로하는</div>
                        <div className={`font-EB service-eb-txt text-${cur} mb-5`}>In-App 결제.</div>
                        <Pc><HashTag cur={cur} active={2}/></Pc>
                    </div>
                    <div className='md:flex md:items-center md:justify-center md:mb-[3rem]'>
                    <img src={`${img_url}/main2.png`} className='app-mockup' />
                        
                    </div>
                </section>

                <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >

                <div className='md:flex md:items-center md:flex-col md:justify-center md:mt-[5rem] '>
                        <div className={`font-EB service-eb-txt text-${cur}`}>실시간 배달알림을</div>
                        <div className='font-SB service-sb-txt  mb-5'>받아 볼 수 있어요.</div>
                        <Pc><HashTag cur={cur} active={3}/></Pc>
                    </div>
                    <div className='md:flex md:items-center md:justify-center md:mb-[3rem]'>
                    <img src={`${img_url}/main3.png`} className='w-[30rem] md:w-[35rem]' />

                    </div>
                </section>
            <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
            <div className='md:flex md:items-center md:justify-center md:mb-[3rem]'>
            <img src={`${img_url}/main4.png`} className='w-[30rem] md:w-[35rem]' />
            </div>
            <div className='md:flex md:items-center md:flex-col md:justify-center md:mt-[5rem] '>
                    <div className='font-SB service-sb-txt text-right'>관리자페이지를 통해,</div>
                    <div className={`font-EB service-eb-txt text-${cur} mb-5 text-right`}>메뉴 및 가맹점 관리를 한번에.</div>
                    <Pc><HashTag cur={cur} direction="right" active={4}/></Pc>
                </div>
                
            </section>
            </section>
                </div>


            <section className={`flex flex-col bg-${cur} items-center justify-end h-[80rem] relative`}  >

                <div className='flex flex-col items-center justify-center mb-10'>

                    <img src={`${img_url}/btm_main.svg`} className='w-[62rem]  top-28 absolute' />
                    <img src={`${img_url}/logo_w.svg`} className='w-56 mb-4' />
                </div>

            </section>
        </div>
    )

}

