import Lottie from 'lottie-react';
import sun from './animation/sun.json'
import strong_rain from './animation/strong_rain.json'
import partly_cloudy_night from './animation/partly_cloudy_night.json'
import many_cloudy_night from './animation/many_cloudy_night.json'
import strong_snow from './animation/strong_snow.json'
import HashTag from './HashTag';
import {DURATION,Pc,Mobile} from 'value';

export default function MainService(props){
    const {cur, img_url} = props;

    return(
        <>
        <Pc>
          <section className='h-screen flex layout items-center justify-around' data-aos="fade-up" data-aos-duration={DURATION}>
          <div >
                        <div className='font-SB service-sb-txt'><span className=' text-myweather font-EB'>개인 맞춤형</span> 날씨정보 및 <span className=' text-myweather font-EB'>카테고리</span></div>
                        <div className='font-SB service-sb-txt mb-5'>알림 설정 가능.</div>
                        <div className='text-subGray mb-10 dt-sub'>정확한 날씨데이터를 바탕으로 원하는 카테고리에 해당하는<br />알림을 즉시 보내드립니다.</div>
                        <Pc><HashTag cur={cur} active={1}/></Pc>
                    </div>
                    
                    <img src={`${img_url}/main1.png`} className='w-[35rem] mr-[2rem] md:mr-0'/>
                </section>

                <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up" data-aos-duration={DURATION}>
                    <img src={`${img_url}/main3.png`} className='app-mockup'/>

                    <div className='mr-[6rem]'> 
                        <div className='font-SB service-sb-txt text-right'>IoT 디바이스의 블루투스를 통한,</div>
                        <div className='font-EB service-eb-txt text-myweather mb-5 text-right'>실시간 기기 연동.</div>
                        <div className='text-subGray mb-10 dt-sub text-right'>마이웨더(IoT)를 어플리케이션과 연동하여<br />보다 더 정확한 실시간 정보를 받아 볼 수 있습니다.</div>
                       <Pc> <HashTag cur={cur} active={2} direction="right" /></Pc>
                    </div>
                </section>
                <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up" data-aos-duration={DURATION}>

                    <div className='mr-[6rem]'>
                        <div className='font-SB service-sb-txt'>클릭 한번으로,</div>
                        <div className='font-EB service-eb-txt text-myweather mb-5'>간편한 SNS 로그인 제공.</div>
                        <div className='text-subGray mb-10 dt-sub'>간편한 SNS 로그인으로 쉽게 이용할 수 있습니다.</div>

                        <Pc><HashTag cur={cur} active={3}/></Pc>
                    </div>
                    <img src={`${img_url}/main2.png`} className='app-mockup' />
                </section>

                <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up" data-aos-duration={DURATION}>
                    <div className='relative flex justify-center items-center'>
                        <img src={`${img_url}/main6.png`} className='app-mockup'/>
                        <div className='absolute top-[17rem] flex justify-center items-center bg-white py-[1rem] pl-[1rem] border border-[#EEEEEE] rounded-[1rem]'>
                        <div className=' w-[8rem] '>
                            <Lottie animationData={sun} loop={true} />
                        </div>
                        <div className=' w-[7rem]'>
                            <Lottie animationData={partly_cloudy_night} loop={true} />
                        </div>
                        <div className=' w-[8rem]'>
                            <Lottie animationData={strong_rain} loop={true} />
                        </div>
                        <div className=' w-[8rem]'>
                            <Lottie animationData={strong_snow} loop={true} />
                        </div>
                        <div className=' w-[8rem] '>
                            <Lottie animationData={many_cloudy_night} loop={true} />
                        </div>
                        </div>
                    </div>

                    <div>
                        <div className='font-SB text-[2rem] text-right mb-5'>애니메이션 기반의,<br />
                            <span className='font-EB text-[1.8rem] text-myweather'>다양한 날씨 아이콘을 통한,</span><br />
                            감성적 날씨 정보 제공
                        </div>
                        <div className='text-subGray mb-10 text-right'>귀여운 애니메이션 기반의 아이콘으로<br /> 정확한 날씨정보를 제공해 드립니다.</div>
                        <Pc><HashTag direction="right" cur={cur} active={4}/></Pc>
                    </div>
                </section>

                <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up" data-aos-duration={DURATION}>

                    <div className='mr-[6rem]'>
                        <div className='font-SB service-sb-txt'>관리자페이지를 통해,</div>
                        <div className='font-EB service-eb-txt text-myweather mb-5'>실시간 데이터 관리.</div>
                        <div className='text-subGray mb-10'>관리자 웹으로 회원 관리 및 실시간 데이터를<br/>확인할 수 있어요.</div>

                        <Pc><HashTag cur={cur} active={5}/></Pc>
                    </div>
                    <img src={`${img_url}/main2.png`} className='app-mockup'/>
                </section>
                </Pc>
                <Mobile>
                <section className='flex flex-col layout items-center mb-[5rem]' data-aos="fade-up" data-aos-duration={DURATION}>
                <div className='flex items-center flex-col justify-center'>
                        <div className='font-SB service-sb-txt'><span className=' text-myweather font-EB'>개인 맞춤형</span> 날씨정보 및 <span className=' text-myweather font-EB'>카테고리</span></div>
                        <div className='font-SB service-sb-txt mb-5'>알림 설정 가능.</div>
                    </div>
                    <div className='flex items-center justify-center flex-col'>
                    <img src={`${img_url}/main1.png`} className='w-[35rem] ml-[14rem]'/>
                        <div className='text-subGray mb-10 !text-[1rem] mt-[3rem] text-center'>정확한 날씨데이터를 바탕으로 원하는 카테고리에 해당하는<br />알림을 즉시 보내드립니다.</div>
                    </div>
                </section>

                <section className=' h-screen flex flex-col layout items-center justify-center' data-aos="fade-up" data-aos-duration={DURATION}>
                    <div className='flex flex-col items-center justify-center'>
                        <div className='font-SB service-sb-txt text-right'>IoT 디바이스의 블루투스를 통한,</div>
                        <div className='font-EB service-eb-txt text-myweather mb-5 text-center'>실시간 기기 연동.</div>
                    <img src={`${img_url}/main3.png`} className='app-mockup'/>
                    </div>
                    <div className='flex items-center flex-col justify-center  '>
                        <div className='text-subGray mb-10 !text-[1rem] text-center'>마이웨더(IoT)를 어플리케이션과 연동하여<br />보다 더 정확한 실시간 정보를 받아 볼 수 있습니다.</div>
                    </div>
                </section>
                <section className=' h-screen flex layout items-center justify-between' data-aos="fade-up" data-aos-duration={DURATION}>

                <div className='flex items-center flex-col justify-center  '>
                        <div className='font-SB service-sb-txt'>클릭 한번으로,</div>
                        <div className='font-EB service-eb-txt text-myweather'>간편한 SNS 로그인 제공.</div>

                    </div>
                    <div className='flex justify-center items-center flex-col'>
                    <img src={`${img_url}/main2.png`} className='app-mockup' />
                        <div className='text-subGray mb-10 !text-[1rem]'>간편한 SNS 로그인으로 쉽게 이용할 수 있습니다.</div>
                    </div>
                </section>

                <section className=' relative h-screen flex flex-col layout items-center justify-center ' data-aos="fade-up" data-aos-duration={DURATION}>
                        <div className='flex flex-col justify-center items-center text-center'>
                        <div className='font-SB text-[1.4rem] mb-5'>애니메이션 기반의,<br />
                            <span className='font-EB text-[1.4rem] text-myweather'>다양한 날씨 아이콘을 통한,</span><br />
                            감성적 날씨 정보 제공
                        </div>
                        <img src={`${img_url}/main6.png`} className='app-mockup'/>
                        </div>
                        
                        <div className='absolute top-[21rem] left-[4rem] flex justify-center items-center bg-white pt-[2rem] pl-[1rem] border border-[#EEEEEE] rounded-[1rem]'>
                            <div className='flex itmes-center justify-between'>
                                <div className=' w-[5rem] '>
                                    <Lottie animationData={sun} loop={true} />
                                </div>
                                <div className=' w-[7rem]'>
                                    <Lottie animationData={strong_rain} loop={true} />
                                </div>
                                <div className=' w-[7rem]'>
                                    <Lottie animationData={strong_snow} loop={true} />
                                </div>
                                <div className=' w-[7rem] '>
                                    <Lottie animationData={many_cloudy_night} loop={true} />
                                </div>
                            </div>
                        </div>


                        <div className='flex items-center flex-col justify-center text-center '>
                        <div className='text-subGray mb-10 !text-[1rem] text-center'>귀여운 애니메이션 기반의 아이콘으로<br /> 정확한 날씨정보를 제공해 드립니다.</div>
                    </div>
                </section>

                <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up" data-aos-duration={DURATION}>

                <div className='flex flex-col justify-center items-center text-center'>
                        <div className='font-SB service-sb-txt'>관리자페이지를 통해,</div>
                        <div className='font-EB service-eb-txt text-myweather mb-5'>실시간 데이터 관리.</div>

                    </div>
                    <div className='flex items-center flex-col justify-center text-center '>
                    <img src={`${img_url}/main4.png`} className='app-mockup'/>
                        <div className='text-subGray mb-10 !text-[1rem] mt-[4rem]'>관리자 웹으로 회원 관리 및 실시간 데이터를<br/>확인할 수 있어요.</div>
                    </div>
                </section>
                </Mobile>
        </>
    )
}