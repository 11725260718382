import React, { Component, useEffect, useState, useRef } from 'react';
import { Routes, BrowserRouter,   RouterProvider ,useOutlet,createBrowserRouter} from 'react-router-dom';
import { Route, useLocation } from 'react-router';
import { motion, useMotionValue,useSpring } from "framer-motion"
import { SwitchTransition ,TransitionGroup, CSSTransition } from "react-transition-group";
import Top from 'component/Top';
import AOS from "aos";
import "aos/dist/aos.css";
import 'css/index.css'



export default function RootRouter() {
  const cursorX = useMotionValue(-100)
  const cursorY = useMotionValue(-100)
  const springConfig = { damping: 35, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);
  const currentOutlet = useOutlet();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      startEvent: 'DOMContentLoaded',
      duration:700
    });
    // AOS.refresh();
    // const moveCursor = (e) => {
    //   cursorX.set(e.clientX);
    //   cursorY.set(e.clientY);
    // };

    // window.addEventListener("mousemove", moveCursor);

    // return () => {
    //   window.removeEventListener("mousemove", moveCursor);
    // };
  }, []);

  return (
      <div>
        <Top isOpen={isOpen} setIsOpen={setIsOpen}/>
        
          <SwitchTransition>
          <CSSTransition
            
            key={location.pathname}
            timeout={300}
            classNames="page"
            unmountOnExit={true}>
            {(state) => (
              <div className="page w-screen">
                {currentOutlet}
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
  )
}