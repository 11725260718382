import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Service from 'component/Service';
import Lottie from 'lottie-react';
import { DURATION,Pc, Mobile } from 'value';
import HashTag from './HashTag';
import Circle from 'component/Circle';
import split from './animation/split5.json';
import PcHow from './pcHow';
import MobileHow from './mobileHow';

export default function Split() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {

        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <div className=' overflow-x-hidden'>
            <section className="top-bg h-screen">
                {/* <img src={`${img_url}/app.svg`} className='w-[10rem]' /> */}
                <Lottie animationData={split} loop={true} className='w-[20rem] mb-4 ' />
                <img src={`${img_url}/logo.png`} className='w-[16rem] mb-2' />
                <div className='text-split text-center text-[1.4rem]'>OTT서비스 계정 공유 플랫폼</div>
            </section>
            <div className='max-container'>
            <section className="flex layout">
                <div className="flex-1">
                    <div>
                        <img src={`${img_url}/logo.png`} className='w-52 mb-4 mt-36' />
                        
                        <div className='text-[1.4rem]'>OTT서비스 계정 공유 플랫폼</div>
                    </div>
                    <div className='mt-10 text-[1.5rem]'>
                        <div>프로젝트 기간 : 6개월</div>
                        <div>지원가능 OS : Android / IOS</div>
                        <div>제공언어 : 한국어</div>
                    </div>

                </div>
                <div className='relative back-mockup' >
                    <div className='w-[50rem] h-[50rem] rounded-full bg-split_10 absolute top-26 -left-14 -z-20' />
                    <img src={`${img_url}/mockup.svg`} className='z-[2] back-mockup' />
                </div>

            </section>
            <section className=' pb-20 relative top-[-10rem] z-0'>
                <div className='layout'>

                    <div className='subtitle'>Persona</div>
                    <div className='flex ml-16 mb-10 md:flex-col md:ml-0 md:items-center'>
                        <div>
                            <img className='w-[8rem]' src={`${img_url}/client.png`} />
                            <div className='font-SB text-[1.4rem] text-center mt-3'>클라이언트</div>
                        </div>
                        <div className='bg-white py-6 px-10 text-center rounded-lg ml-16 flex text-[1.4rem] md:ml-0 md:text-[1rem] sm:px-0'>
                            <img src={`${img_url}/mark1.png`} className='mt-[-3rem] w-10 mr-2 h-fit' />
                            OTT서비스가 늘어남에 따라, 금액이<br />
                            부담됩니다. 원하는 OTT서비스를 공유하고<br />
                            금액도 절약 할 수 있는 어플리케이션을 만들고 싶어요.
                            <img src={`${img_url}/mark2.png`} className='mt-[-2rem] w-10  ml-2 h-fit' />
                        </div>

                    </div>
                    <div className='subtitle'>
                        Service
                    </div>
                    <div className='ml-16 md:ml-0'>
                    <div className='flex md:flex-col md:items-center'>
                        <div className={`circle !border-split`}>수익 모델</div>
                        <div className={`circle  !border-split`}>결제 수수료</div>
                        <div className={`circle  !border-split`}>채팅<br/> 서비스</div>
                        <div className={`circle  !border-split`}>멤버 매칭</div>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/${cur}/service_arr.svg`} className='h-[10rem] w-[10rem] md:rotate-90' />

                        <div className={`bg-${cur} result-circle flex-col `}>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/${cur}/logo_w.svg`} className='w-[7rem]' />
                            <span className='mt-5 text-[1.2rem]'>OTT서비스<br/>공유플랫폼</span>
                            </div>
                    </div>
                        
                    </div>
                </div>
            </section>
            <section className='layout mb-32'>
                <div className='subtitle'>HOW IT WORKS</div>
                <Pc><PcHow img_url={img_url} cur={cur}/></Pc>
                <Mobile><MobileHow img_url={img_url} cur={cur}/></Mobile>
            </section>


            <section className='layout' data-aos="fade-up"  >
                <div className='subtitle'>BRANDING</div>

                <div className='flex justify-center items-start relative py-14 md:flex-col'>
                    <div className=' flex-1'>
                        <div className='font-EB text-[1.85rem]'>Color</div>
                        <div className='flex mt-10'>
                            <div className='bg-split w-[6rem] h-[6rem] rounded-md' />
                            <div className='ml-3'>
                                <div className='font-EB text-split text-[1.4rem]'>BLUE</div>
                                <div className='text-[1.4rem]'>#0060FC</div>
                            </div>
                        </div>
                        <div className='flex mt-3'>
                            <div className='bg-[#07CCD7] w-[6rem] h-[6rem] rounded-md mr-3' />
                            <div className='bg-[#FD3301] w-[6rem] h-[6rem] rounded-md mr-3' />
                            <div className='bg-[#FFBA01] w-[6rem] h-[6rem] rounded-md' />
                        </div>
                    </div>
                    <div className='flex-1 md:mt-[5rem]'>
                        <div className='font-EB text-[1.85rem]'>Font System</div>
                        <div className='mt-10 text-[1.8rem]'>AppleSDGothic</div>
                        <div className='flex  mt-5 text-[1.7rem] md:text-[1.5rem]'>
                            <div className='mr-2 '>영문</div>
                            <div>
                                <div>WELCOME TO SPLIT</div>
                                <div className='font-SB'>WELCOME TO SPLIT </div>
                                <div className='font-EB'>WELCOME TO SPLIT </div>

                            </div>
                        </div>

                        <div className='flex  mt-5 text-[1.7rem]  md:text-[1.5rem]'>
                            <div className='mr-2 '>한글</div>
                            <div>
                                <div>스플릿에 오신것을 환영합니다.</div>
                                <div className='font-SB'>스플릿에 오신것을 환영합니다.</div>
                                <div className='font-EB'>스플릿에 오신것을 환영합니다.</div>

                            </div>
                        </div>

                    </div>
                </div>

            </section>
            <section data-aos="fade-up"  >
                <div className='layout'>
                    <div className='subtitle'>KEYWORD</div>
                    <div className='flex items-center justify-between md:flex-col'>
                        <div className='flex-1 flex md:flex-col md:mb-[3rem]'>
                            <div className='font-B text-[1.7rem] text-split rounded-full border-split border w-[12rem] h-[12rem] flex justify-center items-center'>FRIENDLY</div>
                            <div className='font-B text-[1.7rem] text-split rounded-full border-split border w-[12rem] h-[12rem] flex justify-center items-center'>CUTE</div>
                            <div className='font-B text-[1.7rem] text-split rounded-full border-split border w-[12rem] h-[12rem] flex justify-center items-center'>CREDIT</div>
                        </div>
                        <div className='flex-1'>
                            <img src={`${img_url}/dock.png`} className='w-[32rem]' />
                        </div>

                    </div>
                </div>

            </section>
            <section className='layout py-28' data-aos="fade-up"  >
                <div className='subtitle'>BUSINESS MODEL</div>
                <div className='flex items-center justify-center text-[1.2rem]'>
                    <Pc>
                    <div className='flex relative'>
                        <div className='circle !border-split'>
                            <img src={`${img_url}/client.png`} className='w-[6rem]' />
                        </div>
                        <div className='text-[#FDB325] absolute right-[2.5rem] top-[5rem]'>요금+결제 수수료</div>
                        <img src={`${img_url}/yellow_arr.svg`} className='w-[13rem] transform -scale-y-100' />

                    </div>


                    <div className='flex relative ml-[-5rem]'>
                        <img src={`${img_url}/main_arr.svg`} className='w-[13rem] mt-10 transform -scale-y-100  -mb-[2rem]' />
                        <div className='text-split text-right absolute left-[3rem] bottom-[3.5rem] '>멤버 매칭 제공</div>
                        <div className='center-circle !border-split'>
                            <img src={`${img_url}/logo.svg`} className='w-[8rem]' />
                        </div>

                        <img src={`${img_url}/yellow_arr.svg`} className='w-[13rem] mt-10' />
                        <div className='text-[#FDB325] absolute right-[3rem] top-[8.5rem] '>결제 대행</div>
                    </div>

                    <div className='flex relative ml-[-4rem]'>
                        <div className='text-split text-right absolute left-[1.8rem] top-[4.2rem] '>OTT 서비스 제공</div>
                        <img src={`${img_url}/main_arr.svg`} className='w-[13rem] transform ml-[-0.5rem] -mt-[2rem]' />
                        <div className='circle z-[2] !border-split'>
                            <img src={`${img_url}/bus2.png`} className='w-[6rem]' />
                        </div>

                    </div>
                    </Pc>
                    <Mobile>
                    <img src={`${img_url}/mobile_bus.png`} className="w-[15rem] mt-[10rem]"/>
                    </Mobile>
                </div>
            </section>
            <section >
                <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
                <div className='md:flex md:items-center md:flex-col md:justify-center'>
                        <div className='font-SB service-sb-txt mb-5'>구독자가 원하는 OTT서비스를<br /><span className=' text-split font-EB'>안전한 계정 공유로</span>저렴하게.</div>
                        <Pc><HashTag cur={cur} active={1} /></Pc>
                    </div>
                    <div className='md:flex md:items-center md:justify-center'>
                    <img src={`${img_url}/main1.png`} className='w-[32rem]' />

                    </div>
                </section>
                <section className=' h-screen flex layout items-center justify-around'>
                <div className='md:flex md:items-center md:justify-center'>
                    <img src={`${img_url}/main2.png`} className='w-[32rem]'/>
                </div>
                    <div className='mr-[6rem] '>
                        <div className='font-SB service-sb-txt text-right'>매칭된 파티원 간의,</div>
                        <div className='font-EB service-eb-txt text-split mb-5 text-right'>채팅 기능 제공.</div>
                        <Pc><HashTag direction="right" cur={cur} active={2} /></Pc>
                    </div>
                </section>


                <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >

                <div className='md:flex md:items-center md:flex-col md:justify-center'>
                        <div className='font-SB service-sb-txt'>같이 볼 사람 찾을 필요없이,</div>
                        <div className='font-EB service-eb-txt text-split mb-5'>편리한 자동매칭.</div>
                        <Pc><HashTag cur={cur} active={3} /></Pc>
                    </div>
                    <div className='md:flex md:items-center md:justify-center'>
                    <img src={`${img_url}/main3.png`} className='w-[32rem]'/>

                    </div>
                </section>
            </section>

            <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
            <div className='md:flex md:items-center md:justify-center'>

                <img src={`${img_url}/main4.png`} className='w-[32rem]' />
                </div>
                <div className='md:flex md:items-center md:flex-col md:justify-center'>
                    <div className='font-SB service-sb-txt text-right'>결제 전에는 미리</div>
                    <div className='font-EB service-eb-txt text-split mb-5 text-right'>PUSH알림으로 알려드려요.</div>
                    <Pc><HashTag direction="right" cur={cur} active={4} /></Pc>
                </div>

            </section>

            <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >

            <div className='md:flex md:items-center md:flex-col md:justify-center'>
                    <div className='font-SB service-sb-txt'>클릭 한번으로,</div>
                    <div className='font-EB service-eb-txt text-split mb-5'>간편한 SNS 로그인 제공.</div>

                    <Pc><HashTag cur={cur} active={5} /></Pc>
                </div>
                <div className='md:flex md:items-center md:justify-center'>
                <img src={`${img_url}/main5.png`} className='w-[32rem]'/>

                </div>
            </section>


</div>
            <section className='flex flex-col bg-split items-center justify-end h-[80rem] relative' data-aos="fade-up"  >

                <div className='flex flex-col items-center justify-center mb-10'>

                    <img src={`${img_url}/btm_main.svg`} className='w-[42rem] top-0 absolute' />
                    <img src={`${img_url}/app.svg`} className='w-20 mb-4' />
                    <img src={`${img_url}/logo_w.png`} className='w-46 mb-4' />
                </div>

            </section>
        </div>
    )

}

