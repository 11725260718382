import React, { Component } from 'react';
import { Pc, Mobile } from 'value';

export default function Icon(props) {
    const { img_url } = props;
    return (
        <>
            <Pc>
                <div className='flex flex-col items-center justify-center'>

                    <div className='flex items-center justify-center'>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon1.svg`} className='myweather-icon' />
                            <div className='mt-3'>전체</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon2.svg`} className='myweather-icon' />
                            <div className='mt-3'>가사</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon3.svg`} className='myweather-icon' />
                            <div className='mt-3'>운동</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon4.svg`} className='myweather-icon' />
                            <div className='mt-3'>취미</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon5.svg`} className='myweather-icon' />
                            <div className='mt-3'>뷰티</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon6.svg`} className='myweather-icon' />
                            <div className='mt-3'>펫</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon7.svg`} className='myweather-icon' />
                            <div className='mt-3'>관리</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon8.svg`} className='myweather-icon' />
                            <div className='mt-3'>사업</div>
                        </div>


                    </div>

                    <div className='flex items-center justify-center mt-10'>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon9.svg`} className='myweather-icon' />
                            <div className='mt-3'>강수</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon10.svg`} className='myweather-icon' />
                            <div className='mt-3'>자외선</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon11.svg`} className='myweather-icon' />
                            <div className='mt-3'>공기질</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon12.svg`} className='myweather-icon' />
                            <div className='mt-3'>일사주의</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.2rem] mr-8'>
                            <img src={`${img_url}/icon13.svg`} className='myweather-icon' />
                            <div className='mt-3'>동파주의</div>
                        </div>

                    </div>
                </div>
            </Pc>
            <Mobile>
                <div className=' mx-[3rem]'>
                    <div className='flex items-center justify-between mt-[3rem]'>

                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon1.svg`} className='myweather-icon' />
                            <div className='mt-3'>전체</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon2.svg`} className='myweather-icon' />
                            <div className='mt-3'>가사</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon3.svg`} className='myweather-icon' />
                            <div className='mt-3'>운동</div>
                        </div>
                    </div>
                    <div className='flex items-center  justify-between mt-[3rem]'>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon4.svg`} className='myweather-icon' />
                            <div className='mt-3'>취미</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon5.svg`} className='myweather-icon' />
                            <div className='mt-3'>뷰티</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon6.svg`} className='myweather-icon' />
                            <div className='mt-3'>펫</div>
                        </div>
                    </div>
                    <div className='flex items-center justify-between mt-[3rem]'>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon7.svg`} className='myweather-icon' />
                            <div className='mt-3'>관리</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon8.svg`} className='myweather-icon' />
                            <div className='mt-3'>사업</div>
                        </div>

                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon9.svg`} className='myweather-icon' />
                            <div className='mt-3'>강수</div>
                        </div>
                    </div>

                    <div className='flex items-center  justify-between mt-[3rem]'>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon10.svg`} className='myweather-icon' />
                            <div className='mt-3'>자외선</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon11.svg`} className='myweather-icon' />
                            <div className='mt-3'>공기질</div>
                        </div>
                        <div className='flex flex-col justify-center items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon12.svg`} className='myweather-icon' />
                            <div className='mt-3'>일사주의</div>
                        </div>
                    </div>
                    <div className='flex items-center  justify-between mt-[3rem]'>
                        <div className='flex flex-col self-start items-center text-[1.4rem]'>
                            <img src={`${img_url}/icon13.svg`} className='myweather-icon' />
                            <div className='mt-3'>동파주의</div>
                        </div>
                    </div>
                </div>

            </Mobile>

        </>


    )

}