import React, { Component } from 'react';

export default function Contents6() {
    return (
        <section className=' flex flex-col bg-white justify-center leading-tight fullpage z-10'>
           
<div className='text-[5rem] md:text-[2.8rem] sm:text-[1.7rem] leading-none w-screen h-screen justify-center flex flex-col ml-[10rem] md:ml-[2rem] items-start' >
<div className='italic'>
    Let's make <span className='font-GB -mt-10'> something </span>great!
</div>
<div className='hover:scale-120 italic my-10 text-paletti pb-4 border-b-4 border-paletti'>
    admin@paletti.ai
</div>
<div className='flex italic'>for &nbsp;
<div>
    </div>
collaborations.
    <img
        className="comp-spin w-[2rem] ml-2"
        src={`${process.env.PUBLIC_URL}/asset/img/comp1.svg`} />

</div>
<div className='flex mt-10'>

    <div className='text-[4rem] mr-2'>🏢</div>
    <div className='text-[1.4rem] sm:text-[1.2rem] font-GM mt-2'>
        대한민국<br />
        부산시 강서구<br />
        명지 국제 2로 80 3-56호
    </div>
</div>

</div>
        </section>
    )
}

