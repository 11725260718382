import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PortfolioComp from 'component/PortfolioComp';
import { useTrail, useScroll, animated, useSpring } from '@react-spring/web'
import { Pc, Mobile } from 'value';
import 'css/home.css';
import 'css/portfolio.css';
import PortfolioCompM from 'component/PortfolioCompM';

const pages = [
    [{
        name: 'LittleMate',
        link: 'littlemate',
        date: '',
        kind: 'Application'
    },

    {
        name: 'Metaverse',
        link: 'metaverse',
        date: '',
        kind: 'Application'
    },
    {
        name: 'Cablecar',
        link: 'cablecar',
        date: '',
        kind: 'Application'
    },
    {
        name: 'Diet Challenge',
        link: 'diet',
        date: '',
        kind: 'Application'
    },
    {
        name: 'ETL',
        link: 'etl',
        date: '',
        kind: 'Application'
    },
    {
        name: 'Uhyu',
        link: 'uhyu',
        date: '',
        kind: 'Application'
    },
    {
        name: 'Ranchan',
        link: 'ranchan',
        date: '',
        kind: 'Application'
    },
    ],

    [
        {
            name: 'Saferoom',
            link: 'saferoom',
            date: '',
            kind: 'Application'
        },
        {
            name: 'MyWeather',
            link: 'myweather',
            date: '',
            kind: 'Application'
        },
        {
            name: 'Split',
            link: 'split',
            date: '',
            kind: 'Application'
        },

        {
            name: 'Welfare',
            link: 'welfare',
            date: '',
            kind: 'Application'
        },
        {
            name: 'Heuba',
            link: 'heuba',
            date: '',
            kind: 'Website'
        },
        {
            name: 'Hana',
            link: 'hana',
            date: '',
            kind: 'Website'
        },

    ]

]

export default function Portfolio(props) {

    const location = useLocation();
    const [cur, setCur] = useState('');
    const [textStyles, textApi] = useSpring(() => ({
        y: '100%',
        opacity: 1
    }));
    const [txt, setTxt] = useState('Work')

    useEffect(() => {
        setCur(location.pathname)
        // console.log(props);
    }, [location]);
    const fast = { tension: 1200, friction: 40 }
    const slow = { mass: 10, tension: 200, friction: 50 }
    const [trail, api] = useTrail(1, i => ({
        xy: [0, 0],
        config: i === 0 ? fast : slow,
    }))
    const trans = (x, y) =>
        `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`

    // const img_url = `${process.env.PUBLIC_URL}/asset/img`;
    const containerRef = React.useRef(null)
    const { scrollYProgress } = useScroll({
        container: containerRef,
        // onChange: ({ value: { scrollYProgress } }) => {
        //       if (scrollYProgress < 0.3) {
        //         textApi.start({ y: '0',opacity:0 })
        //         setTxt('Work');
        //       } else if(scrollYProgress>0.3 && scrollYProgress<0.5) {
        //         setTxt('Together');
        //         textApi.start({ y: '100%' ,opacity:1})
        //       }else if(scrollYProgress>0.5 && scrollYProgress<0.7){
        //         textApi.start({ y: '0',opacity:0 })
        //       }else{
        //         textApi.start({ y: '100%' ,opacity:1})
        //       }
        // },
        default: {
            immediate: true,
        },
    })
    return (
        <div className='fullpage flex mt-[5rem] md:justify-center'>
            <Pc>
                <div ref={containerRef}
                className='flex w-screen  sm:block md:border-l md:border-r md:border-[#C6C6CA] overflow-y-auto'>
                    <div className='flex flex-1'>
                    <animated.div className="fixed w-1/2 h-screen bg-paletti justify-center flex md:hidden "
                        style={{
                            zIndex: -10,
                            clipPath: scrollYProgress.to(val => `circle(${val * 50}%)`),
                        }}>
                        <div></div>
                        <div className='flex flex-col justify-around relative'>
                            <div>
                                <div className=' font-GB font-extrabold text-[8rem] text-white'>Portfolio.</div>
                                <div className='flex items-start mt-10'>
                                    <div className='border-t-4 w-14 mr-10 border-white'
                                    />
                                    <div
                                        className='text-white text-[1.5rem]'>
                                        We are creative.<br />
                                        The more you use.<br />
                                        The more you have.
                                    </div>
                                </div>
                            </div>

                            <div
                                className='mt-32 text-white text-[2rem] font-GB'>
                                Paletti co.
                            </div>
                        </div>
                        {/* </div> */}
                    </animated.div>
                    </div>
                    <div className='flex justify-end w-1/2'>
                        {pages.map((page, idx) => {
                            let num = idx == 0 ? -1 : 0;
                            return (
                                <div className={` ${idx == 1 ? 'pt-[16rem]' : ''}  w-1/2 flex flex-1 flex-col`} key={idx}>

                                    {page.map((item, idx2) => {
                                        const len = page.length;
                                        const { name, link, date, kind } = item;
                                        num += 2;
                                        return (
                                            <div className='hover:text-paletti ' key={name} >
                                                <NavLink to={`${cur}/${link}`} className='hover:opacity-100'>
                                                    <div className={`${idx == 0 ? 'border-r border-l' : ''} bg-white font-GM flex justify-between h-[30rem] items-end p-3 border-t border-[#C6C6CA]`}>
                                                        <div className='text-[2.3rem] font-GB'>
                                                            {name}
                                                        </div>
                                                        <div className='text-[#C6C6CA] font-GB text-7xl'>
                                                            {num}
                                                        </div>
                                                    </div>

                                                
                                                    <PortfolioComp
                                                    
                                                        idx={idx} btm={idx2 == len - 1}
                                                        name={name} link={`${process.env.PUBLIC_URL}/asset/img${cur}/${link}.png`} />

                                                </NavLink>
                                            </div>

                                        )

                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Pc>
            <Mobile>


                <div className='flex overflow-y-auto justify-center'>
                    {pages.map((page, idx) => {
                        let num = idx == 0 ? -1 : 0;
                        return (
                            <div className={`flex flex-col justify-start items-start pt-[6rem]`}>

                                {page.map((item, idx2) => {
                                    const len = page.length;
                                    const { name, link, date, kind } = item;
                                    num += 2;
                                    return (
                                        <div className='hover:text-paletti mt-[2rem]'>
                                            <NavLink to={`${cur}/${link}`} >
                                                <PortfolioCompM
                                                    name={name} link={`${process.env.PUBLIC_URL}/asset/img/portfolio`} />
                                            </NavLink>
                                        </div>

                                    )

                                })}
                            </div>
                        )
                    })}
                </div>

            </Mobile>

            {/* </div> */}
        </div>
    )
}


{/* <div className='inner h-screen justify-center '>
<div className='flex h-screen items-center'>
    <div className='flex overflow-y-auto pl-8 pt-5'>
    {pages.map((page) => {
        const { name, link, date, kind } = page;
        return (
            <NavLink to={`${cur}/${link}`}>
                <PortfolioComp name={name} date={date} kind={kind} link={`${process.env.PUBLIC_URL}/asset/img${cur}/${link}.png`} />
            </NavLink>
        )
    })}
    </div>

</div>
</div> */}