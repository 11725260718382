import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import Keyword from 'component/Keyword';
import Service from 'component/Service';
import Icon from './Icon';
import guremi from './animation/guremi';
import Character from './Character';

import partly_cloudy_morning from './animation/partly_cloudy_morning';
import {DURATION,Pc} from 'value';
import MainService from './MainService';


export default function Myweather() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {

        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <div className=' overflow-x-hidden'>
            <section className="top-bg bg-gradient-to-r from-[#4363F2] to-[#3798FF] h-screen">
                <Lottie animationData={partly_cloudy_morning} loop={true} className='w-[20rem]'/>
                {/* <img src={`${img_url}/app.svg`} className='w-20 mb-4' /> */}
                <img src={`${img_url}/logo_w.svg`} className='w-72 mb-2' />
                <div className='text-white text-center text-[1.3rem] md:text-[1rem] '>개인 기상 관측 기기(웨더박스)를 이용한 <br/>날씨 정보 수집 및 제공 플랫폼</div>
            </section>
            <div className='max-container'>
            <section className="flex layout">
                <div className="flex-1  mt-36">
                    <div >
                    
                        <img src={`${img_url}/logo.svg`} className='w-[22rem] mb-4' />
                        <div className='text-[1.5rem] break-words'>개인 기상 관측 기기(웨더박스)를 이용한<br/> 날씨 정보 수집 및 제공 플랫폼</div>
                    </div>
                    <div className='mt-10 text-[1.4rem]'>
                        <div>프로젝트 기간 : 6개월</div>
                        <div>지원가능 OS : Android / IOS</div>
                        <div>제공언어 : 한국어</div>
                    </div>

                </div>
                <div className='relative back-mockup z-0 md:-left-10'  data-aos="fade-up"  >
                    <img src={`${img_url}/mockup.svg`} />
                </div>

            </section>
            <section className='bg-[#F3F9FF] pt-[3rem] pb-20 relative top-[-10rem] -z-10' >
                <div className='layout'>
                    <div className='subtitle mt-[5rem]'>Persona</div>
                    <div className='flex ml-16 mb-10 md:flex-col items-center md:ml-0' >
                        <div className='flex flex-col items-center justify-center self-start md:self-center'>
                            <img className='w-[15rem]' src={`${img_url}/client.png`} />
                            <div className='font-SB text-[1.4rem] mt-3 md:mt-0'>클라이언트</div>
                        </div>
                        <div className='bg-white py-6 px-10 text-center text-[1.4rem] rounded-lg ml-16 flex md:mt-[5rem] md:ml-0 md:text-[1.2rem]'>
                            <img src={`${img_url}/mark1.png`} className='-mt-[1rem] w-6 mr-2 h-fit' />
                            개인 기상 관측기기를 이용하여<br />
                            보다 정확한 날씨정보를<br />
                            제공할 수 있는 어플리케이션을<br />
                            만들고 싶습니다.
                            <img src={`${img_url}/mark2.png`} className='mt-[-1rem] w-6  ml-2  h-fit' />
                        </div>

                    </div>
                    <div className='subtitle'>
                        Service
                    </div>
                    <div className='ml-16 md:ml-0' data-aos="fade-up"  >
                        <Service 
                        first='수익모델'
                        second={`날씨 데이터\n수집 및 판매`}
                        third='웨더박스 판매'
                        fourth='날씨 데이터 제공'
                        result={`웨더박스를 이용한\n 
                        날씨 정보 수집 및 제공`}
                        path={cur} />
                    </div>
                </div>
            </section>
            <section className='mb-32' data-aos="fade-up"  >
                <div className='layout mt-10 flex flex-col items-end justify-end'>
                    <div className='flex items-end justify-end'>
                    <img src={`${img_url}/logo_2.svg`} className='w-36' />
                    </div>
                    <div className='mt-2 text-[1.4rem] text-right'><span className='font-EB text-myweather'>웨더박스</span>를 이용한<br />
                        날씨정보 <span className='font-EB text-myweather'>수집</span>  및 <span className='font-EB text-myweather'>제공</span> 플랫폼
                    </div>
                </div>
                <div className='flex relative justify-center'>
                    <img src={`${img_url}/mockup1.png`} className='w-[60rem]' />
                    <div className='bg-[#18A8F115] w-[50rem] h-[50rem] rounded-full absolute left-[10rem] -top-[10rem] -z-[1]'></div>

                </div>
            </section>
            <section className='layout' data-aos="fade-up"  >
                <div className='subtitle'>BRANDING</div>

                <Keyword />

                <div className='my-40 pb-60'>
                    <div className='font-EB text-[2.3rem] mb-[2rem] text-[#18A8F1]'>Logo</div>
                    <div className='flex items-center justify-between'>
                        <div className='flex-1 '>
                            <img src={`${img_url}/logo_2.svg`} className='w-70 mb-8' />
                            <div className='text-[1.4rem]'>My의 <span className='font-EB'>M</span>과 Weather의 <span className='font-EB'>W</span> + 구름모양</div>
                        </div>
                        <div className='flex relative flex-1 justify-end -mt-5'>
                            <div className='bg-[#18A8F115] w-[50rem] h-[50rem] rounded-full absolute left-[10rem] -top-[13rem]  -z-[1]'></div>
                            <div className='flex -mb-56 md:-mb-[40rem]'>
                                <img src={`${img_url}/logo_3d_w.svg`} className='w-80 z-[1]' />
                                <img src={`${img_url}/logo_3d_c.svg`} className='w-80 -ml-[5rem] -mt-[7rem]' />
                            </div>
                        </div>
                    </div>
                </div>


            </section>

            <section className='flex flex-col py-20 bg-[#FCFCFC] z-[2]' data-aos="fade-up"  >
                <div className='layout'>

                    <div className='text-[#B1B1B1] font-EB text-[2.4rem]'>Character</div>
                    <div className='mt-5 text-[1.3rem]'>"날씨"를 표현할 수 있는 귀여운 캐릭터를<br />제작하였습니다.</div>
                    <Character img_url={img_url} />
                    <div className='flex-1 my-16'>
                        <div className='subtitle'>ICON</div>
                            <Icon img_url={img_url} />
                    </div>

                </div>
            </section>
            <section className='py-20' >
                <div className='flex justify-center items-center flex-col'>
                    <div className='text-[1.2rem] text-[#DDDDDD]' data-aos="fade-up"  > Main Screen</div>
                    <div className='relative'>
                    <img src={`${img_url}/main_screen.svg`} className='w-[30rem]' data-aos="fade-up"  />
                    <Pc>
                        <div className='absolute top-[20rem] -left-[13rem] flex flex-col items-center justify-center' data-aos="fade-up"  >
                            <div>
                            <div className='dt-title'><span className={`text-${cur}`}>중요한 정보</span>를 메인화면에서.</div>
                            <div  className='dt-sub text-expGray'>오늘의 날씨와 온도,습도 등의 건강에 필요한 정보를<br />첫 화면에서 바로 확인이 가능해요.</div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='w-36 ' />
                        </div>
                            <img src={`${img_url}/cloud_bg.svg`} className='absolute -z-[2] w-[90rem] bottom-[20rem] -right-[20rem]' />
                        <div className='absolute top-[12rem] -right-[15.3rem] flex flex-col items-start'>
                            <div className=' left-[7rem] relative '>
                            <img src={`${img_url}/cloud_bg.svg`} className='absolute -z-[2] w-[90rem] bottom-[25rem] -right-[10rem]' />
                            <img src={`${img_url}/time.png`} className='w-[22rem] ' />
                            <div className='dt-title'><span className={`text-${cur}`}>시간별 or 주간별</span>날씨도.</div>
                            <div className='dt-sub text-expGray'>버튼을 누르면 시간대별, 주간별<br />날씨 및 상세 날씨도 확인 할 수 있어요.</div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='w-36 transform rotate-90' />
                        </div>
                        <div className='absolute top-[55rem] -right-[18rem] flex flex-col items-start' data-aos="fade-up"  >
                            <div>
                            <div className='dt-title'><span className={`text-${cur}`}>하루 요약</span>으로 한번에.</div>
                            <div className='dt-sub text-expGray'>한눈에 오늘 하루의 날씨를<br/> 확인 할 수 있어요.</div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='w-36 transform -scale-x-100 relative -left-24' />
                        </div>
                        <div className='absolute top-[65rem] -left-[14rem] flex flex-col items-center justify-center'>
                            <div>
                            <div className='dt-title'><span className={`text-${cur}`}>미세먼지</span>수치를 손쉽게.</div>
                            <div className='dt-sub text-expGray'>미세먼지 수치에 따라 다른 마커색상을 이용해서<br/>눈으로 쉽게 확인 할 수 있어요.</div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='w-36' />
                        </div>
                        <div className='absolute top-[105rem] -right-[15rem] flex flex-col items-center justify-center' data-aos="fade-up"  >
                            <div className='relative left-24'>
                            <div className='dt-title'><span className={`text-${cur}`}>활동지수</span>에 따른 추천정보.</div>
                            <div className='dt-sub text-expGray'>날씨정보를 바탕으로<br/>사용자가 선택한 알맞은 활동들을 추천 해드려요.</div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='w-36 transform -scale-x-100' />
                        <div className='absoulte'>
                        
                        </div>
                        </div>
                        </Pc>
                    </div>
                </div>
            </section>
            <section>

              <MainService img_url={img_url} cur={cur}/>
            </section>
            </div>


            <section className='flex flex-col bg-myweather items-center justify-end h-[80rem] relative' data-aos="fade-up"  >

                <div className='flex flex-col items-center justify-center mb-10'>

                    <img src={`${img_url}/btm_main.svg`} className='w-[52rem] top-0 absolute' />
                    <img src={`${img_url}/app.svg`} className='w-24 mb-4' />
                    <img src={`${img_url}/logo_w.svg`} className='w-56 mb-4' />
                </div>

            </section>
        </div>
    )

}

