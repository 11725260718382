import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { DURATION, Mobile, Pc } from 'value';
import MobilePage from './mobilePage';
import PcPage from './pcPage';

export default function Hana() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;

    useEffect(() => {
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <div className=' overflow-x-hidden'>
            <section className={`top-bg h-screen md:bg-center`} style={{ backgroundImage: `url("${img_url}/back.png")` }}>
                <div className='font-B text-[4rem]  md:text-[3rem] flex flex-col w-screen px-32 sm:justify-center sm:items-center sm:text-center'>
                    <div className='text-hana tracking-[3rem]  md:tracking-[1rem]'>HANA</div>
                    <div className='tracking-[3rem]  md:tracking-[1rem] justify-end text-right text-white'>FUNERAL</div>
                </div>
            </section>
            <div className='max-container'>
            <section className='flex flex-col justify-center items-center relative py-32'>
                <img src={`${img_url}/mockup.png`} className='w-[70rem]' />
                <div className='flex items-center justify-center md:flex-col sm:mt-[8rem]'>
                    <div className='mr-[5rem] w-[20rem] text-[1.5rem] md:mb-[4rem] md:mr-0'>
                        <div className='text-[2rem] font-B mb-4'>CLIENT</div>
                        <div className='border-b-2 mb-4' />
                        <div>하나 상조 서비스</div>
                    </div>
                    <div className='mr-[5rem] w-[20rem] text-[1.5rem] md:mb-[4rem] md:mr-0'>
                        <div className='text-[2rem] font-B mb-4'>SERVICE</div>
                        <div className='border-b-2 mb-4' />
                        <div>Dynamic Website</div>
                    </div>
                    <div className='mr-[5rem] w-[20rem] text-[1.3rem] md:mb-[4rem] md:mr-0'>
                        <div className='text-[2rem] font-B mb-4'>PROGRAM</div>
                        <div className='border-b-2 mb-4' />
                        <div>Photoshop, Illustrator</div>
                    </div>
                </div>
                <div className='w-[60rem] h-[20rem] bg-hana absolute top-[8rem] -z-10' />
            </section>
            <section className='bg-[#F1F1F1] flex flex-col items-center justify-center py-20'>
                <img src={`${img_url}/main1.png`} className='mt-10 w-[65rem]' data-aos="fade-up"   />
                <img src={`${img_url}/main2.png`} className='mt-10 w-[65rem]' data-aos="fade-up"   />
                <img src={`${img_url}/main3.png`} className='mt-10 w-[65rem]' data-aos="fade-up"   />
                <img src={`${img_url}/main4.png`} className='mt-10 w-[65rem]' data-aos="fade-up"   />
            </section>
            <section className='layout py-32' data-aos="fade-up"  >
                <div className='font-B text-[2.6rem] mb-24'>ICON</div>
                <div className='flex justify-between px-[2rem]'>
                    <div className='flex flex-col items-center justify-between mr-[10rem] md:mr-[1rem]'>
                        <img src={`${img_url}/icon1.svg`} className='mb-10 w-[6rem]' />
                        <img src={`${img_url}/icon2.svg`} className='mb-10 w-[6rem]' />
                        <img src={`${img_url}/icon3.svg`} className='w-[5.5rem]' />
                    </div>
                    <div className='flex flex-col items-center justify-between mr-[10rem] md:mr-[1rem]'>
                        <img src={`${img_url}/icon4.svg`} className='mb-10 w-[6rem]' />
                        <img src={`${img_url}/icon5.svg`} className='mb-10 w-[4rem] sm:w-[3rem]' />
                        <img src={`${img_url}/icon6.svg`} className='w-[6rem]' />
                    </div>
                    <div className='flex flex-col items-center justify-between mr-[10rem] md:mr-[1rem]'>
                        <img src={`${img_url}/icon7.svg`} className='mb-10 w-[6rem]' />
                        <img src={`${img_url}/icon8.svg`} className='mb-10 w-[6rem]' />
                        <img src={`${img_url}/icon9.svg`} className='w-[5rem]' />
                    </div>
                    <div className='flex flex-col items-center justify-start'>
                        <img src={`${img_url}/icon10.svg`} className='mb-5 w-[6rem]' />
                        <img src={`${img_url}/icon11.svg`} className='mt-8 w-[6rem]' />

                    </div>
                </div>
            </section>
          

        <Mobile>
            <MobilePage/>
        </Mobile>
        <Pc>
            <PcPage/>
        </Pc>
</div>
            <section className={`mt-[15rem] flex flex-col bg-${cur} items-center justify-end h-[30rem]`}>
            </section>
        </div>
    )

}


