export default function HashTag(props) {
    const { direction,cur,active } = props;

    return (
        <div className={direction == "right" ? 'flex flex-col items-end' : 'flex flex-col'}>
            <div className='flex'>
                <div className={`hash-tag !border-uhyu ${active==1 ? `bg-uhyu text-white hover:text-uhyu hover:bg-white` :`text-uhyu hover:text-white hover:bg-uhyu`}  mr-2`}>In-App 결제</div>
                <div className={`hash-tag !border-uhyu ${active==2 ? `bg-uhyu text-white hover:text-uhyu hover:bg-white` :`text-uhyu hover:text-white hover:bg-uhyu`}  mr-2`}>전자계약 시스템</div>
                <div className={`hash-tag !border-uhyu ${active==3 ? `bg-uhyu text-white hover:text-uhyu hover:bg-white` :`text-uhyu hover:text-white hover:bg-uhyu`}  mr-2`}>카카오 지도 연동</div>
                <div className={`hash-tag !border-uhyu ${active==4 ? `bg-uhyu text-white hover:text-uhyu hover:bg-white` :`text-uhyu hover:text-white hover:bg-uhyu`}  mr-2`}>SNS 로그인</div>

            </div>
            
        </div>
    )

}