import React, { Component } from 'react';

export default function PortfolioCompM(props) {
        const {name, link} = props;
        return (
                <div className='flex flex-col  text-center'>
                        <img src={`${link}/folder.png`} className='w-[20rem]'/>
                        <div className='text-[1.3rem] font-SB'>{name}</div>
                </div>
        )

}





{/* <div className='border-solid border-black border-t-2 mr-8 w-[26rem] min-w-[15rem] hover:scale-110'>
<div className='font-B text-md'>{name}</div>
<div className='mt-10'>{kind}</div>
<div className='text-[#B1B1B1] text-sm'>{date}</div>
<div className='mt-5'>
<img src={link} className='w-[30rem] h-auto'/>
</div>
</div> */}