import React, { useLayoutEffect, Component, Suspense, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, MotionConfig, useMotionValue } from "framer-motion";


export default function Contents4(props) {


  return (


    <section className=' w-screen h-screen flex flex-col justify-center items-center fullpage'>

      <div className='font-GB text-[7rem] md:text-[5rem] sm:text-[3.5rem] '>PALETTI CO.</div>


      <div className='font-GB fill-txt text-center text-[7rem] md:text-[5rem] sm:text-[2.4rem]'>
        WANT TO SEE{'\n'}
        <div className='flex'>
          HOW <img className="w-[5rem] h-[5rem] sm:w-[3rem] sm:h-[3rem] sm:mt-1 mt-3 mr-1" src={`${process.env.PUBLIC_URL}/asset/img/logo.png`} />WE WORK
        </div>WITH CLIENTS ?

      <img
        className="comp-spin absolute -left-10 md:w-[2rem]"
        src={`${process.env.PUBLIC_URL}/asset/img/comp1.svg`} />

      <img
        className="comp-spin absolute right-9 top-10  md:w-[2rem]"
        src={`${process.env.PUBLIC_URL}/asset/img/comp1.svg`} />
      <img
        className="comp-spin absolute -right-10 bottom-0  md:w-[2rem]"
        src={`${process.env.PUBLIC_URL}/asset/img/comp1.svg`} />
</div>
           <NavLink to="/portfolio">
            <motion.div
              variants={{ hover: { scale: 0.85 }, press: { scale: 1.1 } }}
              className="label flex items-center"
            >


              <div
                className='z-[1]'
              >
                <div
                  className='text-[2.5rem] sm:text-[2rem] z-[1] flex items-center bg-white font-FaktumR border-2 border-black rounded-full text-center py-1 px-4 hover:scale-125'>
                  OUR WORK
                  <img
                    className='w-[2.6rem] h-[2.6rem] ml-3 -rotate-90'
                    src={`${process.env.PUBLIC_URL}/asset/img/arr3.svg`} />
                </div>

              </div>

            </motion.div> 
          </NavLink>
      

    </section>
  )
}
