import React, { Component } from 'react';
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";


export default function Contact() {
    const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
    const FadeUp = batch(Fade(), Move(), Sticky());
    const img_url = `${process.env.PUBLIC_URL}/asset/img`;
    return (
        <div className='inner' >
            <div className='max-container'>
            <ScrollContainer>
                <ScrollPage>
                    <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
                        <div className='flex flex-col text-center justify-center items-center relative'>
                        <div className='italic font-GB text-cetner text-[#f8f8f8] absolute -bottom-[20rem] -left-[25rem] text-[18.5rem] -z-10'>
                                PALETTI CO.
                            </div>
                            <div className='text-[10.5rem] font-GB leading-none md:text-[5.5rem] sm:text-[3rem] w-screen'>
                                <span className='text-[#818390]'>We don't<br />just think.</span>
                                <br />We do<span className='text-paletti'>.</span></div>

                        </div>
                    </Animator>
                </ScrollPage>

                <ScrollPage>
                    <div className='font-GB flex-col text-[4.2rem] md:text-[3rem] sm:text-[1.5rem]' style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }} >

                        <Animator className='mb-3'>AI<span className='text-paletti'>.</span></Animator>
                        <Animator className='mb-3'>APPLICATION<span className='text-paletti'>.</span></Animator>
                        <Animator className='mb-3'>METAVERSE<span className='text-paletti'>.</span></Animator>
                        <Animator className='mb-3'>AR / VR<span className='text-paletti'>.</span> </Animator>
                        <Animator className='mb-3'>WEBSITE<span className='text-paletti'>.</span></Animator>
                        <Animator className='mb-3'>SOFTWARE<span className='text-paletti'>.</span></Animator>
                        <Animator >DIGITAL TWIN<span className='text-paletti'>.</span></Animator>

                        {/* <Animator animation={MoveIn(-1000, 0)}>AI🤖</Animator>
                            <Animator animation={MoveIn(1000, 0)}>APPLICATION 🙋🏻‍♀️</Animator>
                            <Animator animation={MoveOut(1000, 0)}>METAVERSE ✋🏻</Animator>
                            <Animator animation={MoveOut(1000, 0)}>AR / VR ✋🏻</Animator>
                            <Animator animation={MoveOut(-1000, 0)}>WEBSITE 💛</Animator>
                            <Animator animation={MoveOut(1000, 0)}>SOFTWARE 💛</Animator>
                            <Animator animation={MoveOut(-1000, 0)}>DIGITAL TWIN 💛</Animator> */}
                    </div>
                </ScrollPage>
                <ScrollPage>
                    <Animator animation={batch(Fade(), Sticky())}>
                        <div className='text-[5rem] md:text-[2.8rem] sm:text-[1.7rem] leading-none w-screen h-screen justify-center flex flex-col ml-[10rem] md:ml-[2rem] items-start' >
                            <div className='italic'>
                                Let's make <span className='font-GB '> something </span>great!
                            </div>
                            <div className='hover:scale-120 italic my-10 text-paletti pb-4 border-b-4 border-paletti'>
                                admin@paletti.ai
                            </div>
                            <div className='flex italic'>for &nbsp;
                            <div>
                                </div>
                            collaborations.
                                <img
                                    className="comp-spin w-[2rem] ml-2"
                                    src={`${process.env.PUBLIC_URL}/asset/img/comp1.svg`} />

                            </div>
                            <div className='flex mt-10'>

                                <div className='text-[4rem] mr-2'>🏢</div>
                                <div className='text-[1.4rem] sm:text-[1.2rem] font-GM'>
                                    대한민국<br />
                                    부산시 강서구<br />
                                    명지 국제 2로 80 3-56호
                                </div>
                            </div>

                        </div>
                    </Animator>
                </ScrollPage>
            </ScrollContainer>
            </div>
            <div className='footer font-GM text-[1.2rem] md:text-[1rem] text-center w-screen mb-3'>ALL RIGHTS RESERVED © 2023 PALETTI CO.</div>
        </div>
    )
} 
