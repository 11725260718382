import React from "react"
import { useSpring, useTransform } from "framer-motion";
import { useMediaQuery } from "react-responsive"

export const LITTLEMATE = "#019AF7";
export const transition = {
  type: "spring",
  duration: 0.7,
  bounce: 0.2
};

export const DURATION = 400;
export function useSmoothTransform(value, springOptions, transformer) {
  return useSpring(useTransform(value, transformer), springOptions);
}

export function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}


export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)"
  });
  return <>{isMobile && children}</>
}

export const Pc = ({ children }) => {
  const isPc = useMediaQuery({
    query: "(min-width:769px)"
  });
  return <>{isPc && children}</>
}
