import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Service from 'component/Service';
import { DURATION, Pc } from 'value';
import HashTag from './HashTag';

export default function Uhyu() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {

        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <div className=' overflow-x-hidden'>
            <section className="top-bg bg-gradient-to-r from-[#F3A142] to-[#EB6720] h-screen">
                <img src={`${img_url}/logo_w.svg`} className='w-48 mb-2' />
                <div className='text-white text-center text-[1.4rem] mt-[1rem]'>대학생을 타겟으로한 부동산 중개 거래 어플리케이션</div>
            </section>
            <div className='max-container'>
                <section className="flex layout"  >
                    <div className="flex-1">
                        <div>
                            <img src={`${img_url}/logo.svg`} className='w-36 mb-4 mt-36' />
                            <div className='text-[1.4rem]'>대학생을 타겟으로한 부동산 중개 거래 어플리케이션</div>
                        </div>
                        <div className='mt-10 text-[1.3rem]'>
                            <div>프로젝트 기간 : 6개월</div>
                            <div>지원가능 OS : Android / IOS</div>
                            <div>제공언어 : 한국어</div>
                        </div>

                    </div>
                    <div className='relative back-mockup z-0 ' >
                        <img src={`${img_url}/mockup.svg`} className='w-[60rem]' />
                        <div className='absolute right-[10rem] top-[10rem] -z-[1] flex justify-center items-center'>
                            <div className='bg-[#FF6C1D10] w-[60rem] h-[60rem] rounded-full '></div>
                            <div className='bg-white w-[40rem] h-[40rem] rounded-full z-10 absolute'></div>
                        </div>
                    </div>

                </section>
                <section className='pb-20 relative top-[-10rem] z-0'>
                    <div className='layout'>

                        <div className='subtitle'>Persona</div>
                        <div className='flex ml-16 mb-10  md:flex-col md:ml-0'>
                            <div className='flex flex-col items-center text-center'>
                                <img className='w-[10rem]' src={`${img_url}/client.png`} />
                                <div className='font-SB text-[1.3rem] mt-3'>클라이언트</div>
                            </div>
                            <div className=' py-6 px-10 text-center ml-16 flex text-[1.4rem] md:text-[1rem] md:ml-0 md:mt-[4rem]'>
                                <img src={`${img_url}/mark1.png`} className='mt-[-3rem] w-6 mr-2 h-fit' />
                                <div className='-z-10'>
                                    대학생을 대상으로한 원룸을<br />
                                    제공하는 부동산 어플리케이션을 만들고 싶습니다.
                                </div>
                                <img src={`${img_url}/mark2.png`} className='mt-[-3rem] w-6 mr-2 h-fit' />
                            </div>

                        </div>
                        <div className='subtitle'>
                            Service
                        </div>
                        <div className='ml-16 md:ml-0'>
                            <div className='flex md:flex-col md:items-center'>
                                <div className={`circle !border-uhyu`}>수익 모델</div>
                                <div className={`circle  !border-uhyu`}>광고 수수료</div>
                                <div className={`circle  !border-uhyu`}>대학생<br />대상</div>
                                <div className={`circle  !border-uhyu`}>원룸 제공</div>
                                <img src={`${img_url}/service_arr.svg`} className='h-[10rem] w-[10rem] md:rotate-90' />

                                <div className={`bg-${cur} result-circle flex-col `}>
                                    <img src={`${img_url}/logo_w.svg`} className='w-[6rem]' />
                                    <span className='mt-5 text-[1.1rem]'>대학생을 대상으로 하는<br />부동산서비스</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className='mb-32'>
                    <div className='layout mt-10 flex flex-col items-end'>
                        <img src={`${img_url}/logo.svg`} className='w-[8rem]' />
                        <div className='mt-10 text-right text-[1.5rem]'><span className='font-EB text-uhyu'></span>대학생을 위한<br />
                            <span className='font-EB text-uhyu'>부동산 중개 어플</span>
                        </div>

                    </div>
                    <div className='flex relative justify-center'>
                        <img src={`${img_url}/mockup1.png`} className='w-[50rem]' />


                    </div>
                </section>


                <section className='py-20' >
                    <div className='flex justify-center items-center flex-col'>
                        <div className='text-[#DDDDDD]'> Main Screen</div>
                        <div className='relative'>
                            <img src={`${img_url}/main_screen.svg`} className='w-[30rem]' />
                            <Pc>
                                <div className='absolute top-[30rem] -right-[14rem] flex flex-col items-start justify-center' data-aos="fade-up"  >
                                    <img src={`${img_url}/arrow.svg`} className='w-32 ' />
                                    <div className='relative left-[7rem]'>
                                        <div className='dt-title'><span className={`text-${cur}`}>매물정보</span>를 확인</div>
                                        <div className='dt-sub text-expGray'>표로 정리된 <br />매물정보를 한눈에확인해보세요.</div>
                                    </div>
                                </div>
                                <div className='absolute right-[10rem] top-[20rem] -z-[1] flex justify-center items-center'>
                                    <div className='bg-[#FF6C1D10] w-[30rem] h-[30rem] rounded-full '></div>
                                    <div className='bg-white w-[18rem] h-[18rem] rounded-full z-10 absolute'></div>
                                </div>

                                <div className='absolute top-[48rem] -left-[10.5rem] flex flex-col items-center justify-center' data-aos="fade-up"  >
                                    <img src={`${img_url}/arrow.svg`} className='w-32 transform -scale-x-100' />
                                    <div className='relative right-[7rem] text-right'>
                                        <div className='dt-title'><span className={`text-${cur}`}>옵션정보</span>를 한눈에</div>
                                        <div className='dt-sub text-expGray'>간결한 아이콘으로 표현된 <br />옵션정보를 확인 할 수 있어요.</div>
                                    </div>
                                </div>
                                <div className='absolute top-[97rem] -right-[11.5rem] flex flex-col items-center justify-center' data-aos="fade-up"  >
                                    <div className='absolute bg-[#FF6C1D10] w-[10rem] h-[10rem] rounded-full  -z-10 -right-10'></div>
                                    <div className='absolute bg-[#FF6C1D10] w-[8rem] h-[8rem] rounded-full  -z-10 -right-[5rem] -top-[5rem]'></div>
                                    <img src={`${img_url}/arrow.svg`} className='w-32 ' />
                                    <div className='relative left-[7rem]'>
                                        <div className='dt-title'><span className={`text-${cur}`}>지도</span>로 매물 위치 확인</div>
                                        <div className='dt-sub text-expGray'>지도에서 바로 매물위치를<br /> 확인 할 수 있어요.</div>
                                    </div>
                                </div>
                                <div className='absolute top-[132rem] -left-[10rem] flex flex-col items-center justify-center' data-aos="fade-up"  >
                                    <img src={`${img_url}/arrow.svg`} className='w-32 transform -scale-x-100' />
                                    <div className='relative right-[9rem] text-right z-10'>
                                        <div className='dt-title'><span className={`text-${cur}`}>솔직한 리뷰</span>시스템</div>
                                        <div className='dt-sub text-expGray'>이전에 거래한 사람들의 리뷰로<br />판매자 정보를 확인 할 수 있어요.</div>
                                    </div>

                                    {/* <div className='absolute -z-10 bg-[#FF6C1D10] w-[18rem] h-[18rem] rounded-full -right-[5rem] top-10'></div> */}
                                </div>
                            </Pc>
                            <div className='relative -z-10 bg-[#FF6C1D10] w-[20rem] h-[20rem] rounded-full -left-[10rem] -top-[14rem]'></div>

                        </div>

                    </div>

                </section>
                <section>
                    <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
                        <div className='md:flex md:items-center md:flex-col md:justify-center md:text-center '>
                            <div className='font-SB service-sb-txt mb-5 '>중개수수료 없이<br /><span className=' text-uhyu font-EB'>In-APP 결제</span><span className=' text-uhyu font-EB'>로 간편하게</span></div>
                            <Pc> <HashTag cur={cur} active={1} /></Pc>
                        </div>
                        <div className='md:flex md:items-center md:justify-center'>
                            <img src={`${img_url}/main1.png`} className='app-mockup' />

                        </div>
                    </section>
                    <section className=' h-screen flex layout items-center justify-around'>
                        <div className='md:flex md:items-center md:justify-center'>
                            <img src={`${img_url}/main2.png`} className='app-mockup' />
                        </div>
                        <div className='md:flex md:items-center md:flex-col md:justify-center  '>
                            <div className='font-SB service-sb-txt text-right'>앱에서 바로 결제하고,</div>
                            <div className='font-EB service-eb-txt text-uhyu mb-5 text-right'>전자계약 시스템으로 계약까지.</div>
                            <Pc> <HashTag direction="right" cur={cur} active={2} /></Pc>
                        </div>
                    </section>

                    <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >

                        <div className='md:flex md:items-center md:flex-col md:justify-center  '>
                            <div className='font-SB service-sb-txt'>카카오 지도를 이용해,</div>
                            <div className='font-EB service-eb-txt text-uhyu mb-5'>주변 매물을 확인.</div>

                            <Pc> <HashTag cur={cur} active={3} /></Pc>
                        </div>
                        <div className='md:flex md:items-center md:justify-center'>
                            <img src={`${img_url}/main3.png`} className='app-mockup' />

                        </div>
                    </section>
                    <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
                        <div className='md:flex md:items-center md:justify-center'>
                            <img src={`${img_url}/main4.png`} className='app-mockup' />
                        </div>
                        <div className='md:flex md:items-center md:flex-col md:justify-center  '>
                            <div className='font-SB service-sb-txt text-right'>클릭 한번으로,</div>
                            <div className='font-EB service-eb-txt text-uhyu mb-5 text-right'>간편한 SNS 로그인 제공.</div>
                            <Pc><HashTag direction="right" cur={cur} active={4} /></Pc>
                        </div>
                    </section>


                </section>
            </div>

            <section className='flex flex-col bg-uhyu items-center justify-end h-[80rem]' data-aos="fade-up"  >

                <div className='flex flex-col items-center justify-center mb-10'>

                    <img src={`${img_url}/btm_main.svg`} className='w-[52rem]' />
                    <img src={`${img_url}/logo_w.svg`} className='w-36 mb-4' />
                </div>

            </section>
        </div>
    )

}

