import React, { Component } from 'react';

export default function Service(props) {
    const { img_url, cur } = props;
    return (
        <div className='layout'>

            <div className='subtitle'>
                Service
            </div>

            <div className='ml-16 md:ml-0 md:flex md:justify-center md:items-center'>
                <div className='flex md:flex-col md:justify-center md:items-center'>
                    <div className={`circle  !border-diet`}>수익 모델</div>
                    <div className={`circle !border-diet`}>회원 결제</div>
                    <div className={`circle !border-diet`}>Admob<br />광고수익</div>
                    <div className={`circle !border-diet`}>다이어트<br />대회개최</div>
                    
                    <img src={`${img_url}/service_arr.svg`} className='w-[6rem] md:w-[10rem] md:h-[10rem] md:rotate-90' />

                    <div className={`bg-${cur} result-circle flex-col`}>
                        <img src={`${img_url}/logo.svg`} className='w-[6rem] mb-3' />
                        <div className='text-[1.1rem]'>
                            사용자간의 다이어트 <br />대회 어플리케이션
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
