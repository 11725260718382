import React, { Component } from 'react';
import { Mobile,Pc } from 'value';

export default function Service(props) {
    const { first, contents, ment } = props;
    return (
        <>
        <Pc>
        <div className='flex justify-center items-center relative py-14'>
            <div className=' flex-1'>
                <div className='flex items-center justify-center bg-littlemate opacity-60 text-white w-[8rem] h-[8rem] rounded-full absolute top-32 -left-[3rem] text-[1.2rem]'>친절한</div>
                <div className='flex items-center justify-center bg-littlemate opacity-75 text-white w-[10rem] h-[10rem] rounded-full absolute top-3 -left-[2rem] text-[1.3rem]'>신뢰감</div>
                <div className='flex items-center justify-center bg-littlemate opacity-30 text-white w-[12rem] h-[12rem] rounded-full absolute -top-6 left-56 text-[1.2rem]'>귀여운</div>
                <div className='flex items-center justify-center bg-littlemate opacity-30 text-white w-[8rem] h-[8rem] rounded-full absolute top-32 left-[17rem] text-[1.1rem]'>깔끔한</div>
                <div className='flex items-center justify-center bg-littlemate opacity-75 text-white w-[15rem] h-[15rem] rounded-full absolute top-16 left-14 text-[1.5rem]'>신뢰감</div>
            </div>
            <div className='flex-1'>
                <div className='text-[#019AF775] font-EB text-[3rem] mb-2'>Color Value</div>
                <div className='text-[2rem] font-EB'>Trust</div>
                <div className='text-[#019AF775] text-4xl font-EB mt-8 mb-2'>Mission</div>
                <div className='text-[2rem]'>
                    <div className='mt-1'>1. 비대면 상담서비스 제공</div>
                    <div className='mt-1'>2. 반려동물 용품 구독제 서비스 제공</div>
                    <div className='mt-1'>3. 수의사 평점 및 후기 시스템 적용</div>
                </div>
            </div>

        </div>
        </Pc>
        <Mobile>
        <div className='pt-[0rem] pb-[20rem] ml-[1rem] relative sm:ml-0 sm:scale-75'>
            
            <div className=''>
                <div className='text-[#019AF775] font-EB text-[1.6rem] mb-2'>Color Value</div>
                <div className='text-[1.6rem] font-EB'>Trust</div>
                <div className='text-[#019AF775] text-[1.6rem] font-EB mt-8 mb-2'>Mission</div>
                <div className='text-[1.3rem]'>
                    <div className='mt-1'>1. 비대면 상담서비스 제공</div>
                    <div className='mt-1'>2. 반려동물 용품 구독제 서비스 제공</div>
                    <div className='mt-1'>3. 수의사 평점 및 후기 시스템 적용</div>
                </div>
            </div>
            <div className='absolute mt-[5rem] scale-75 ml-[2rem]'>
                <div className='flex items-center justify-center bg-littlemate opacity-60 text-white w-[8rem] h-[8rem] rounded-full absolute top-32 -left-[3rem] text-[1.2rem]'>친절한</div>
                <div className='flex items-center justify-center bg-littlemate opacity-75 text-white w-[10rem] h-[10rem] rounded-full absolute top-3 -left-[2rem] text-[1.3rem]'>신뢰감</div>
                <div className='flex items-center justify-center bg-littlemate opacity-30 text-white w-[12rem] h-[12rem] rounded-full absolute -top-6 left-56 text-[1.2rem]'>귀여운</div>
                <div className='flex items-center justify-center bg-littlemate opacity-30 text-white w-[8rem] h-[8rem] rounded-full absolute top-32 left-[17rem] text-[1.1rem]'>깔끔한</div>
                <div className='flex items-center justify-center bg-littlemate opacity-75 text-white w-[15rem] h-[15rem] rounded-full absolute top-16 left-14 text-[1.5rem]'>신뢰감</div>
            </div>
        </div>
        </Mobile>
        </>
    )
}
