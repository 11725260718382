
export default function HashTag(props) {
    const { direction, cur,active } = props;

    return (
        <div className={direction == "right" ? 'flex flex-col items-end' : 'flex flex-col'}>
            <div className='flex'>
                <div className={`hash-tag !border-metaverse ${active==1 ? `bg-metaverse text-white hover:text-metaverse hover:bg-white` :`text-metaverse hover:text-white hover:bg-metaverse`}  mr-2`}>가상공간</div>
                <div className={`hash-tag !border-metaverse ${active==2 ? `bg-metaverse text-white hover:text-metaverse hover:bg-white` :`text-metaverse hover:text-white hover:bg-metaverse`}  mr-2`}>스팟이동</div>
                <div className={`hash-tag !border-metaverse ${active==3 ? `bg-metaverse text-white hover:text-metaverse hover:bg-white` :`text-metaverse hover:text-white hover:bg-metaverse`}  mr-2`}>WebRTC 통신</div>
            </div>
            <div className='flex mt-2'>
                <div className={`hash-tag !border-metaverse ${active==4 ? `bg-metaverse text-white hover:text-metaverse hover:bg-white` :` text-metaverse hover:text-white hover:bg-metaverse`} mr-2`}>아바타</div>
                <div className={`hash-tag !border-metaverse ${active==5 ? `bg-metaverse text-white hover:text-metaverse hover:bg-white` :` text-metaverse hover:text-white hover:bg-metaverse`} mr-2`}>실시간 데이터</div>

            </div>
        </div>
    )

}