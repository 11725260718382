import React, { Component, useState,useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
    useTransition,
    useSpring as useSpringWeb,
    useChain,
    config,
    animated,
    useSpringRef,
} from '@react-spring/web'
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    MotionValue
  } from "framer-motion";
  import {useParallax} from 'value';

  
const data = [
    {
        name: 'Rare Wind',
        description: '#a8edea → #fed6e3',
        css: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)',
        height:200,
    },
];

export default function Contents1() {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });
    const y = useParallax(scrollYProgress, 300);
    // const springApi = useSpringRef()
    // const [open, set] = useState(false)

    // const { size, ...rest } = useSpringWeb({
    //     ref: springApi,
    //     config: config.stiff,
    //     from: { size: '20%',},
    //     to: {
    //         size: open ? '100%' : '20%',
    //         // background: open ? 'white' : 'hotpink',
    //     },
    // })

    // const transApi = useSpringRef()
    // const transition = useTransition(open ? data : [], {
    //     ref: transApi,
    //     trail: 400 / data.length,
    //     from: { opacity: 0, scale: 0 },
    //     enter: { opacity: 1, scale: 1 },
    //     leave: { opacity: 0, scale: 0 },
    // })

    // // This will orchestrate the two animations above, comment the last arg and it creates a sequence
    // useChain(open ? [springApi, transApi] : [transApi, springApi], [
    //     0,
    //     open ? 0.1 : 0.6,
    // ])


    return (
        
            <section className=' bg-white fullpage flex flex-col justify-center ml-[5rem] md:ml-[3rem] leading-tight'>
            <div className='text-[8rem] md:text-[5rem] sm:text-[4rem] font-GB'>We Design.</div>
            <div className='text-[8rem] md:text-[4.6rem]  sm:text-[3.6rem] font-GB'>We Develop.</div>
            <div className='text-[8rem] md:text-[5rem]  sm:text-[4rem] font-GB'>We <span className='leading-none text-paletti'>Inspir.</span></div>
            </section>
            
        
    )
}