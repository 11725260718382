import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DURATION } from 'value';

export default function PcPage() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;

    useEffect(() => {
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <>
          <section className='flex flex-col layout' >
                <div className='font-B text-[2.6rem] mb-24'>PAGES</div>
                <div className='rleative flex'>
                    <img src={`${img_url}/page1.png`} className='web-page-img' data-aos="fade-up" data-aos-duration={DURATION} />
                    <div >
                        <div className='flex justify-start relative top-[5rem] -left-[1.5rem]' data-aos-duration={DURATION} data-aos="fade-up">
                            <img src={`${img_url}/arrow.svg`} className='w-[12rem]' />
                            <div className='ml-5'>
                                <div className={`dt-title text-${cur}`}>Main Banner</div>
                                <div className={`dt-sub text-subGray`}>하나상조가 제공하고자 하는<br />
                                    메세지를 간결하게 전달합니다.</div>
                            </div>
                        </div >
                        <div className='flex justify-start  relative top-[15rem] -left-[1.5rem]' data-aos-duration={DURATION} data-aos="fade-up">
                            <img src={`${img_url}/arrow.svg`} className='w-[12rem]' />
                            <div className='ml-5'>
                                <div className={`dt-title text-${cur}`}>Main Service</div>
                                <div className={`dt-sub text-subGray`}>하나상조가 제공하고자 하는<br />
                                    서비스를 한눈에 인식 할 수 있습니다.</div>
                            </div>
                        </div >
                        <div className='flex justify-start  relative top-[50rem] -left-[1.5rem]' data-aos-duration={DURATION} data-aos="fade-up">
                            <img src={`${img_url}/arrow.svg`} className='w-[12rem]' />
                            <div className='ml-5'>
                                <div className={`dt-title text-${cur}`}>장례절차 안내</div>
                                <div className={`dt-sub text-subGray`}>익숙하지 않은 장례절차를<br />
                                    간결한 아이콘을 이용해 순서를 제공합니다.</div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section className='mt-[15rem]'>
                <div className='rleative flex justify-center'>
                    <div >
                        <div className='flex justify-start  relative top-[15rem] left-[1rem] z-10' data-aos="fade-up" data-aos-duration={DURATION}  >
                            <div className='mr-5 text-right'>
                                <div className={`dt-title text-${cur}`}>회사소개</div>
                                <div className={`dt-sub text-subGray`}>하나상조서비스가 제공하는
                                    <br />
                                    서비스를 전달합니다.</div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='w-[12rem] rotate-180 ' />
                        </div >
                        <div className='flex justify-start  relative top-[50rem] left-[1rem] z-10' data-aos="fade-up" data-aos-duration={DURATION} >
                            <div className='mr-5 text-right'>
                                <div className={`dt-title text-${cur}`}>회사 연혁</div>
                                <div className={`dt-sub text-subGray`}>지금까지 하나상조서비스가
                                    <br />
                                    걸어온 발자취를 전달합니다.</div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='w-[12rem] rotate-180' />
                        </div>
                    </div>
                    <img src={`${img_url}/page2.png`} className='web-page-img' data-aos="fade-up" data-aos-duration={DURATION} />
                </div>
            </section>
            <section className='mt-[15rem]'>
                <div className='rleative flex justify-center'>
                    <img src={`${img_url}/page3.png`} className='web-page-img' data-aos="fade-up" data-aos-duration={DURATION} />
                    <div >
                        <div className='flex justify-start relative top-[10rem] -left-[1rem]' data-aos-duration={DURATION} data-aos="fade-up">
                            <img src={`${img_url}/arrow.svg`} className='w-[12rem]' />
                            <div className='ml-5'>
                                <div className={`dt-title text-${cur}`}>상품안내</div>
                                <div className={`dt-sub text-subGray`}>생소한 장례서비스 상품을
                                    <br />
                                    구분하기 쉽게 카테고리별로 제공합니다.</div>
                            </div>
                        </div >
                        <img src={`${img_url}/mockup1.png`} className='absolute bottom-[45rem] ml-10 w-[25rem]' data-aos="fade-up" data-aos-duration={DURATION} />
                    </div>
                </div>

            </section>

        </>
    )

}


