import React, { Component } from 'react';
import { Mobile ,Pc} from 'value';

export default function Model(props) {
    const { first, contents, ment ,cur,img_url} = props;
    return (
        <section className='layout py-28'>
            <div className='subtitle'>BUSINESS MODEL</div>
            <div className='flex items-center justify-center mt-[10rem]'>
                <Pc>
                <div className='flex relative'>
                    <div className={`circle !border-${cur}`}>
                        <img src={`${img_url}/bus1.png`} className='w-12 h-12' />
                    </div>
                    <div className='text-[#25C3FD] absolute right-[1.5rem] top-[4.5rem] text-[1.2rem]'>수수료</div>
                    <img src={`${img_url}/yellow_arr.svg`} className='w-32' />

                </div>


                <div className='flex relative ml-[-5rem]'>
                    <img src={`${img_url}/main_arr.svg`} className='w-32 mt-10' />
                    <div className={`text-${cur} text-right absolute left-6 bottom-[4rem] text-[1.2rem]`}>플랫폼 제공</div>
                    <div className={`center-circle !border-${cur}`}>
                        <img src={`${img_url}/logo2.png`} className='w-[8rem]' />
                    </div>

                    <img src={`${img_url}/yellow_arr.svg`} className='w-32 mt-10 transform -scale-y-100' />
                    <div className='text-[#25C3FD] absolute right-[1.5rem] top-[8.5rem] text-[1.2rem]'>반찬 배달</div>
                </div>

                <div className='flex relative ml-[-4rem]'>
                    <div className={`text-ranchan text-right absolute left-3 top-[4.5rem] text-[1.2rem]`}>반찬 주문</div>
                    <img src={`${img_url}/main_arr.svg`} className='w-32 transform -scale-y-100 ml-[-0.5rem]' />
                    <div className={`circle z-[2] !border-${cur}`}>
                        <img src={`${img_url}/bus2.png`} className='w-12 h-12' />
                    </div>

                </div>
                </Pc>
                <Mobile>
                <img src={`${img_url}/mobile_bus.png`} className='w-[18rem] mt-[8rem]' />
                </Mobile>
            </div>
        </section>
    )
}

