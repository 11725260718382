import React, { Component, useEffect, useState, useRef } from 'react';
import { FullPageScroll } from 'component/FullPageScroll';
import {
    motion, spring, useMotionValue, useSpring, useScroll,
    useTransform,
    MotionValue
} from "framer-motion"
import Contents1 from 'view/section/Contents1';
import Contents4 from 'view/section/Contents4';
import Contents5 from 'view/section/Contents5';
import Contents7 from 'view/section/Contents7';
import useMeasure from 'react-use-measure'
import { useTrail, animated } from '@react-spring/web'

import 'css/home.css';
import 'css/layout.css';
import Contents2 from './section/Contents2';

const fast = { tension: 1200, friction: 40 }
const slow = { mass: 10, tension: 200, friction: 50 }
const trans = (x, y) =>
    `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`


export default function Home() {

    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });


    const cursorX = useMotionValue(0)
    const cursorY = useMotionValue(0)
    const s = useMotionValue(1)
    const springConfig = { damping: 35, stiffness: 700 };
    // const scaleSpring = useSpring(s, springConfig);
    // const cursorXSpring = useSpring(cursorX, springConfig);
    // const cursorYSpring = useSpring(cursorY, springConfig);

    const [trail, api] = useTrail(3, i => ({
        xy: [0, 0],
        config: i === 0 ? fast : slow,
    }))
    const [ref, { left, top }] = useMeasure()

    const handleMouseMove = e => {
        api.start({ xy: [e.clientX - left, e.clientY - top] })
    }

    useEffect(() => {
        const moveCursor = (e) => {
            cursorX.set(e.clientX);
            cursorY.set(e.clientY);
        };

        window.addEventListener("mousemove", moveCursor);

        return () => {
            window.removeEventListener("mousemove", moveCursor);
        };

    }, []);

    return (
        <div className='home-container'>

            <svg style={{ position: 'relative', width: 0, height: 0, zIndex: 999 }}>
                <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="30" />
                    <feColorMatrix
                        in="blur"
                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 30 -7"
                    />
                </filter>
            </svg>

            {/* <div className='scroll-box vertical h-screen'> */}
            <FullPageScroll>
            <div ref={ref} className='hooksMain z-0' onMouseMove={handleMouseMove}>
                {trail.map((props, index) => (
                    <animated.div key={index} style={{ transform: props.xy.to(trans) }} />
                ))}
            </div>
            <Contents2/>
                <Contents5 />
                <Contents1 />
                <Contents4 />
                <Contents7/>
            </FullPageScroll>
            {/* </div> */}
            {/* <motion.div className="progress" style={{ scaleX }} /> */}
            {/* <Contents4 /> */}


            <div className='font-GM footer text-center w-screen mb-3'>ALL RIGHTS RESERVED © 2023 PALETTI CO.</div>
        </div>
    )
}

