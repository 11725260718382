import React, { Component } from 'react';

/**
 * @param {Number} number 숫자
 * @param {String} color 색상
 * 
 */
export default function Circle(props) {
    const { color,number} = props;
    
    return (

        <div className={`mr-2 min-w-[2rem]  min-h-[2rem] w-[2rem] h-[2rem] leading-none text-[1rem] font-EB flex items-center justify-center rounded-full text-center bg-${color} text-white`}>
            {number}
        </div>
    )
}
