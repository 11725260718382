export default function HashTag(props) {
    const { direction ,cur,active} = props;

    return (
        <div className={direction == "right" ? 'flex flex-col items-end' : 'flex flex-col'}>
            <div className='flex'>
                <div className={`hash-tag !border-split ${active==1 ? `bg-split text-white hover:text-split hover:bg-white` :`text-split hover:text-white hover:bg-split`}  mr-2`}>정기결제</div>
                <div className={`hash-tag !border-split ${active==2 ? `bg-split text-white hover:text-split hover:bg-white` :`text-split hover:text-white hover:bg-split`}  mr-2`}>채팅 서비스</div>
                <div className={`hash-tag !border-split ${active==3 ? `bg-split text-white hover:text-split hover:bg-white` :`text-split hover:text-white hover:bg-split`}  mr-2`}>자동 매칭</div>

            </div>
            <div className='flex mt-2'>
                <div className={`hash-tag !border-split ${active==4 ? `bg-split text-white hover:text-split hover:bg-white` :`text-split hover:text-white hover:bg-split`}  mr-2`}>PUSH 알림</div>
                <div className={`hash-tag !border-split ${active==5 ? `bg-split text-white hover:text-split hover:bg-white` :`text-split hover:text-white hover:bg-split`}  mr-2`}>SNS 로그인</div>
            </div>
        </div>
    )

}