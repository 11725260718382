import React from 'react';
import HashTag from './HashTag';
import {Pc} from 'value';

export const Func = ({ img_url,cur }) => (
  <section>
    <section className='h-screen flex layout flex-col' data-aos="fade-up" id="1">
      <Pc><HashTag active={1} cur={cur}/></Pc>
      <div className='flex items-center justify-between md:flex-col'>
        <div>
          <div className='font-SB service-sb-txt'>직접 가지않아도</div>
          <div className='font-EB service-eb-txt text-saferoom mb-5'>
            <span className='!text-black !font-SB'>체험할 수 있는 생생한 </span>VR뷰어.</div>
          <div className='text-subGray text-[1.2rem]'>
            직접 가보지 않아도 직접 찍은 VR뷰어를 통해<br />
            360도 원하는 각도로 자세히 살펴볼 수 있어요.
          </div>

        </div>
        <img src={`${img_url}/main1.png`} className='app-mockup  mr-[6rem] md:mr-0' />
      </div>
    </section>
    <section className='h-screen flex layout flex-col' data-aos="fade-up"  id="2">
      <Pc><HashTag active={2} cur={cur}/></Pc>
      <div className='flex items-center justify-between md:flex-col'>
        <div>
          <div className='font-SB service-sb-txt'>거리별 매물 추천으로</div>
          <div className='font-EB service-eb-txt text-saferoom mb-5'>필요한 매물을 찾아보세요!</div>
          <div className='text-subGray text-[1.2rem]'>
            거리별 매물추천 및 원하는 조건에 맞는 매물을<br />
            손쉽게 찾아 볼 수 있어요.
          </div>

        </div>
        <img src={`${img_url}/main3.png`} className='app-mockup mr-[6rem] md:mr-0' />
      </div>
    </section>
    <section className='h-screen flex layout flex-col' data-aos="fade-up"  id="3">
      <Pc><HashTag active={3} cur={cur}/></Pc>
      <div className='flex items-center justify-between md:flex-col'>
        <div>
          <div className='font-SB service-sb-txt'>부동산 거래후,</div>
          <div className='font-EB service-eb-txt text-saferoom mb-5'>
            리뷰를 남겨보세요!</div>
          <div className='text-subGray text-[1.2rem]'>
            먼저 거래해본 다른 사람들의 후기를 살펴보고<br />
            필요하거나 궁금했던 정보를 알아보세요.
          </div>

        </div>
        <img src={`${img_url}/main2.png`} className='app-mockup mr-[6rem] md:mr-0' />
      </div>
    </section>
    <section className='h-screen flex layout flex-col' data-aos="fade-up" id="4">
      <Pc><HashTag active={4} cur={cur}/></Pc>
      <div className='flex items-center justify-between md:flex-col'>
        <div>
          <div className='font-SB service-sb-txt'>클릭 한번으로,</div>
          <div className='font-EB service-eb-txt text-saferoom mb-5'>
            간편한 SNS 로그인 제공.</div>
          <div className='text-subGray text-[1.2rem]'>
            클릭 한번으로 편하게<br />
            카카오톡 로그인을 사용 할 수 있어요.
          </div>

        </div>
        <img src={`${img_url}/main4.png`} className='app-mockup mr-[6rem] md:mr-0' />
      </div>
    </section>
  </section>

);


