import React, { useRef, Component, useLayoutEffect } from 'react';
import { motion, useMotionValue, useSpring } from "framer-motion"
import { gsap } from 'gsap';
export default function Contents2() {
  
  return (
    <div className=' fullpage bg-white w-screen justify-between ch-bg z-20'>
      <div className='flex bg-wthie justify-end mr-[3rem] z-30'>
      <div className=' text-right font-GB text-[10rem] md:text-[6rem] md:mt-[3rem] break-all'>Develope<br/> With<span className='text-paletti'>.</span></div>
      </div>
      <div className=' flex flex-col bg-wthie justify-end ml-[5rem] text-[3rem] md:mt-[8rem]'>
        <div className='font-light'>안녕하세요.</div>
        <div className='font-SB'>함께하는</div>
        <div className='font-EB'>팔레티 입니다.</div>

      <div className='text-[1.4rem] sm:text-[1.2rem] font-SB mt-2'>
        대한민국<br />
        부산시 강서구<br />
        명지 국제 2로 80 3-56호
    </div>
      </div>

    {/* <div className='diagonal'>
      <span>2015</span>
      
      <span>2023</span>
    </div> */}


      
    </div>
  )
}