import React, { Component } from 'react';
import { Pc } from 'value';

export default function MdMockup(props) {
    const { img_url, first,second,cur } = props;
    
    return (
        <div className='layout mt-10 text-[1vw] md:mb-10'>
            <Pc>
            <img src={`${img_url}/logo_2.svg`} className='w-[4vw]' />
            <div className='font-EB mt-2'>{first}<br />
                <span className={`font-EB text-${cur}`}>{second}</span>
            </div>
            </Pc>
            <img src={`${img_url}/md_mockup.png`} className='mb-3' />
        </div>
    )
}
