import React, { useState, useRef, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    MotionValue
} from "framer-motion";
//   import {useParallax} from 'value';
import { Pc, Mobile } from 'value';

export default function Contents5(props) {
    const ref = useRef(null);
    // const { scrollYProgress } = useScroll({ target: ref });
    // const y = useParallax(scrollYProgress, 300);

    return (

        <section className=' flex flex-col bg-white justify-center leading-tight fullpage'>
            <Pc>
                <div className='flex flex-col justify-around h-screen'>
                    <div className='flex flex-col font-GB leading-[20rem] tracking-tight text-[28.5rem] text-center  ' ref={ref}>
                        <div className='flex text-center justify-around items-center'>
                            <div className='p-2'>if</div>
                            <div className='font-GB flex items-center text-center justify-between'>
                                <div className='flex items-center'>
                                    <div className=''>(</div>
                                    <div
                                        className='w-[55rem] flex items-center'>
                                        <TypeAnimation
                                            sequence={[
                                                // Same substring at the start will only be typed out once, initially
                                                'WANT',
                                                1000, // wait 1s before replacing "Mice" with "Hamsters"
                                                'WITH',
                                                1000,
                                                'HOPE',
                                                1000,
                                                'WISH',
                                                1000
                                            ]}
                                            className='font-GB text-paletti content-keyword'
                                            wrapper="span"
                                            speed={50}
                                            style={{ display: 'inline-block' }}
                                            repeat={Infinity}
                                        />
                                        {/* <img src={`${process.env.PUBLIC_URL}/asset/img/bulb_w.svg`} className='w-[15rem] p-10' /> */}
                                    </div>
                                    {/* <img src={`${process.env.PUBLIC_URL}/asset/img/fullmoon.png`} className='w-[20rem]  -ml-20' /> */}
                                    <div>)</div>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/asset/img/arr3.svg`} className='ml-7 w-[25rem] md:w-[12rem] lg:w-[10rem]' />
                            </div>

                        </div>
                        <div className='text-[25.5rem] text-center md:text-[13rem] lg:text-[9rem]'>Developer</div>
                    </div>
                    <div className='ml-[5rem] font-GM'>
                        <div className='text-[1.7rem] md:text-[2.4rem]'>안녕하세요.<br />함께하는 팔레티 입니다.</div>
                    </div>
                </div>
            </Pc>
            <Mobile>

                <div className='flex flex-col font-GB leading-[20rem] tracking-tight text-[9.5rem] text-center ' ref={ref}>
                    <div className='flex text-center justify-around items-center'>
                        <div className='p-2'>if</div>
                        <div className='font-GB flex items-center text-center justify-between'>
                            <div className='flex items-center'>
                                <div className=''>(</div>
                                <div
                                    className='w-[17rem] flex items-center'>
                                    <TypeAnimation
                                        sequence={[
                                            // Same substring at the start will only be typed out once, initially
                                            'WANT',
                                            1000, // wait 1s before replacing "Mice" with "Hamsters"
                                            'WITH',
                                            1000,
                                            'HOPE',
                                            1000,
                                            'WISH',
                                            1000
                                        ]}
                                        className='font-GB text-[5.3rem] text-paletti'
                                        wrapper="span"
                                        speed={50}
                                        style={{ display: 'inline-block' }}
                                        repeat={Infinity}
                                    />
                                </div>
                                {/* <img src={`${process.env.PUBLIC_URL}/asset/img/fullmoon.png`} className='w-[20rem]  -ml-20' /> */}
                                <div>)</div>
                            </div>
                            <img src={`${process.env.PUBLIC_URL}/asset/img/arr3.svg`} className='ml-2 w-[6rem]' />
                        </div>

                    </div>
                    <div className='flex justify-center items-center -mt-[5rem]'>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/logo.png`} className='w-[7rem]' />

                    </div>
                    <div className='-mt-[7rem] text-[5.5rem] font-GB text-center'>Developer</div>
                </div>
                <div className='ml-[2rem] mt-5 font-GM'>
                    <div className='text-[1.7rem]'>안녕하세요.<br />함께하는 팔레티 입니다.</div>
                </div>
            </Mobile>
        </section>
    )
}
