                            
                            
export default function HashTag(props){
    const {direction,active} = props;
    
        return(
            <div className={direction == "right" ? 'flex flex-col items-end' : 'flex flex-col'}>
                <div className='flex'>
                <div className={`hash-tag ${active==1 ? 'bg-littlemate !border-littlemate text-white hover:text-littlemate hover:bg-white':'!border-littlemate text-littlemate hover:text-white hover:bg-littlemate'} mr-2`}>정기 / 마일리지 결제</div>
                <div className={`hash-tag ${active==2 ? 'bg-littlemate !border-littlemate text-white hover:text-littlemate hover:bg-white':'!border-littlemate text-littlemate hover:text-white hover:bg-littlemate'} mr-2`}>WebRTC 상담서비스</div>
                <div className={`hash-tag ${active==3 ? 'bg-littlemate !border-littlemate text-white hover:text-littlemate hover:bg-white':'!border-littlemate text-littlemate hover:text-white hover:bg-littlemate'}`}>소셜 로그인</div>
                </div>
                <div className='flex mt-2'>
                <div className={`hash-tag ${active==4 ? 'bg-littlemate !border-littlemate text-white hover:text-littlemate hover:bg-white':'!border-littlemate text-littlemate hover:text-white hover:bg-littlemate'} mr-2`}>관리자 페이지</div>
                <div className={`hash-tag ${active==5 ? 'bg-littlemate !border-littlemate text-white hover:text-littlemate hover:bg-white':'!border-littlemate text-littlemate hover:text-white hover:bg-littlemate'}`}>PUSH 알림</div>
                {/* <div className='hash-tag !border-[#0F9AF0] text-[#0F9AF0] hover:text-white hover:bg-[#0F9AF0]'>정기 결제</div> */}
                </div>
            </div>
        )
    
}