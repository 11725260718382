import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DURATION } from 'value';

export default function MobilePage() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    const [product, setProduct] = useState(false);
    const [main, setMain] = useState(false);
    const [service, setService] = useState(false);
    const [stage, setStage] = useState(false);

    useEffect(() => {
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <>
            <section className='flex flex-col layout' >
                <div className='font-B text-[2.6rem] mb-[8rem]'>PAGES</div>
                <div className=' flex flex-col'>
                    <img src={`${img_url}/page1.png`} className='web-page-img' data-aos="fade-up" />
                    <div >
                        <div className='flex justify-start '  data-aos="fade-up">
                            <div className='ml-5'>
                                <div className={`dt-title text-${cur}`}>Portfolio</div>
                                <div className={`dt-sub text-subGray`}>
                                    복잡한 레이아웃 대신, 간결하고 명확한<br />
                                    이미지 배치를 통하여, 신뢰감을 형성 할 수 있는<br />
                                    디자인을 제공합니다.

                                </div>
                            </div >

                        </div>

                    </div>
                </div>
            </section>

            <section className='mt-[15rem] layout'>
                <div className=' flex flex-col justify-end'>
                        <div className='flex justify-end  mb-10' data-aos="fade-up" >
                            <div className='mr-5 text-right'>
                                <div className={`dt-title text-${cur}`}>Philosopy</div>
                                <div className={`dt-sub text-subGray`}>
                                    글보다 아이콘을 사용하여,<br />
                                    보다 전달하고자 하는 메세지를 제공합니다.
                                </div>
                            </div>
                        </div >
                        <div className='flex justify-end  mb-10' data-aos="fade-up">
                            <div className='mr-5 text-right'>
                                <div className={`dt-title text-${cur}`}>Our History</div>
                                <div className={`dt-sub text-subGray`}>
                                    시간의 흐름에 따른 레이아웃 배치로<br />
                                    한눈에 허바허바 디자인 여정의 흐름을<br />
                                    파악 할 수 있습니다.
                                </div>
                            </div>
                        </div>
                    <div className='flex justify-end'>
                    <img src={`${img_url}/page2.png`} className='web-page-img' data-aos="fade-up" />
                    </div>
                </div>
            </section>

            <section 
            className='mt-[15rem] flex justify-center items-center'
            data-aos="fade-up" data-aos-duration={DURATION}>
                <img src={`${img_url}/mockup3.png`} className='w-[20rem] z-10'/>
                <img src={`${img_url}/page4.png`} className='w-[30rem] -ml-[22rem]'/>
            </section>

            <section
             className='mt-[15rem]'
            >
            <div className=' flex justify-center flex-col layout'>
                
                    <img src={`${img_url}/page3.png`} className='web-page-img' data-aos="fade-up" />
                    <div>
                        <div className='mt-10 flex justify-start'  data-aos="fade-up">
                            <div className='ml-5'>
                                <div className={`dt-title text-${cur}`}>PROCESS</div>
                                <div className={`dt-sub text-subGray`}>
                                    복잡한 레이아웃 약국 인테리어에 필요한 준비조건을<br/>
                                    미리 알아볼수 있습니다.
                                </div>
                            </div >

                        </div>
                       
                    </div>
                </div>
            </section>

            <section className='flex-col flex justify-end mt-[15rem] items-end mr-[5rem]'>
            <div className='flex' data-aos="fade-up" >
                        <div className=' text-right flex flex-col justify-end'>
                                <div className={`dt-title text-${cur}`}>ADDRESS</div>
                                <div className={`dt-sub text-subGray`}>
                                지도를 이용하여 허바허바 디자인으로<br/>
                                오시는 길을 알려드립니다.
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end items-end mt-10'>
                        <img src={`${img_url}/mockup4.png`} className='w-[25rem]' data-aos="fade-up" />
                        </div>
            </section>
       

        </>
    )

}


