import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Circle from 'component/Circle';
import { DURATION,Mobile,Pc } from 'value';
import { Func } from './Func';
import Icon from './Icon';
import MdMockup from 'component/MdMockup';
import AOS from "aos";
import "aos/dist/aos.css";
import PcBus from './PcBus';
import MobileBus from './MobileBus';

export default function Saferoom() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <div className=' overflow-x-hidden'>
            <section className="top-bg bg-gradient-to-r from-[#FF312E] to-[#FF1E6F] h-screen" data-aos="fade-up"  >
                <img src={`${img_url}/app.svg`} className='w-36 mb-4' />
                <div className='text-white text-center text-[1.4rem] sm:text-[1rem]'>허위매물 없는 안전한 부동산 서비스 어플리케이션</div>
            </section>
            <div className='max-container'>
            <section className="flex layout">
                <div className="flex-1">
                    <div>
                        <img src={`${img_url}/logo.svg`} className='w-72 mb-4 mt-36' />
                        <div className='text-[1.4rem]'>허위매물 없는 안전한 부동산 서비스 어플리케이션</div>
                    </div>
                    <div className='mt-10 text-[1.5rem]'>
                        <div>프로젝트 기간 : 6개월</div>
                        <div>지원가능 OS : Android / IOS</div>
                        <div>제공언어 : 한국어</div>
                    </div>

                </div>
                <div className='relative back-mockup'  data-aos="fade-left" >
                    <div className='w-[55rem] h-[55rem] rounded-full bg-saferoom_20 absolute top-56 -z-20' />
                    <img src={`${img_url}/mockup.svg`} className='z-[2] w-[50vw]' />
                </div>

            </section>
            <section className='pb-20 relative top-[-10rem] z-0' >
                <div className='layout'>

                    <div className='subtitle'>Persona</div>
                    <div className='flex mb-10'>

                        <div className='bg-[#F8F8F8] p-6 text-left rounded-lg flex flex-col'>
                            <div>
                                <img className='w-24' src={`${img_url}/client.png`} />
                                <div className='font-SB text-[#6C6C6C] text-[1.5rem] mt-3'>CLIENT</div>
                            </div>
                            <div className='mt-10 text-[1.3rem]'>
                                <span className='text-saferoom font-EB text-[1.6rem]'>WANT</span>
                                <br />
                                일일이 가보지 않아도, VR로 생생하게<br />
                                투어할 수 있고, 허위매물이 없는.<br />
                                부동산 어플리케이션을 만들고 싶습니다.
                            </div>

                            <div className='mt-10 text-[1.3rem]'>
                                <span className='text-saferoom font-EB text-[1.6rem]'>GOAL</span><br />
                                <div className='flex items-center mb-1'>
                                    <Circle number={1} color={cur} />
                                    메타버스기반의 부동산 중개 플랫폼
                                </div>
                                <div className='flex items-center mb-1'>
                                    <Circle number={2} color={cur} />
                                    철저한 허위매물 관리
                                </div>
                                <div className='flex items-center'>
                                    <Circle number={3} color={cur} />
                                    원룸 위주의 중개
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='subtitle'>
                        Service
                    </div>


                    <div className='ml-16 md:ml-0' >
                        <div className='flex md:flex-col md:items-center '>
                            <div className={`circle !border-${cur}`}>수익 모델</div>
                            <div className={`circle !md:ml-0 md:-mt-3 !border-${cur}`}>소독 서비스</div>
                            <div className={`circle !md:ml-0 md:-mt-3 !border-${cur}`}>생생한<br /> VR 투어</div>
                            <div className={`circle !md:ml-0 md:-mt-3 !border-${cur}`}>중개 수수료</div>
                            
                            <img src={`${img_url}/service_arr.svg`} className='w-20 md:rotate-90 md:h-[10rem] md:w-[10rem]' />

                            <div className={`bg-${cur} result-circle flex-col`}>
                                <img src={`${img_url}/logo_w.svg`} className='w-16 mb-3' />
                                <div >
                                    <span className='font-EB'>허위매물없는 안전한</span><br /><span className='font-EB'>부동산</span> 서비스
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-aos="fade-up"  >
                <MdMockup
                    cur={cur}
                    img_url={img_url}
                    first={'허위매물 없고, 안전한'}
                    second={'부동산 어플리케이션'}
                />

            </section>
            <section className='layout' data-aos="fade-up"  >
                <div className='subtitle'>BRANDING</div>
                <div className='flex justify-center items-start relative py-14 md:flex-col'>
                    <div className=' flex-1'>
                        <div className='font-EB text-[1.85rem]'>Color</div>
                        <div className='flex mt-10'>
                            <div className='bg-saferoom w-[6rem] h-[6rem] rounded-md' />
                            <div className='ml-3'>
                                <div className='font-EB text-saferoom text-[1.4rem]'>RED PINK</div>
                                <div className='text-[1.4rem]'>#FF2750</div>
                            </div>
                        </div>
                        <div className='flex mt-3'>
                            <div className='bg-saferoom_50 w-[6rem] h-[6rem] rounded-md mr-3' />
                            <div className='bg-[#F7F8FA] w-[6rem] h-[6rem] rounded-md mr-3' />
                            <div className='bg-[#CCCCCC] w-[6rem] h-[6rem] rounded-md' />
                        </div>
                    </div>
                    <div className='flex-1 md:mt-[6rem]'>
                        <div className='font-EB text-[1.85rem]'>Font System</div>
                        <div className='mt-10 text-[1.8rem]'>AppleSDGothic</div>
                        <div className='flex  mt-5 text-[1.7rem]'>
                            <Pc><div className='mr-2 text-[1.7rem]'>영문</div></Pc>
                            <div>
                                <div>WELCOME TO THE SAFEROOM APPLICATION</div>
                                <div className='font-SB'>WELCOME TO THE SAFEROOM APPLICATION</div>
                                <div className='font-EB'>WELCOME TO THE SAFEROOM APPLICATION</div>

                            </div>
                        </div>

                        <div className='flex  mt-5 text-[1.7rem]'>
                            <Pc><div className='mr-2 text-[1.7rem]'>한글</div></Pc>
                            <div>
                                <div>안전방에 오신것을 환영합니다.</div>
                                <div className='font-SB'>안전방에 오신것을 환영합니다.</div>
                                <div className='font-EB'>안전방에 오신것을 환영합니다.</div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='layout py-28' data-aos="fade-up"  >
                <div className='subtitle'>BUSINESS MODEL</div>
                <Pc><PcBus img_url={img_url}/></Pc>
                <Mobile><MobileBus img_url={img_url}/></Mobile>
            </section>
            <section className='flex layout py-28 mb-32'>
                <div className='flex-1'>
                    <div className='subtitle'>ICON</div>
                    <div className='flex items-center justify-center'>
                        <Icon img_url={img_url} />
                    </div>
                </div>

            </section>
            <section >

                <Func img_url={img_url} cur={cur} />
            </section>
</div>
            <section className='flex flex-col bg-saferoom items-center justify-end h-[80rem]'>

                <div className='flex flex-col items-center justify-center mb-10'>

                    <img src={`${img_url}/btm_main.svg`} className='w-[40rem] mb-16 mt-10 md:w-[30rem]' />
                    <img src={`${img_url}/app.svg`} className='w-32 mb-4' />
                </div>

            </section>
        </div>
    )

}

