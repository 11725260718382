
import { Link } from "react-scroll"

export default function HashTag(props) {
  const { direction ,cur,active} = props;

  return (
    <div className={direction == "right" ? 'flex flex-col items-end' : 'flex flex-col'}>
      <div className='flex'>
        {/* <Link to="1" spy={true} smooth={true}> */}
          <div className={`hash-tag !border-saferoom ${active==1 ? `bg-saferoom text-white hover:text-saferoom hover:bg-white` :`text-saferoom hover:text-white hover:bg-saferoom`}  mr-2`}>정기 / 마일리지 결제</div>
        {/* </Link> */}
        {/* <Link to="2" spy={true} smooth={true}> */}
          <div className={`hash-tag !border-saferoom ${active==2 ? `bg-saferoom text-white hover:text-saferoom hover:bg-white` :`text-saferoom hover:text-white hover:bg-saferoom`}  mr-2`} >WebRTC 상담서비스</div>
        {/* </Link> */}
        {/* <Link to="3" spy={true} smooth={true}> */}
          <div className={`hash-tag !border-saferoom ${active==3 ? `bg-saferoom text-white hover:text-saferoom hover:bg-white` :`text-saferoom hover:text-white hover:bg-saferoom`}  mr-2`} >소셜 로그인</div>
        {/* </Link> */}
        {/* <Link to="4" spy={true} smooth={true}> */}
          <div className={`hash-tag !border-saferoom ${active==4 ? `bg-saferoom text-white hover:text-saferoom hover:bg-white` :`text-saferoom hover:text-white hover:bg-saferoom`}  mr-2`} >관리자 페이지</div>
        {/* </Link> */}
        {/* <Link to="5" spy={false} smooth={true}> */}
          <div className={`hash-tag !border-saferoom ${active==5 ? `bg-saferoom text-white hover:text-saferoom hover:bg-white` :`text-saferoom hover:text-white hover:bg-saferoom`}  mr-2`} >PUSH 알림</div>
        {/* </Link> */}
      </div>
    </div>
  )

}