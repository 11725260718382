import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DURATION } from 'value';

export default function PcPage() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;

    useEffect(() => {
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <>
         <section className='flex flex-col layout' >
                <div className='font-B text-[2.6rem] mb-[8rem]'>PAGES</div>
                <div className='rleative flex'>
                    <img src={`${img_url}/page1.png`} className='w-[40rem]' data-aos="fade-up" />
                    <div >
                        <div className='flex justify-start relative top-[5rem] -left-[1rem]'  data-aos="fade-up">
                            <img src={`${img_url}/arrow.svg`} className='w-[12rem]' />
                            <div className='ml-5'>
                                <div className={`dt-title text-${cur}`}>Portfolio</div>
                                <div className={`dt-sub text-subGray`}>
                                    복잡한 레이아웃 대신, 간결하고 명확한<br />
                                    이미지 배치를 통하여, 신뢰감을 형성 할 수 있는<br />
                                    디자인을 제공합니다.

                                </div>
                            </div >

                        </div>

                    </div>
                </div>
            </section>

            <section className='mt-[15rem]'>
                <div className='rleative flex justify-center'>
                    <div >
                        <div className='flex justify-start  relative top-[35rem] left-[1rem] z-10' data-aos="fade-up">
                            <div className='mr-5 text-right'>
                                <div className={`dt-title text-${cur}`}>Philosopy</div>
                                <div className={`dt-sub text-subGray`}>
                                    글보다 아이콘을 사용하여,<br />
                                    보다 전달하고자 하는 메세지를 제공합니다.
                                </div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='w-[12rem] rotate-180' />
                        </div >
                        <div className='flex justify-start  relative top-[60rem] left-[3rem] z-10' data-aos="fade-up">
                            <div className='mr-5 text-right'>
                                <div className={`dt-title text-${cur}`}>Our History</div>
                                <div className={`dt-sub text-subGray`}>
                                    시간의 흐름에 따른 레이아웃 배치로<br />
                                    한눈에 허바허바 디자인 여정의 흐름을<br />
                                    파악 할 수 있습니다.
                                </div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='w-[12rem] rotate-180' />
                        </div>
                    </div>
                    <img src={`${img_url}/page2.png`} className='w-[40rem]' data-aos="fade-up" />
                </div>
            </section>

            <section 
            className='mt-[15rem] flex justify-center items-center'
            data-aos="fade-up" data-aos-duration={DURATION}>
                <img src={`${img_url}/mockup3.png`} className='w-[30rem] z-10'/>
                <img src={`${img_url}/page4.png`} className='w-[50rem] -ml-[5rem]'/>
            </section>

            <section
             className='mt-[15rem]'
            >
            <div className='rleative flex justify-center'>
                    <img src={`${img_url}/page3.png`} className='w-[40rem]' data-aos="fade-up" />
                    <div>
                        <div className='flex justify-start relative top-[5rem] -left-[1rem]' data-aos="fade-up">
                            <img src={`${img_url}/arrow.svg`} className='w-32' />
                            <div className='ml-5'>
                                <div className={`dt-title text-${cur}`}>PROCESS</div>
                                <div className={`dt-sub text-subGray`}>
                                    복잡한 레이아웃 약국 인테리어에 필요한 준비조건을<br/>
                                    미리 알아볼수 있습니다.
                                </div>
                            </div >

                        </div>
                       
                    </div>
                </div>
            </section>
            <section className='layout relative -top-[30rem] flex justify-end'>
            <div className='flex relative -right-[2.3rem] z-[2]' data-aos="fade-up" >
                        <div className='mr-5 text-right relative top-[49rem]  flex flex-col justify-start'>
                                <div className={`dt-title text-${cur}`}>ADDRESS</div>
                                <div className={`dt-sub text-subGray`}>
                                지도를 이용하여 허바허바 디자인으로<br/>
                                오시는 길을 알려드립니다.
                                </div>
                            </div>
                                <img src={`${img_url}/arrow.svg`} className='w-[12rem] rotate-180' />
                        </div>
                        <img src={`${img_url}/mockup4.png`} className='w-[25rem]' data-aos="fade-up" />
            </section>
       

        </>
    )

}


