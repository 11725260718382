import React, { Component, useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
    motion, spring, useMotionValue, useSpring, useScroll,
    useTransform,
    MotionValue
} from "framer-motion"
import useMeasure from 'react-use-measure'
import { useTrail, animated } from '@react-spring/web'
import {Pc} from 'value';
import 'css/home.css';
import 'css/layout.css';

const fast = { tension: 1200, friction: 40 }
const slow = { mass: 10, tension: 200, friction: 50 }
const trans = (x, y) =>
    `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`


export default function NotFound() {

    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });


    const cursorX = useMotionValue(0)
    const cursorY = useMotionValue(0)
    const s = useMotionValue(1)
    const springConfig = { damping: 35, stiffness: 700 };
    // const scaleSpring = useSpring(s, springConfig);
    // const cursorXSpring = useSpring(cursorX, springConfig);
    // const cursorYSpring = useSpring(cursorY, springConfig);

    const [trail, api] = useTrail(3, i => ({
        xy: [0, 0],
        config: i === 0 ? fast : slow,
    }))
    const [ref, { left, top }] = useMeasure()

    const handleMouseMove = e => {
        api.start({ xy: [e.clientX - left, e.clientY - top] })
    }

    useEffect(() => {
        const moveCursor = (e) => {
            cursorX.set(e.clientX);
            cursorY.set(e.clientY);
        };

        window.addEventListener("mousemove", moveCursor);

        return () => {
            window.removeEventListener("mousemove", moveCursor);
        };

    }, []);

    return (
        <div className='home-container'>
            <svg style={{ position: 'relative', width: 0, height: 0, zIndex: 999 }}>
                <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="30" />
                    <feColorMatrix
                        in="blur"
                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 30 -7"
                    />
                </filter>
            </svg>

            <div className='h-screen'>
            <div ref={ref} className='hooksMain z-0' onMouseMove={handleMouseMove}>
                {trail.map((props, index) => (
                    <animated.div key={index} style={{ transform: props.xy.to(trans) }} />
                ))}
            </div>
              <div className='h-screen flex flex-col justify-center items-center'>
                <div className='relative'>
              <div className=' font-GB text-[7rem] md:text-[3.7rem]'>
                NOT FOUND<span className='text-paletti'>.</span>
              </div>
                    <img
                className="comp-spin absolute right-[5rem] top-0  md:w-[2rem]"
                src={`${process.env.PUBLIC_URL}/asset/img/comp1.svg`} />
                    <img
                className="comp-spin absolute right-[10rem] top-18 md:top-14  md:w-[2rem]"
                src={`${process.env.PUBLIC_URL}/asset/img/comp1.svg`} />

                <img
                className="comp-spin absolute -left-[4rem] md:left-0 top-[0] w-[4rem] md:w-[2rem]"
                src={`${process.env.PUBLIC_URL}/asset/img/comp1.svg`} />
                    </div>              
            
            <NavLink to="/">
            <motion.div
              variants={{ hover: { scale: 0.85 }, press: { scale: 1.1 } }}
              className="label flex items-center"
            >

              <div
                className='z-[1]'>
                <div
                  className='text-[2.5rem] sm:text-[2rem] z-[1] flex items-center bg-white font-FaktumR border-2 border-black rounded-full text-center py-1 px-4 hover:scale-125'>
                  GO HOME
                  <img
                    className='w-[2.6rem] h-[2.6rem] ml-3 -rotate-90'
                    src={`${process.env.PUBLIC_URL}/asset/img/arr3.svg`} />
                </div>

              </div>

            </motion.div> 
            
          </NavLink>
          </div>
          </div>
            <div className='font-GM footer text-[1.2rem] text-center w-screen mb-3'>ALL RIGHTS RESERVED © 2023 PALETTI CO.</div>
        </div>
    )
}

