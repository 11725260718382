import React, { Component } from 'react';

export default function Business(props) {
    const {img_url,cur ,txt1,txt2,txt3,txt4} = props;
    return (

                <div className='flex items-center justify-center'>
                    <div className='flex relative'>
                        <div className={`circle !border-${cur}`}>
                            <img src={`${img_url}/bus1.png`} className='w-[5rem] h-[5rem]' />
                        </div>
                        <div className='text-[#FDB325] absolute right-[2.5rem] top-[5rem] text-[1.2rem]'>{txt1}</div>
                        <img src={`${img_url}/yellow_arr.svg`} className='w-[10rem]' />

                    </div>


                    <div className='flex relative ml-[-5rem]'>
                        <img src={`${img_url}/main_arr.svg`} className='w-[10rem] mt-10 transform -scale-y-100' />
                        <div className={`text-${cur} text-right absolute left-[2.5rem] bottom-[4.5rem] text-[1.2rem]`}>{txt2}</div>
                        <div className={`center-circle !border-${cur}`}>
                            <img src={`${img_url}/logo_2.svg`} className='w-[5rem]' />
                        </div>

                        <img src={`${img_url}/yellow_arr.svg`} className='w-[10rem] mt-10 transform -scale-y-100' />
                        <div className='text-[#FDB325] absolute right-[2.5rem] top-[8.5rem] text-[1.2rem]'>{txt3}</div>
                    </div>

                    <div className='flex relative ml-[-4rem]'>
                        <div className={`text-${cur} text-right absolute left-[2rem] top-[5.2rem] text-[1.2rem]`}>{txt4}</div>
                        <img src={`${img_url}/main_arr.svg`} className='w-[10rem] transform ml-[-0.5rem]' />
                        <div className={`circle z-[2] !border-${cur}`}>
                            <img src={`${img_url}/bus1.png`}  className='w-[5rem] h-[5rem]' />
                        </div>

                    </div>
                </div>
    )
}
