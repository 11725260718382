import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DURATION, Mobile } from 'value';

export default function MobilePage() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    

    useEffect(() => {
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <>
            <section className='flex flex-col layout' >
                <div className='font-B text-[2.6rem] mb-24'>PAGES</div>
                <div className='rleative flex justify-center items-center md:flex-col'>
                    <img src={`${img_url}/page1.png`} className='web-page-img' data-aos="fade-up" />

                    <div >

                        <div className='flex items-center mt-[3rem]' data-aos="fade-up">
                                    <div>
                                        <div className={`dt-title  text-${cur}`}>Main Banner</div>
                                        <div className={`dt-sub text-subGray`}>하나상조가 제공하고자 하는<br />
                                            메세지를 간결하게 전달합니다.</div>
                                    </div>

                        </div >

                        <div className='flex items-center mt-[3rem]' data-aos="fade-up">
                            
                                    <div >
                                        <div className={`dt-title text-${cur}`}>Main Service</div>
                                        <div className={`dt-sub text-subGray`}>하나상조가 제공하고자 하는<br />
                                            서비스를 한눈에 인식 할 수 있습니다.</div>
                                    </div>
                                

                        </div >
                        <div className='flex items-center mt-[3rem]' data-aos="fade-up">
                            <div>
                                <div className={`dt-title text-${cur}`}>장례절차 안내</div>
                                <div className={`dt-sub text-subGray`}>익숙하지 않은 장례절차를<br />
                                    간결한 아이콘을 이용해 순서를 제공합니다.</div>
                            </div>

                        </div>
                    </div>

                </div>

            </section>

            <section className='mt-[15rem]'>
                <div className='flex flex-col'>
                    <div className='flex items-center justify-center'>
                        <img src={`${img_url}/page2.png`} className='web-page-img' data-aos="fade-up" />
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex justify-start ' data-aos="fade-up"  >
                            <div className='ml-[4rem] mt-5 text-left'>
                                <div className={`dt-title text-${cur}`}>회사소개</div>
                                <div className={`dt-sub text-subGray`}>하나상조서비스가 제공하는
                                    <br />
                                    서비스를 전달합니다.</div>
                            </div>
                            {/* <img src={`${img_url}/arrow.svg`} className='w-[12rem] rotate-180 ' /> */}
                        </div >
                        <div className='flex justify-end' data-aos="fade-up">
                            <div className='mt-5 mr-[4rem] text-right'>
                                <div className={`dt-title text-${cur}`}>회사 연혁</div>
                                <div className={`dt-sub text-subGray`}>지금까지 하나상조서비스가
                                    <br />
                                    걸어온 발자취를 전달합니다.</div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='mt-[15rem]'>
                <div className='flex flex-col items-center justify-center'>
                    <div className='flex flex-col'>
                        <div className='flex flex-col ' data-aos="fade-up">
                            <div>
                                <img src={`${img_url}/page3.png`} className='web-page-img' data-aos="fade-up" />
                            </div>
                            <div className='my-5 ml-[1rem]'>
                                <div className={`dt-title text-${cur}`}>상품안내</div>
                                <div className={`dt-sub text-subGray`}>생소한 장례서비스 상품을
                                    <br />
                                    구분하기 쉽게 카테고리별로 제공합니다.</div>
                            </div>
                        </div >
                    </div>
                    <img src={`${img_url}/mockup1.png`} className='w-[25rem]' data-aos="fade-up" />
                </div>

            </section>

        </>
    )

}


