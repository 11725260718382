

export default function HashTag(props) {
    const { direction,active,cur } = props;

    return (
        <div className={direction == "right" ? 'flex flex-col items-end' : 'flex flex-col'}>
            <div className='flex'>
                <div className={`hash-tag !border-myweather ${active==1 ? `bg-myweather text-white hover:text-myweather hover:bg-white` :`text-myweather hover:text-white hover:bg-myweather`}  mr-2`}>알림기능</div>
                <div className={`hash-tag !border-myweather ${active==2 ? `bg-myweather text-white hover:text-myweather hover:bg-white` :`text-myweather hover:text-white hover:bg-myweather`}  mr-2`}>IoT 디바이스 연동</div>
                <div className={`hash-tag !border-myweather ${active==3 ? `bg-myweather text-white hover:text-myweather hover:bg-white` :`text-myweather hover:text-white hover:bg-myweather`}  mr-2`}>소셜 로그인</div>

            </div>
            <div className='flex mt-2'>
                <div className={`hash-tag !border-myweather ${active==4 ? `bg-myweather text-white hover:text-myweather hover:bg-white` :`text-myweather hover:text-white hover:bg-myweather`}  mr-2`}>움직이는 UI</div>
                <div className={`hash-tag !border-myweather ${active==5 ? `bg-myweather text-white hover:text-myweather hover:bg-white` :`text-myweather hover:text-white hover:bg-myweather`}  mr-2`}>관리자 페이지</div>
            </div>
        </div>
    )

}