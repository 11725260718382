import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Circle from 'component/Circle';
import { DURATION, Mobile, Pc } from 'value';

export default function Cablecar() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <div className=' overflow-x-hidden '>
            <section className="top-bg h-screen bg-cablecar" >
                <img src={`${img_url}/app.svg`} className='w-32' />
                <div className={`text-white text-center text-2xl mt-5 sm:text-[1rem]`}>해상 케이블카 입장권 검표 어플리케이션</div>
            </section>
        <div className='max-container'>
            <section className="flex layout" data-aos="fade-up"  >
                <div className="flex-1">
                    <div>
                        <img src={`${img_url}/logo.svg`} className='w-42 mb-4 mt-36' />
                        <div className='text-[1.5rem] font-EB'>해상 케이블카 어플리케이션</div>
                        <div className='text-[1.4rem] sm:text-[1rem]'>해상 케이블카 입장권 검표 어플리케이션</div>
                    </div>
                    <div className='mt-10 text-[1.4rem]'>
                        <div>프로젝트 기간 : 1개월</div>
                        <div>지원가능 OS : Android / IOS</div>
                        <div>제공언어 : 한국어</div>
                    </div>

                </div>
                <div className='relative back-mockup'>
                    <div className='w-[55rem] h-[55rem] rounded-full bg-split_10 absolute top-26 -left-14 -z-20' />
                    <img src={`${img_url}/mockup.svg`} className='z-[2]' />
                </div>

            </section>
            <section className=' pb-20 relative top-[-10rem] z-0' data-aos="fade-up"  >
                <div className='layout'>

                    <div className='subtitle'>Persona</div>
                    <div className='flex mb-10'>

                        <div className='bg-[#F8F8F8] p-6 text-left rounded-lg flex flex-col'>
                            <div>
                                <img className='w-24' src={`${img_url}/client.png`} />
                                <div className='font-SB text-[#6C6C6C] text-[1.4rem] mt-3'>CLIENT</div>
                            </div>
                            <div className='mt-10 text-[1.2rem]'>
                                <span className='text-cablecar font-EB text-[1.4rem]'>WANT</span>
                                <br />
                                티켓을 바코드 스캐너로 스캔 후에<br />
                                케이블카 입장권을 검표 할 수 있는 어플을 만들고 싶어요.<br />
                            </div>

                            <div className='mt-10 text-[1.2rem]'>
                                <span className={`text-[1.4rem] text-${cur} font-EB`}>GOAL</span><br />
                                <div className='flex items-center mb-1'>
                                    <Circle number={1} color={cur} />
                                    티켓을 바코드로 스캔
                                </div>
                                <div className='flex items-center mb-1'>
                                    <Circle number={2} color={cur} />
                                    바코드 스캔시 티켓 정보 송출
                                </div>
                                <div className='flex items-center'>
                                    <Circle number={3} color={cur} />
                                    티켓의 사용유무 체크 후 서버에 전송
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='layout' data-aos="fade-up"  >
                <div className='subtitle'>BRANDING</div>
                <div className='flex items-center justify-between md:flex-col'>
                    <div className='flex-1'>
                        <img src={`${img_url}/dock.png`} />
                    </div>
                    <div className='flex-1 flex ml-20 md:ml-0'>
                        <img src={`${img_url}/logo_3d.png`} className='w-48 h-48 self-end' />
                        <img src={`${img_url}/logo_3d_c.png`} className='w-80 h-80 -ml-10' />
                    </div>
                </div>


            </section>
            <section className='layout justify-center items-center flex flex-col py-24' data-aos="fade-up"  >
                <div className='flex py-28 md:flex-col md:items-center  '>
                    <img src={`${img_url}/main1.png`} className='w-[24rem]' />
                    <Pc>
                        <div className='relative'>
                            <img src={`${img_url}/arr1.svg`} className='absolute top-32 -left-2 arrow w-[12rem]' />
                            <div className='relative top-[6.5rem] left-[12rem] '>
                                <div className='dt-title'><span className={`text-${cur} font-B`}>장소이름</span>과 <span className={`text-${cur} font-B`}>시간</span>을 한눈에</div>
                                <div className='text-subGray dt-sub'>한눈에 해당 장소와<br />시간을 알아볼 수 있어요.</div>
                            </div>
                        </div>

                        <div className='relative'>
                            <img src={`${img_url}/arr1.svg`} className='arrow absolute md:top-[28rem] top-[30rem] -left-[20.8rem] w-[12rem]' />
                            <div className='relative top-[28.5rem] -left-[7rem] '>
                                <div className='dt-title'><span className={`text-${cur} font-B`}>상품명</span>과 <span className={`text-${cur} font-B`}>인원수</span>를 한눈에</div>
                                <div className='text-subGray dt-sub'>한눈에 해당 상품명과<br />인원수를 알아볼 수 있어요.</div>
                            </div>
                        </div>
                    </Pc>
                    <Mobile>
                        <div className='mt-[4rem]'>
                            <div className='dt-title'><span className={`text-${cur} font-B`}>장소이름</span>과 <span className={`text-${cur} font-B`}>시간</span>을 한눈에</div>
                            <div className='text-subGray dt-sub'>한눈에 해당 장소와<br />시간을 알아볼 수 있어요.</div>
                        </div>

                        <div className='mt-[4rem] text-right'>
                            <div className='dt-title'><span className={`text-${cur} font-B`}>상품명</span>과 <span className={`text-${cur} font-B`}>인원수</span>를 한눈에</div>
                            <div className='text-subGray dt-sub'>한눈에 해당 상품명과<br />인원수를 알아볼 수 있어요.</div>
                        </div>
                    </Mobile>
                    <img src={`${img_url}/detail_bg.png`} className='absolute -z-10 w-[40rem]' />
                </div>
                <div className='flex py-28 md:flex-col md:items-center'>
                    <Pc>
                        <div className='relative'>
                            <div className='relative top-[10.8rem] right-[1rem]'>
                                <div className='dt-title text-right'><span className={`text-${cur} font-B`}>유효한</span> 티켓인지 확인.</div>
                                <div className='text-subGray dt-sub text-right' >유효하지 않은 티켓이라면<br />소리와 함께 배경색이 빨간색으로 나타나요.</div>
                            </div>
                            <img src={`${img_url}/arr1.svg`} className='absolute top-44 -right-[16.2rem] transform -scale-x-100 arrow w-[12rem]' />
                        </div>
                    </Pc>
                    <Mobile>
                        <div className='mb-[3rem]'>
                            <div>
                                <div className='dt-title text-right'><span className={`text-${cur} font-B`}>유효한</span> 티켓인지 확인.</div>
                                <div className='text-subGray dt-sub text-right' >유효하지 않은 티켓이라면<br />소리와 함께 배경색이 빨간색으로 나타나요.</div>
                            </div>
                        </div>
                    </Mobile>
                    <div className='flex items-end md:flex-col md:items-center'>
                        <img src={`${img_url}/main2_1.png`} className='w-[14rem] mr-7 md:w-[24rem] md:mr-0 md:mb-10' />
                        <img src={`${img_url}/main2.png`} className='w-[24rem]' />
                    </div>
                    <img src={`${img_url}/detail_bg.png`} className='absolute -z-10 w-[40rem] left-[10rem]' />
                </div>
                <div className='flex py-28 md:flex-col md:items-center'>
                    <img src={`${img_url}/main3.png`} className='w-[18rem] md:w-[25rem]' />
                    <Mobile>
                        <div className='mt-[5rem]'>
                            <div className=''>
                                <div className='dt-title'><span className={`text-${cur} font-B`}>탑승시각</span>과 <span className={`text-${cur} font-B`}>하차시각</span>도 함께.</div>
                                <div className='text-subGray dt-sub'>탑승시각 및 하차시각 그리고<br />
                                    발권을 받은 기기까지 알 수 있어요.</div>
                            </div>
                        </div>
                    </Mobile>
                    <Pc>
                        <div className='relative'>
                            <img src={`${img_url}/arr1.svg`} className='absolute top-32 -left-2 arrow w-[12rem]' />
                            <div className='relative top-[7.5rem] left-[14rem]'>
                                <div className='dt-title'><span className={`text-${cur} font-B`}>탑승시각</span>과 <span className={`text-${cur} font-B`}>하차시각</span>도 함께.</div>
                                <div className='text-subGray dt-sub'>탑승시각 및 하차시각 그리고<br />발권을 받은 기기까지 알 수 있어요.</div>
                            </div>
                        </div>

                    </Pc>

                </div>
                <div className='flex py-28 md:flex-col md:items-center'>
                    <Mobile>
                        <div className='relative md:flex md:flex-col'>
                            <div className='mb-[7rem]'>
                                <div className='dt-title text-right'>현재 <span className={`text-${cur} font-B`}>접속중인 서버</span>정보 확인.</div>
                                <div className='text-subGray dt-sub text-right' >현재의 서버 정보와 사용장소, 아이디까지<br />확인 할 수 있어요.</div>
                            </div>
                        </div>
                        <div className='mb-[5rem]'>
                            <div className=''>
                                <div className='dt-title text-left'><span className={`text-${cur} font-B`}>서버연결</span>이 되어있는지 미리 확인.</div>
                                <div className='text-subGray dt-sub text-left' >새로운 IP로 접속시<br />연결에 성공했는지 미리 확인할 수 있어요.</div>
                            </div>
                        </div>
                    </Mobile>
                    <Pc>
                        <div className='relative'>
                            <div className='relative top-[10.6rem] -right-[14rem]'>
                                <div className='dt-title text-right'>현재 <span className={`text-${cur} font-B`}>접속중인 서버</span>정보 확인.</div>
                                <div className='text-subGray dt-sub text-right' >현재의 서버 정보와 사용장소, 아이디까지<br />확인 할 수 있어요.</div>
                            </div>
                            <img src={`${img_url}/arr1.svg`} className='arrow absolute top-[10.8rem] -right-[26.7rem] transform -scale-x-100 w-[12rem]' />
                        </div>
                        <div className='relative'>
                            <div className='relative top-[31.2rem] right-[12.3rem]'>
                                <div className='dt-title text-right'><span className={`text-${cur} font-B`}>서버연결</span>이 되어있는지 미리 확인.</div>
                                <div className='text-subGray dt-sub text-right' >새로운 IP로 접속시<br />연결에 성공했는지 미리 확인할 수 있어요.</div>
                            </div>
                            <img src={`${img_url}/arr1.svg`} className='arrow absolute top-[31.8rem] -right-[0.6rem]  transform -scale-x-100 w-[12rem]' />
                        </div>

                    </Pc>
                    <img src={`${img_url}/main4.png`} className='w-[24rem] md:w-[27rem]' />
                    <img src={`${img_url}/detail_bg.png`} className='absolute -z-10 w-[40rem] left-[10rem]' />
                </div>
            </section>

            </div>


            <section className={`flex flex-col bg-${cur} items-center justify-end h-[40rem] relative`} data-aos="fade-up"  >

                <div className='flex flex-col items-center justify-center mb-10'>

                    <img src={`${img_url}/btm_main.svg`} className='w-[42rem]' />
                    <img src={`${img_url}/app.svg`} className='w-20 mb-4' />
                </div>

            </section>
        </div>
    )

}
