import React, { Component } from 'react';

export default function PortfolioComp(props) {
        const {name, kind, date,link ,idx,btm} = props;
        return (
                <div className='bg-[#EEEEEE]'>
                        {/* <div className='font-B text-md'>{name}</div> */}
                        {/* <div className='mt-5'> */}
                        <img src={link} style={{width:'100%'}} className={`${idx == 0 ? 'border-r border-l' :''}  ${btm ? 'border-b' :''} object-cover object-left flex flex-1 h-[30rem] border-t border-[#C6C6CA]`}/>
                        {/* </div> */}
                </div>
        )

}





{/* <div className='border-solid border-black border-t-2 mr-8 w-[26rem] min-w-[15rem] hover:scale-110'>
<div className='font-B text-md'>{name}</div>
<div className='mt-10'>{kind}</div>
<div className='text-[#B1B1B1] text-sm'>{date}</div>
<div className='mt-5'>
<img src={link} className='w-[30rem] h-auto'/>
</div>
</div> */}