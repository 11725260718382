import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Circle from 'component/Circle';
import { DURATION, Mobile, Pc } from 'value';
import HashTag from './HashTag';

export default function Metaverse() {
    const location = useLocation();
    const [cur, setCur] = useState('');
    const img_url = `${process.env.PUBLIC_URL}/asset/img/${cur}`;
    useEffect(() => {
        const paths = location.pathname.split('/');
        setCur(paths[paths.length - 1])
    }, [location])

    return (
        <div className='overflow-x-hidden'>
            <section className="top-bg h-screen" style={{ backgroundImage: `url("${img_url}/bg.png")` }} >
                <div className='font-EB text-6xl tracking-widest text-metaverse text-center md:text-3xl'>METAVERSE<br />SAFTEY COLLABORATION</div>
            </section>
            <div className='max-container'>
                <section className="flex layout" >
                    <div className="flex-1">
                        <div>
                            <img src={`${img_url}/logo.png`} className='w-20 mt-36' />
                            <div className='text-[1.4rem]'>발전현장 메타버스 안전.협업 모바일/웹 플랫폼</div>
                        </div>
                        <div className='mt-10 text-[1.3rem]'>
                            <div>프로젝트 기간 : 6개월</div>
                            <div>지원가능 OS : Android / IOS</div>
                            <div>제공언어 : 한국어</div>
                        </div>

                    </div>
                    <div className='relative back-mockup' data-aos="fade-left" >
                        <div className='w-[50rem] h-[50rem] rounded-full bg-metaverse_10 absolute top-56 -z-20' />
                        <img src={`${img_url}/mockup.svg`} className='z-[2]' />
                    </div>

                </section>
                <section className='pb-20 relative top-[-10rem] z-0' >
                    <div className='layout'>

                        <div className='subtitle'>Persona</div>
                        <div className='flex mb-10'>

                            <div className='bg-[#F8F8F8] p-6 text-left rounded-lg flex flex-col'>
                                <div>
                                    <img className='w-24' src={`${img_url}/client.png`} />
                                    <div className='font-SB text-[#6C6C6C] text-[1.5rem] mt-3'>CLIENT</div>
                                </div>
                                <div className='mt-10 text-[1.3rem]'>
                                    <span className={`text-${cur} font-EB`}>WANT</span>
                                    <br />
                                    현장과 중앙제어실에서 근무자간에 소통을 하며<br />
                                    교육을 할 수 있는 플랫폼을 만들고 싶습니다.<br />
                                </div>

                                <div className='mt-10 text-[1.3rem]'>
                                    <span className={`text-${cur} font-EB`}>GOAL</span><br />
                                    <div className='flex items-center mb-1'>
                                        <Circle number={1} color={cur} />
                                        가상공간과 현장설비 간의 위치정보 동기화
                                    </div>
                                    <div className='flex items-center mb-1'>
                                        <Circle number={2} color={cur} />
                                        현장 작업자와 중앙제어실 근무자간 실시간 음성,문자 정보 교환
                                    </div>
                                    <div className='flex items-center'>
                                        <Circle number={3} color={cur} />
                                        수행절차 교육훈련 시나리오를 활용한 기능 개발 및 접속현황 통계
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='subtitle'>
                            Service
                        </div>
                        <div className='ml-16 md:ml-0'>
                            <div className='flex justify-between items-center md:block'>
                                <div className='flex flex-col justify-center items-center'>
                                    <div className={`text-center flex justify-center items-center w-[10rem] h-[10rem] text-[1rem] md:text-[1.3rem] text-${cur} rounded-full border border-dashed !border-${cur}`}>영상 전송</div>
                                    <div className='text-center mt-5 text-[1.2rem]'>모바일 및 웹 기반 안전.협업 플랫폼 내에서<br /> 1:1 또는 1:N 간의 영상 전송을 통한 공유 기능 개발</div>
                                </div>
                                <div className='flex flex-col justify-center items-center md:mt-[4rem]'>
                                    <div className={`text-center flex justify-center items-center w-[10rem] h-[10rem] text-[1rem] md:text-[1.3rem] text-${cur} rounded-full border border-dashed !border-${cur}`}>음성 통화</div>
                                    <div className='text-center mt-5 text-[1.2rem]'>모바일 및 웹 기반 안전.협업 플랫폼 내에서<br /> 1:1 또는 1:N간의 화상, 음성 통화 기능 개발</div>
                                </div>
                                <div className='flex flex-col justify-center items-center md:mt-[4rem]'>
                                    <div className={`text-center flex justify-center items-center w-[10rem] h-[10rem] text-[1rem] md:text-[1.3rem] text-${cur} rounded-full border border-dashed !border-${cur}`}>문자 채팅</div>
                                    <div className='text-center mt-5 text-[1.2rem]'>모바일 및 웹 기반 안전.협업 플랫폼 내에서<br /> 1:1 또는 1:N 간의 문자 채팅 기능 개발</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className='' data-aos="fade-up" >
                    <div className='layout'>
                        <div className='subtitle'>Avatar</div>
                        <div className='text-[1.3rem]'>아바타의 다양한 애니메이션을<br />확인할 수 있어요.</div>
                    </div>
                   <Pc>
                   <div className='relative mt-[10rem] md:mt-[18rem]'>
                        <img src={`${img_url}/main_arr.svg`} className='relative -left-[13rem]' />
                        <div className='flex flex-col items-center absolute -top-[8rem] right-[15rem] md:-top-[14rem] md:right-[10rem]'>
                            <div className=' text-[1.2rem]'>앉기</div>
                            <img src={`${img_url}/sit.png`} className='w-[16rem] md:w-[10rem]' />
                        </div>
                        <div className='flex flex-col items-center absolute top-[15rem] left-[15rem] md:left-[3rem] z-[2]'>
                            <div className='text-center'>기본자세</div>
                            <img src={`${img_url}/base.png`} className='w-[16rem] md:w-[10rem]' />
                        </div>
                        <div className='flex flex-col items-center absolute top-[45rem] right-[15rem]'>
                            <div className=' text-[1.2rem]'>회의실</div>
                            <img src={`${img_url}/meeting.png`} className='w-[20rem] md:w-[14rem]' />
                        </div>
                        <div className='flex flex-col items-center absolute top-[120rem] left-[20rem] md:-top-[5rem] md:left-[0rem]'>
                            <div className=' text-[1.2rem]'>걷기</div>
                            <img src={`${img_url}/walk.png`} className='w-[18rem] md:w-[12rem]' />
                        </div>
                        <div className='flex flex-col items-center absolute bottom-[30rem] right-[15rem] md:bottom-[40rem] md:right-[10rem]'>
                            <div className=' text-[1.2rem]'>지시하기</div>
                            <img src={`${img_url}/pointing.png`} className='w-[18rem] md:w-[10rem]' />
                        </div>

                    </div>
                   </Pc>
                   <Mobile>
                   <div className='mt-[5rem] flex justify-center flex-col items-center'>
                        {/* <img src={`${img_url}/main_arr.svg`} className='left-1/2' /> */}
                        <div className='flex flex-col items-center'>
                            <div className=' text-[1.2rem]'>앉기</div>
                            <img src={`${img_url}/sit.png`} className=' w-[10rem]' />
                        </div>
                        <div className='flex flex-col items-cente'>
                            <div className='text-center'>기본자세</div>
                            <img src={`${img_url}/base.png`} className=' w-[10rem]' />
                        </div>
                        <div className='flex flex-col items-center'>
                            <div className=' text-[1.2rem]'>회의실</div>
                            <img src={`${img_url}/meeting.png`} className=' md:w-[14rem]' />
                        </div>
                        <div className='flex flex-col items-center'>
                            <div className=' text-[1.2rem]'>걷기</div>
                            <img src={`${img_url}/walk.png`} className='md:w-[12rem]' />
                        </div>
                        <div className='flex flex-col items-center'>
                            <div className=' text-[1.2rem]'>지시하기</div>
                            <img src={`${img_url}/pointing.png`} className='w-[10rem]' />
                        </div>

                    </div>
                   </Mobile>
                </section>

                <section>
                    <section className='h-screen flex layout items-center justify-around md:mt-[5rem]' data-aos="fade-up"  >
                    <div className='md:flex md:items-center md:justify-center'>
                        <img src={`${img_url}/main1.png`} className='mr-[6rem] md:mr-0 app-mockup' />
                        </div>
                        <div className='md:flex md:items-center md:flex-col md:justify-center  '>
                            <div className='font-SB service-sb-txt text-right'>파노라마뷰 간의</div>
                            <div className='font-SB service-eb-txt mb-5 text-right'><span className={`text-${cur} font-EB`}>스팟이동.</span></div>
                            <Pc><HashTag cur={cur} active={1} direction="right" /></Pc>
                        </div>
                    </section>

                    <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
                    <div className=' md:flex md:items-center md:flex-col md:justify-center  '>
                            <div className='font-SB service-sb-txt'>파노라마뷰를 이용한 가상공간에서,</div>
                            <div className={`font-EB service-eb-txt text-${cur} mb-5`}>360도 원하는 뷰를 확인.</div>
                            <Pc>
                                <HashTag cur={cur} active={2} />
                            </Pc>
                        </div>
                        <div className='md:flex md:items-center md:justify-center'>
                        <img src={`${img_url}/main2.png`} className='app-mockup' />

                        </div>
                    </section>

                    <section className=' h-screen flex layout items-center justify-around' data-aos="fade-up"  >
                    <div className='md:flex md:items-center md:justify-center'>
                        <img src={`${img_url}/main3.png`} className='w-[30rem] md:w-[35rem]' />
                        </div>
                        
                        <div className=' text-right md:flex md:items-center md:flex-col md:justify-center  '>
                            <div className='font-SB service-sb-txt'>자체 개발한 WebRTC로</div>
                            <div className={`font-EB service-eb-txt text-${cur} mb-5`}>N:N 실시간 영상 통화와 채팅.</div>
                            <Pc>
                                <HashTag cur={cur} active={3} direction="right" />
                            </Pc>
                        </div>

                    </section>
                    <section className=' h-screen flex mr-[15rem] md:mr-0 items-center justify-between md:items-stretch md:flex-col' data-aos="fade-up"  >
                        <Pc>
                            <div className='flex flex-1 justify-around relative mr-10 md:mr-0' style={{ backgroundImage: `url("${img_url}/section_bg.png")` }}>
                                <img src={`${img_url}/walk.png`} className='w-[18rem] absolute -top-[10rem] left-0' />
                                <img src={`${img_url}/sit.png`} className='w-[13rem] ml-[15rem]' />

                            </div>
                        </Pc>
                        <Mobile>
                            <div className='flex flex-col items-center flex-1 ml-10'>
                                <div className='flex self-start'>
                                    <img src={`${img_url}/walk.png`} className='w-[18rem] object-contain ' />

                                </div>
                                <div className='flex self-end'>
                                    <img src={`${img_url}/sit.png`} className='w-[16rem]  object-contain' />

                                </div>
                            </div>
                        </Mobile>

                        <div className='mr-[6rem] md:mt-[2rem]'>
                            <div className='font-SB service-sb-txt text-right'>파노라마뷰에서 다양한,</div>
                            <div className={`font-EB service-eb-txt text-${cur} mb-5 text-right`}>아바타 애니메이션으로 의사표현.</div>
                            <Pc>
                                <HashTag cur={cur} active={4} direction="right" />
                            </Pc>
                        </div>

                    </section>

                    <section className=' h-screen flex layout items-center justify-around md:mt-[19rem]' data-aos="fade-up"  >
                    <div className=' md:flex md:items-center md:flex-col md:justify-center  '>
                            <div className='font-SB service-sb-txt'>실시간 데이터 정보를</div>
                            <div className={`font-EB service-eb-txt text-${cur} mb-5`}>바로 업데이트.</div>
                            <Pc>
                                <HashTag cur={cur} active={5} />
                            </Pc>
                        </div>
                        <div className='md:flex md:items-center md:justify-center'>
                        <img src={`${img_url}/main6.png`} className='app-mockup' />
                        </div>
                    </section>
                </section>
            </div>
            <section className={`flex flex-col bg-${cur} items-center justify-end h-[30rem]`} data-aos="fade-up"  >

                <div className='flex flex-col items-center justify-center mb-10'>

                    {/* <img src={`${img_url}/btm_main.svg`} className='w-[30rem] mb-16 mt-10' /> */}
                </div>

            </section>
        </div>
    )

}

