import React, { Component } from 'react';
import { Pc, Mobile } from 'value';

import Lottie from 'lottie-react';
// import sun from './animation/sun.json';
// import sun_night from './animation/sun_night.json';
// import cloudy from './animation/cloudy.json';
// import many_cloudy_morning from './animation/many_cloudy_morning.json';
// import many_cloudy_night from './animation/many_cloudy_night.json';
// import partly_cloudy_morning from './animation/partly_cloudy_morning.json';
// import partly_cloudy_night from './animation/partly_cloudy_night.json';
// import storm from './animation/storm.json';
// import strong_rain from './animation/strong_rain.json';
// import strong_snow from './animation/strong_snow.json';
// import weak_rain from './animation/weak_rain.json';


export default function Character(props) {
    const { img_url } = props;
    return (
        <>
            <Pc>
                <div className='flex flex-col justify-center items-center'>
                    <div className='flex items-end justify-start mt-[6rem]'>

                        <Left flag={true} day={true} weather={"맑음"} name={"weather1"} img_url={img_url} />
                        <Right name={"weather2"} img_url={img_url} />

                        <Left flag={true} day={true} weather={"구름 조금"} name={"weather3"} img_url={img_url} />
                        <Right name={"weather4"} img_url={img_url} />

                        <Left flag={true} day={true} weather={"구름 많음"} name={"weather5"} img_url={img_url} />
                        <Right name={"weather6"} img_url={img_url} />
                    </div>
                    <div className='flex items-end justify-start  mt-[6rem]'>

                        <Left weather={"흐림"} name={"weather7"} img_url={img_url} />
                        <Left weather={"약한비"} name={"weather8"} img_url={img_url} />
                        <Left weather={"보통비"} name={"weather9"} img_url={img_url} />
                        <Left weather={"강한비"} name={"weather10"} img_url={img_url} />
                        <Left weather={"폭우"} name={"weather11"} img_url={img_url} />
                        <Left weather={"뇌우"} name={"weather12"} img_url={img_url} />
                    </div>
                    <div className='flex items-end justify-start  mt-[6rem]'>
                        <Left weather={"약한눈"} name={"weather13"} img_url={img_url} />
                        <Left weather={"보통눈"} name={"weather14"} img_url={img_url} />
                        <Left weather={"강한눈"} name={"weather15"} img_url={img_url} />

                    </div>
                </div>

            </Pc>
            <Mobile>
                <div className='flex flex-col justify-between items-stretch mx-5 '>
                    <div className='flex items-end justify-between mt-16'>
                        <Left flag={true} day={true} weather={"맑음"} name={"weather1"} img_url={img_url} />
                        <Right name={"weather2"} img_url={img_url} />
                    </div>
                    <div className='flex items-end justify-between mt-16'>
                        <Left flag={true} day={true} weather={"구름 조금"} name={"weather3"} img_url={img_url} />
                        <Right name={"weather4"} img_url={img_url} />
                    </div>
                    <div className='flex items-end justify-between mt-16'>
                        <Left flag={true} day={true} weather={"구름 많음"} name={"weather5"} img_url={img_url} />
                        <Right name={"weather6"} img_url={img_url} />
                    </div>
                    <div className='flex items-end justify-between mt-16'>
                        <Left weather={"흐림"} name={"weather7"} img_url={img_url} />
                        <Left weather={"약한비"} name={"weather8"} img_url={img_url} />
                    </div>
                    <div className='flex items-end justify-between mt-16'>
                        <Left weather={"보통비"} name={"weather9"} img_url={img_url} />
                        <Left weather={"강한비"} name={"weather10"} img_url={img_url} />
                    </div>
                    <div className='flex items-end justify-between mt-16'>
                        <Left weather={"폭우"} name={"weather11"} img_url={img_url} />
                        <Left weather={"뇌우"} name={"weather12"} img_url={img_url} />
                    </div>
                    <div className='flex items-end justify-between mt-16'>
                        <Left weather={"약한눈"} name={"weather13"} img_url={img_url} />
                        <Left weather={"보통눈"} name={"weather14"} img_url={img_url} />
                    </div>
                </div>
                <div className='flex items-end justify-center mt-16'>
                    <Left weather={"강한눈"} name={"weather15"} img_url={img_url} />
                    <div style={{visibility:'hidden'}}>
                    <Left weather={"강한눈"} name={"weather15"} img_url={img_url}  />

                    </div>
                </div>
            </Mobile >
        </>



    )

}

function Left(props) {
    const { flag, img_url, name, weather } = props;
    return (
        <div className='text-left text-[1.1rem] mr-[3rem] md:mr-0'>
            <div className='text-[1.2rem] mb-5'>{weather}</div>
            <div>
                <img src={`${img_url}/${name}.png`} className='w-[11rem] h-[9.3rem]' />
                {flag ? <div className='text-center mt-5'>낮</div> : <div></div>}
            </div>

        </div>
    )
}

function Right(props) {
    const { name, img_url } = props;
    return (
        <div className='text-left text-[1.1rem] mr-[3rem] md:mr-0'>

            <div>
                <img src={`${img_url}/${name}.png`} className='w-[9.7rem] h-[9rem]' />
                <div className='text-center mt-5'>밤</div>
            </div>

        </div>
    )
}
