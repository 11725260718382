import { DURATION } from 'value';
import Circle from 'component/Circle';

export default function PcHow(props) {
    const { cur, img_url} = props;

    return (
       <>
          <div className='flex justify-between' data-aos="fade-up">
                    <div className='relative' >
                        <div className='rounded-full w-[35rem] h-[35rem] bg-split_10 absolute top-[5rem] -left-[3rem] -z-10' />
                        <img src={`${img_url}/works1.png`} className='w-[28rem]' />
                    </div>
                    <div className='flex flex-col flex-1' >
                        <div className='relative -left-[1.5rem] top-[11.6rem] flex-1'>
                            <img src={`${img_url}/arrow.svg`} className=' w-[18rem] h-fit ' />
                            <div className=' left-[19rem] top-[7rem] absolute flex-1'>
                                <div className='dt-title flex items-center' >
                                    <Circle number="1" color={cur} />
                                    원하는<span className={`text-${cur}`}>OTT 서비스</span>를 선택하세요.</div>
                                <div className='dt-sub text-expGray  ml-7'>넷플릭스, 웨이브, 왓챠, 티빙까지<br />원하는 OTT를 마음껏 선택하세요.</div>
                            </div>
                        </div>
                        <div className=' flex justify-center' >
                            <div className='relative -left-[1.8rem] -top-[15rem] flex-1'>
                                <img src={`${img_url}/arrow.svg`} className=' w-[18rem] h-fit ' />
                                <div className='left-[19rem] top-[7rem] absolute flex-1'>
                                    <div className='dt-title flex items-center' >
                                        <Circle number="2" color={cur} />
                                        <span className={`text-${cur}`}>파티장 or 파티원</span>선택후 매칭하기 클릭.</div>
                                    <div className='dt-sub text-expGray ml-7'>파티장은 파티원 보다<br />더 저렴한 가격으로 이용할 수 있어요.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex justify-between' data-aos="fade-up">
                    <div className='flex flex-col flex-1'>
                        <div className='relative -left-[3rem] top-[23rem] flex-1'>
                            <div className='right-[15rem] top-[7rem] absolute flex-1'>
                                <div className='dt-title flex items-center' >
                                    <Circle number="3" color={cur} />
                                    원하는 <span className={`text-${cur}`}>파티 매칭</span>될때까지 기다리기.</div>
                                <div className='dt-sub text-expGray  ml-7'>같이 볼 사람 찾을 필요 없이<br />자동으로 매칭해 드려요.</div>
                            </div>
                            <img src={`${img_url}/arrow.svg`} className='absolute -right-[3.5rem] z-[1] w-[18rem] h-fit trnasform -scale-x-100' />
                        </div>
                    </div>
                    <div className='relative'>
                        <div className='rounded-full w-[35rem] h-[35rem] bg-split_10 absolute top-[5rem] -left-[3rem] -z-10' />
                        <img src={`${img_url}/works2.png`} className='w-[28rem] ' />
                    </div>
                </div>

                <div className='flex justify-between' data-aos="fade-up">
                <div className='relative' >
                        <img src={`${img_url}/works3.png`} className='w-[28rem]' />
                        <div className='rounded-full w-[35rem] h-[35rem] bg-split_10 absolute top-[5rem] -left-[3rem]  -z-10' />
                    </div>
                    
                    <div className='flex flex-col flex-1'>
                        <div className='relative -left-[1.6rem] top-[23rem] flex-1'>
                            <img src={`${img_url}/arrow.svg`} className=' w-[18rem] h-fit ' />
                            <div className=' left-[19rem] top-[7rem] absolute flex-1'>
                                <div className='dt-title flex items-center' >
                                    <Circle number="4" color={cur} />
                                    <span className={`text-${cur}`}>매칭</span>성공!</div>
                                <div className='dt-sub text-expGray  ml-7'>언제든 원할때 탈퇴 할 수 있고,<br />미리 예약도 가능해요!</div>
                            </div>
                        </div>
                    </div>
                </div>
       </>
    )

}