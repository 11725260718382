export default function HashTag(props) {
    const { direction, cur ,active} = props;

    return (
        <div className={direction == "right" ? 'flex flex-col items-end' : 'flex flex-col'}>
            <div className='flex'>
                <div className={`hash-tag !border-ranchan ${active==1 ? `bg-ranchan text-white hover:text-ranchan hover:bg-white` :`text-ranchan hover:text-white hover:bg-ranchan`}  mr-2`}>In-App 결제</div>
                <div className={`hash-tag !border-ranchan ${active==2 ? `bg-ranchan text-white hover:text-ranchan hover:bg-white` :`text-ranchan hover:text-white hover:bg-ranchan`}  mr-2`}>카카오 지도 연동</div>
                <div className={`hash-tag !border-ranchan ${active==3 ? `bg-ranchan text-white hover:text-ranchan hover:bg-white` :`text-ranchan hover:text-white hover:bg-ranchan`}  mr-2`}>반찬 주문</div>
                <div className={`hash-tag !border-ranchan ${active==4 ? `bg-ranchan text-white hover:text-ranchan hover:bg-white` :`text-ranchan hover:text-white hover:bg-ranchan`}  mr-2`}>SNS 로그인</div>

            </div>
        </div>
    )

}