export default function PcBus(props) {
    const {img_url} = props;
    return (
        <div className='flex items-center justify-center'>
            <div className='flex relative'>
                <div className='circle !border-saferoom'>
                    <img src={`${img_url}/bus1.png`} className='w-16 h-16' />
                </div>
                <div className='text-[#FDB325] absolute right-[1.5rem] top-[5rem] text-[1.3rem]'>월 구독료</div>
                <img src={`${img_url}/yellow_arr.svg`} className='w-[10rem]' />

            </div>


            <div className='flex relative ml-[-5rem]'>
                <img src={`${img_url}/main_arr.svg`} className='w-32 mt-10 transform -scale-y-100' />
                <div className='text-saferoom text-right absolute left-10 bottom-16 text-[1.3rem]'>매물 제공</div>
                <div className='center-circle !border-saferoom'>
                    <img src={`${img_url}/logo_2.svg`} className='w-20' />
                </div>

                <img src={`${img_url}/yellow_arr.svg`} className='w-[10rem] mt-10 transform -scale-y-100' />
                <div className='text-[#FDB325] absolute right-[1.5rem] top-[8.6rem] text-[1.3rem]'>구매자 연결</div>
            </div>

            <div className='flex relative ml-[-4rem]'>
                <div className='text-saferoom text-right absolute left-3 top-[4.7rem] text-[1.3rem]'>판매 요청</div>
                <img src={`${img_url}/main_arr.svg`} className='w-[10rem] transform ml-[-0.5rem]' />
                <div className='circle z-[2] !border-saferoom'>
                    <img src={`${img_url}/bus2.png`} className='w-16 h-16' />
                </div>

            </div>
        </div>
    )
}