import React, { Component,useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuToggle } from './MenuToggle';
import 'css/layout.css'
import { useAnimate, stagger } from "framer-motion";


function useMenuAnimation(isOpen) {
    const [scope, animate] = useAnimate();
  
    useEffect(() => {
      const menuAnimations = isOpen
        ? [
            [
              "nav",
              { transform: "translateX(0%)" },
              { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.6 }
            ],
            [
              "li",
              { transform: "scale(1)", opacity: 1, filter: "blur(0px)" },
              { delay: stagger(0.05), at: "-0.1" }
            ]
          ]
        : [
            [
              "li",
              { transform: "scale(0.5)", opacity: 0, filter: "blur(10px)" },
              { delay: stagger(0.05, { from: "last" }), at: "<" }
            ],
            ["nav", { transform: "translateX(100%)" }, { at: "-0.1" }]
          ];
  
      animate([
        [
          "path.top",
          { d: isOpen ? "M 3 16.5 L 17 2.5" : "M 2 2.5 L 20 2.5" },
          { at: "<" }
        ],
        ["path.middle", { opacity: isOpen ? 0 : 1 }, { at: "<" }],
        [
          "path.bottom",
          { d: isOpen ? "M 3 2.5 L 17 16.346" : "M 2 16.346 L 20 16.346" },
          { at: "<" }
        ],
        ...menuAnimations
      ]);
    }, [isOpen]);
  
    return scope;
  }

export default function Top(props) {
    
  const {setIsOpen, isOpen} = props;
    const scope = useMenuAnimation(isOpen);
    return (
        <div className='fixed top:0 w-screen h-[7rem] sm:h-[7rem] flex border-b bg-white border-black font-EB items-center px-10 z-10'>
          <NavLink to="/" className="flex-1 " onClick={()=>{setIsOpen(false)}}>
            {/* <div className='text-left text-7xl font-GM hover:cursor-pointer'>PALETTI CO.</div> */}
            <img
        src={`${process.env.PUBLIC_URL}/asset/img/logo-b.svg`} className='w-[5rem]'/>
          </NavLink>
            <div className='flex-none'>
              
                <div ref={scope}>
                    <nav className="menu">
                        <ul className='menu-ul'>
                        <NavLink to={'/'} onClick={()=>{setIsOpen(false)}}>
                            <li className='menu-li'>Home</li>
                            </NavLink>
                            <NavLink to={'/portfolio'} onClick={()=>{setIsOpen(false)}}>
                            <li className='menu-li'>Portfolio</li>
                            </NavLink>
                            <NavLink to={'/contact'} onClick={()=>{setIsOpen(false)}}>
                            <li className='menu-li'>Contact</li>
                            </NavLink>
                            <li className='text-white text-sm mt-20'>
                            {/* <div>
                              <span>ENG</span> | <span>KOR</span>
                              </div> */}
                            </li>
                        </ul>
                    </nav>
                    
                <MenuToggle toggle={() => setIsOpen(!isOpen)} isOpen={isOpen}/>
                </div>

                {/* <motion.div layoutId='1' onClick={()=>setSelectId("1")}>
                    <div 
                    className='hover:scale-110'>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/hamburger.svg`} className='w-10' />
                    </div>
                    </motion.div> */}

                {/* <ul>
                                <NavLink to="/">
                                    home
                                </NavLink>
                                <NavLink to="/portfolio">
                                    <li>portfolio</li>
                                </NavLink>
                                <NavLink to="/contact">
                                    <li>contact</li>
                                </NavLink>
                            </ul> */}
            </div>
        </div>
    )
} 
